import * as React from 'react'

const UploadIcon = (props) => {
  return (
    <svg
      width='45'
      height='26'
      viewBox='0 0 45 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        x='1'
        y='1'
        width='42.6875'
        height='24'
        rx='7'
        fill='white'
        stroke='white'
        strokeWidth='2'
      />
      <path
        d='M22 8.33331V17.6666'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.3334 13H26.6667'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UploadIcon
