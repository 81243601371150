import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import './styles.scss'

const SoonModal = (props) => {
  const { show, handleClose, handleBack, title } = props

  return (
    <Modal
      ariaHideApp={false}
      className='soon-modal'
      isOpen={show}
      onRequestClose={() => handleClose()}
      overlayClassName='Overlay'
    >
      <div className='soon-modal__wrapper'>
        <div className='soon-modal__title'>{title}</div>
        <div className='soon-modal__hr' />
        <div className='soon-modal__actions'>
          <button
            className='soon-modal__btn'
            onClick={() => handleBack()}
            type='button'
          >
            Go Back
          </button>
          <button
            className='soon-modal__btn cancel-btn'
            onClick={() => handleClose()}
            type='button'
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

SoonModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

export default SoonModal
