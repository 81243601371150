import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'

function* createGuest({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.guestCreateSuccess(detail))
  } catch (e) {
    yield put(actions.guestCreateFailure(e))
  }
}

function* watchGuestCreateRequest({ meta }) {
  yield call(createGuest, meta)
}

export default function* () {
  yield takeLatest(actions.GUEST_CREATE_REQUEST, watchGuestCreateRequest)
}
