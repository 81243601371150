import * as actions from './actions'

const initialResourceState = {
  details: {},
  loading: false,
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.INFO_INDEX_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.INFO_INDEX_SUCCESS:
    return {
      ...state,
      loading: false,
      details: payload.data.attributes
    }

  case actions.INFO_INDEX_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  default:
    return state
  }
}
