const resource = 'tips'

export const CREATE_TIP_REQUEST = 'CREATE_TIP_REQUEST'
export const CREATE_TIP_SUCCESS = 'CREATE_TIP_SUCCESS'
export const CREATE_TIP_FAILURE = 'CREATE_TIP_FAILURE'
export const RESET_TIP_STATE = 'RESET_TIP_STATE'

export const tipCreateRequest = (data) => ({
  type: CREATE_TIP_REQUEST,
  payload: { data },
  meta: {
    resource
  }
})

export const tipCreateSuccess = () => ({
  type: CREATE_TIP_SUCCESS
})

export const tipCreateFailure = (error) => ({
  type: CREATE_TIP_FAILURE,
  error: true,
  payload: error
})

export const tipResetState = () => ({
  type: RESET_TIP_STATE
})
