import * as actions from './actions'

const initialResourceState = {
  details: {},
  loading: false,
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.ADS_INDEX_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.ADS_INDEX_SUCCESS:
    return {
      ...state,
      loading: false,
      details: payload.data
    }

  case actions.ADS_INDEX_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.ADS_SEND_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.ADS_SEND_SUCCESS:
    return {
      ...state,
      loading: false
    }

  case actions.ADS_SEND_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.RESET_AD_STATE:
    return {
      ...state,
      loading: false,
      details: {},
      errors: {}
    }

  default:
    return state
  }
}
