import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import * as EmailValidator from 'email-validator'
import isEmpty from 'lodash.isempty'

import {
  UnFocusUsernameIcon,
  FocusUsernameIcon,
  FocusPasswordIcon,
  UnFocusPasswordIcon,
  SuccessIcon,
  ErrorIcon,
  SignUpIcon,
  EyeOffIcon,
  EyeOnIcon
} from '../../assets/svg'
import './styles.scss'
import { history } from '../../../services'

const AuthorizationModal = ({
  showAuthorizationModal,
  handleCloseModal,
  postAuth,
  errors,
  isCreated
}) => {
  const [focusedInput, setFocusedInput] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [changed, setChanged] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [errorPassword, setErrorPassword] = useState('')
  const [validUsername, setValidUsername] = useState(false)
  const [errorUsername, setErrorUsername] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (password.length >= 8) {
      setValidPassword(true)
      setErrorPassword('')
    } else if (password.length > 0 && password.length < 8) {
      setValidPassword(false)
      setErrorPassword('Min. 8 characters, at least 1 letter and number.')
    } else if (!password && changed === 'password') {
      setValidPassword(false)
      setErrorPassword('Is required')
    }
  }, [password, changed])

  useEffect(() => {
    if (username.length >= 4) {
      if (changed === 'username' && focusedInput === 'username') {
        setValidUsername(false)
        setErrorUsername('')
        if (username.includes('@') && EmailValidator.validate(username)) {
          setValidUsername(true)
          setErrorUsername('')
        } else {
          setValidUsername(true)
          setErrorUsername('')
        }
      } else if ((username.includes('@') && !EmailValidator.validate(username) && focusedInput !== 'username')) {
        setValidUsername(false)
        setErrorUsername('Not a valid email.')
      }
    } else if (!username && changed === 'username') {
      setValidUsername(false)
      setErrorUsername('Is required.')
    }
  }, [username, changed, focusedInput])

  useEffect(() => {
    const closeModal = localStorage.getItem('modalClose')
    if (!isEmpty(closeModal)) {
      if (isCreated) {
        handleCloseModal()
        localStorage.removeItem('modalClose')
      }
    }
  }, [isCreated])

  const valid = () => {
    return validUsername && validPassword
  }

  const hidePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Modal
      ariaHideApp={false}
      className='authorization-modal'
      isOpen={showAuthorizationModal}
      onRequestClose={handleCloseModal}
      overlayClassName='Overlay'
    >
      <div className='sign-in-container'>
        <button className='sign-up-button' onClick={() => history.push('/sign_up')} type='button'>
          <SignUpIcon />
          <p className='sign-in-text'>
            Don’t have an account? Tap <span className='text-underline'>here</span>.
          </p>
        </button>
        <div className='form-wrap'>
          <div className='form'>
            <div className='hr' />
            <div className='header'>
              <p className='title'>Login</p>
            </div>
            <div className='inputs'>
              <div className='little-hr' />
              <div className='input-wrap'>
                {
                  focusedInput === 'username' || username.length
                    ? <FocusUsernameIcon className='icon' />
                    : <UnFocusUsernameIcon className='icon' />
                }
                {
                  validUsername && !errorUsername && !errors.error &&
                  <SuccessIcon className='valid-icon' />
                }
                {
                  (errorUsername || errors.error) && (
                    <>
                      <ErrorIcon className='error-icon' />
                      <p className='error-text'>{errorUsername || errors.error}</p>
                    </>
                  )
                }
                <input
                  className='input'
                  onBlur={() => setFocusedInput('')}
                  onChange={(e) => { setUsername(e.target.value); setChanged('username') }}
                  onFocus={() => setFocusedInput('username')}
                  placeholder='Enter username or email'
                  type='email'
                  value={username}
                />
                <div className='inputs-hr' />
              </div>
              <div className='input-wrap'>
                {
                  focusedInput === 'password' || password.length
                    ? <FocusPasswordIcon className='icon' />
                    : <UnFocusPasswordIcon className='icon' />
                }
                {
                  validPassword && !errorPassword && !errors.error &&
                  <SuccessIcon className='valid-icon' />
                }
                {
                  errorPassword && (
                    <>
                      <ErrorIcon className='error-icon' />
                      <p className='error-text'>{errorPassword}</p>
                    </>
                  )
                }
                {
                  password && password.length && (
                    showPassword
                      ? <EyeOnIcon className='eye-icon' onClick={() => hidePassword()} />
                      : <EyeOffIcon className='eye-icon' onClick={() => hidePassword()} />
                  )
                }
                <input
                  className='input'
                  onBlur={() => { setFocusedInput(''); setChanged('') }}
                  onChange={(e) => { setPassword(e.target.value); setChanged('password') }}
                  onFocus={() => setFocusedInput('password')}
                  placeholder='Enter password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                />
                <div className='inputs-hr bottom' />
              </div>
            </div>
            <div className='buttons-container'>
              <div className='buttons'>
                <button
                  className='done button'
                  disabled={!valid()}
                  onClick={() => postAuth(username, password)}
                  type='button'
                >
                  <p className='p-button done-color'>Done</p>
                </button>
                <button className='cancel button' onClick={() => handleCloseModal()} type='button'>
                  <p className='p-button cancel-color'>Cancel</p>
                </button>
              </div>
            </div>
            <div className='hr' />
          </div>
        </div>
      </div>
    </Modal>
  )
}

AuthorizationModal.propTypes = {
  showAuthorizationModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  postAuth: PropTypes.func,
  errors: PropTypes.object,
  isCreated: PropTypes.bool
}

export default AuthorizationModal
