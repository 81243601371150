import * as React from 'react'

const LikeIcon = (props) => {
  return (
    <svg
      width='28'
      height='27'
      viewBox='0 0 28 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M24.308 5.18943C23.7124 4.57458 23.0052 4.08683 22.2269 3.75405C21.4486 3.42128 20.6143 3.25 19.7719 3.25C18.9294 3.25 18.0951 3.42128 17.3168 3.75405C16.5385 4.08683 15.8313 4.57458 15.2357 5.18943L13.9997 6.46488L12.7636 5.18943C11.5606 3.94805 9.92889 3.25064 8.22752 3.25064C6.52615 3.25064 4.89447 3.94805 3.69142 5.18943C2.48837 6.43082 1.8125 8.1145 1.8125 9.87009C1.8125 11.6257 2.48837 13.3094 3.69142 14.5507L4.92748 15.8262L13.9997 25.1875L23.0719 15.8262L24.308 14.5507C24.9038 13.9362 25.3765 13.2065 25.699 12.4034C26.0215 11.6002 26.1875 10.7394 26.1875 9.87009C26.1875 9.00075 26.0215 8.13994 25.699 7.33681C25.3765 6.53369 24.9038 5.804 24.308 5.18943Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LikeIcon
