/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'

import {
  BackArrowIcon, CopyRightIcon, EighteenPlusIcon,
  VideoIcon, IllegalIcon, RulesIcon, FakeRulesIcon
} from '../../assets/svg'
import './styles.scss'
import { history } from '../../../services'
import { useDocumentBackground } from '../../../hooks/documentBackground'

const Rules = (props) => {
  const [videoDuration, setVideoDuration] = useState(0)
  const [videoFile, setFile] = useState({})

  useDocumentBackground('#000')

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (videoDuration < 301) {
      props.resetVideo()
      localStorage.removeItem('createdVideo')
      localStorage.removeItem('createdVideoImg')
      localStorage.removeItem('fromUploadPage')
      localStorage.removeItem('videoDuration')
      localStorage.removeItem('createdVideoError')
      localStorage.removeItem('userVideoCreated')
      localStorage.setItem('videoDuration', videoDuration)

      if ((props.createdVideoId || !localStorage.getItem('createdVideo')) &&
        !localStorage.getItem('fromUploadPage')) {
        const formData = new FormData()
        formData.append('file', videoFile)

        axios.post(`${process.env.BACKEND_BASE_URL}videos`, formData, { withCredentials: true })
          .then((response) => {
            props.createVideoSuccess(response.data)
          }).catch((error) => {
            props.createVideoFailure(error)
          })
      }

      history.push('/upload')
    } else {
      toastr.error('Video is too long', 'The video should be maximum 5 minutes.')
    }
  }, [videoDuration])

  const handleChange = e => {
    if (e.target.files.length) {
      if (/\.(mp4|mov)$/i.test(e.target.files[0].name) === false) {
        toastr.error('Invalid file type', 'Please select a correct video file type')
      }

      setFile(e.target.files[0])

      const sizeFile = (e.target.files[0].size / 1000000).toFixed(3)

      if (sizeFile <= 100) {
        const coefficient = (sizeFile / ((sizeFile * 0.1) + 10)) * 1000
        localStorage.setItem('coefficient', coefficient)
        const video = document.createElement('video')
        video.preload = 'metadata'
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src)
          setVideoDuration(video.duration)
        }
        video.src = URL.createObjectURL(e.target.files[0])
      } else {
        toastr.error('Video size is too large', 'The video should be maximum 100 megabytes.')
      }
    }
  }

  const handleBack = () => {
    const videoId = localStorage.getItem('currentVideoId')
    if (videoId) {
      history.push(`/v/${videoId}`)
    } else {
      history.push('/v')
    }
  }

  return (
    <div className='rules-container'>
      <a className='go-back uploadBackBtn' role='button'>
        <BackArrowIcon
          className='go-back'
          onClick={() => handleBack()}
        />
      </a>
      <div className='rules-block'>
        <div className='rules-img-block'>
          <FakeRulesIcon className='rules-svg rules-fake-svg' />
          <RulesIcon className='rules-svg' />
        </div>
        <div className='rules-info-block'>
          <div className='rules-title'>
            Got something to share? Sweet! Just remember...
          </div>
          <div className='rules-icons-block'>
            <div className='rules-icons'>
              <CopyRightIcon />
              <p className='rules-icons-description'>No copyright material.</p>
            </div>
            <div className='rules-icons'>
              <EighteenPlusIcon />
              <p className='rules-icons-description'>
                Everyone must be consenting and 18+.
              </p>
            </div>
            <div className='rules-icons'>
              <VideoIcon />
              <p className='rules-icons-description'>
                Vertical videos only, max 5 minutes.
              </p>
            </div>
            <div className='rules-icons'>
              <IllegalIcon />
              <p className='rules-icons-description'>
                No illegal content, ever.
              </p>
            </div>
          </div>
        </div>
        <a className='rules-button-block uploadSubmitBtn' role='button'>
          <label className='rules-button' htmlFor='uploader'>
            UPLOAD
          </label>
          <input
            accept='video/mp4,video/x-m4v,video/*'
            id='uploader'
            onChange={handleChange}
            style={{ display: 'none' }}
            type='file'
          />
        </a>
      </div>
    </div>
  )
}

Rules.propTypes = {
  resetVideo: PropTypes.func.isRequired,
  createVideoSuccess: PropTypes.func.isRequired,
  createVideoFailure: PropTypes.func.isRequired,
  createdVideoId: PropTypes.number
}

export default Rules
