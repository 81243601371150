/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Linkify from 'linkifyjs/react'
import * as linkify from 'linkifyjs'
import hashtag from 'linkifyjs/plugins/hashtag'
import ReactGA from 'react-ga'

import isEmpty from 'lodash.isempty'
import {
  LikeIcon,
  CommentIcon,
  MoreIcon,
  LikedIcon,
  PlayButtonIcon,
  TopArrowsIcon,
  SecondBackArrowIcon,
  TipIcon,
  UnlockIcon
} from '../../assets/svg'
import { PurchaseModal } from '../../molecules'
import { history } from '../../../services'
import NoAvatar from '../../../../no-avatar.png'
import BuyedGif from '../../assets/buy-video.gif'

class VideoSlide extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      isPurchaseOpen: false
    }
  }

  handlePlay = (e) => {
    e.preventDefault()
    this.props.playVideo()
  }

  animateButton = (id, type) => {
    const animation = document.getElementById(`${type}_animation_${id}`)

    animation.classList.remove('animate')
    animation.classList.add('animate')
    document.getElementsByClassName(`${type}_class_${id}`)[0].style.transform =
      'scale(0.8)'
    setTimeout(() => {
      document.getElementsByClassName(
        `${type}_class_${id}`
      )[0].style.transform = 'scale(1)'
      animation.classList.remove('animate')
    }, 700)
  }

  setLike = (id) => {
    this.animateButton(id, 'like')
    this.props.likeVideo(id)
  }

  openComments = (id) => {
    this.props.clearComments()
    this.props.setCommentsModal(true)
    this.props.getComments(id, 1)
    this.animateButton(id, 'comments')
    ReactGA.event({
      category: 'Comments button',
      action: 'Clicked Comments'
    })
  }

  openTip = (id) => {
    this.props.getUser()
    ReactGA.event({
      category: 'Tip button',
      action: 'Clicked Tip'
    })
    if (isEmpty(this.props.currentUser) && !isEmpty(this.props.currentGuest)) {
      localStorage.setItem('modalClose', true)
      this.props.setAuthorizationModal(true)
    } else {
      setTimeout(() => {
        if (this.props.currentUser.tokens_count > 0) {
          this.props.setTipModal(true)
        } else {
          this.props.setRefillModal(true)
        }
      }, 200)
    }
    this.animateButton(id, 'tips')
  }

  openMore = (id) => {
    this.animateButton(id, 'more')
    this.props.setShowModal(true)
  }

  openPurchase = () => {
    this.props.getUser()
    this.props.resetPurchaseState()
    if (isEmpty(this.props.currentUser) && !isEmpty(this.props.currentGuest)) {
      localStorage.setItem('modalClose', true)
      this.props.setAuthorizationModal(true)
    } else {
      setTimeout(() => {
        if (this.props.currentUser.tokens_count > 0) {
          this.setState({ isPurchaseOpen: true })
        } else {
          this.props.setRefillModal(true)
        }
      }, 300)
    }
  }

  closePurchase = () => {
    this.setState({ isPurchaseOpen: false })
  }

  handlePurchase = () => {
    const data = { id: this.props.video.id }
    this.props.createPurchase(data)
  }

  render() {
    const {
      video,
      stateOpacity,
      resetTimer,
      index,
      realIndex,
      translateX
    } = this.props
    const userAvatar =
      video.attributes.user.avatar && video.attributes.user.avatar.thumbnail.url

    hashtag(linkify)
    return (
      <div>
        {this.props.username && (
          <div
            className='back-profile'
            onClick={() => history.push(`/${this.props.username}`)}
            role='button'
            tabIndex='0'
          >
            <div className='arrow-left'>
              <SecondBackArrowIcon />
            </div>
            <img alt='' src={userAvatar || NoAvatar} />
          </div>
        )}
        <div
          className='bottom-info'
          onClick={() => resetTimer()}
          role='button'
          tabIndex='0'
        >
          <a
            className={classNames('video-info videoProfileBtn', {
              'opacity-video-text-move': translateX && realIndex === index
            })}
            onClick={() => history.push(`/${video.attributes.user.username}`)}
            role='button'
            tabIndex={0}
          >
            <div
              className='video-user'
              style={stateOpacity && !video.attributes.is_locked ? { opacity: '0' } : { opacity: '1' }}
            >
              {userAvatar && (
                <div className='video-user-avatar'>
                  <img alt='' src={userAvatar} />
                </div>
              )}
              <h2 className='video-username'>
                {!userAvatar && '@'}
                {video.attributes.user.username}
              </h2>
            </div>
            <h1
              className='videos-description'
              style={stateOpacity && !video.attributes.is_locked ? { opacity: '0' } : { opacity: '1' }}
            >
              <Linkify options={{ className: 'links' }}>
                {video.attributes.description}
              </Linkify>
            </h1>
          </a>
          <div
            className='main-info'
          >
            <a
              className={classNames(
                'buttons-block bar-item-animation videoMoreBtn',
                { 'translate-button': realIndex === index },
                { 'translate-move': translateX },
                { 'translate-move': stateOpacity }
              )}
              onClick={() => this.openMore(video.id)}
              role='button'
              tabIndex='0'
            >
              <button
                className='tip-bubbly-button'
                id={`more_animation_${video.id}`}
                type='button'
              />
              <MoreIcon className={`more_class_${video.id}`} />
              <p>More</p>
            </a>
            <a
              className={classNames(
                'buttons-block bar-item-animation videoTipBtn',
                { 'translate-button': realIndex === index },
                { 'translate-move': translateX },
                { 'translate-move': stateOpacity }
              )}
              onClick={() => this.openTip(video.id)}
              role='button'
              tabIndex={0}
            >
              <button
                className='tip-bubbly-button'
                id={`tips_animation_${video.id}`}
                type='button'
              />
              <TipIcon
                className={`tips_class_${video.id}`}
                stroke='white'
                style={{ zIndex: 2 }}
              />
              <p>Tip</p>
            </a>
            <a
              className={classNames(
                'buttons-block bar-item-animation videoLikeBtn',
                { 'translate-button': realIndex === index },
                { 'translate-move': translateX },
                { 'translate-move': stateOpacity }
              )}
            >
              <button
                className='bubbly-button'
                id={`like_animation_${video.id}`}
                type='button'
              />
              {video.attributes.is_liked ? (
                <LikedIcon
                  className={`like_class_${video.id}`}
                  height={27}
                  onClick={() => this.setLike(video.id)}
                  style={{ zIndex: 2 }}
                  width={28}
                />
              ) : (
                <LikeIcon
                  className={`like_class_${video.id}`}
                  onClick={() => this.setLike(video.id)}
                  style={{ zIndex: 2 }}
                />
              )}
              <p>{video.attributes.likes_count}</p>
            </a>
            <a
              className={classNames(
                'buttons-block bar-item-animation videoCommentsBtn',
                { 'translate-button': realIndex === index },
                { 'translate-move': translateX },
                { 'translate-move': stateOpacity }
              )}
              onClick={() => this.openComments(video.id)}
              role='button'
              tabIndex={0}
            >
              <button
                className='comments-bubbly-button'
                id={`comments_animation_${video.id}`}
                type='button'
              />
              <CommentIcon
                className={`comments_class_${video.id}`}
                style={{ zIndex: 2 }}
              />
              <p>{video.attributes.comments_count}</p>
            </a>
          </div>
        </div>
        {this.props.purchaseAnimation && (
          <img
            alt='tip-success' id='buy-video' src={BuyedGif}
            height='100%' style={{ zIndex: 100, position: 'absolute' }}
          />
        )}
        {video.attributes.is_locked ? (
          <>
            <PurchaseModal
              handleClose={this.closePurchase}
              handleSubmit={this.handlePurchase}
              price={video.attributes.price}
              purchaseError={this.props.purchaseError}
              purchaseLoading={this.props.purchaseLoading}
              purchaseAnimation={this.props.purchaseAnimation}
              setRefillModal={this.props.setRefillModal}
              show={this.state.isPurchaseOpen}
            />
            <a className='video-wrap' role='button' tabIndex='0'>
              <div className='video_locked'>
                <div className='video_locked_ovarlay' />
                <span
                  className='video_locked_circle'
                  onClick={() => this.openPurchase()}
                  role='button'
                  tabIndex='0'
                >
                  <div className='video_locked_circle_wrapper'>
                    <div className='unlock__icons__wrapper'>
                      <UnlockIcon width='30' height='30' className='unlock__icon' />
                      <TipIcon height={30} width={30} className='tip__icon' />
                    </div>
                    {video.attributes.price}
                  </div>
                </span>
              </div>
              <img
                alt='preview'
                className={classNames('container-poste', {
                  hidden: !this.props.isPreviewShown
                })}
                src={video.attributes.image}
              />
            </a>
          </>
        ) : (
          <a
            className='video-wrap videoSlideBtn'
            onClick={(e) => this.handlePlay(e)}
            role='button'
            tabIndex='0'
          >
            <div
              className={classNames('play-button', {
                hidden: !this.props.isButtonShown
              })}
            >
              <PlayButtonIcon style={{ position: 'relative' }} />
              <div className='play-button-block'>
                <p className='play-button-text'>
                  or swipe up
                  <br />
                  for the next video
                </p>
                <TopArrowsIcon />
              </div>
            </div>
            <img
              alt='preview'
              className={classNames('container-poste', {
                hidden: !this.props.isPreviewShown
              })}
              src={video.attributes.image}
            />
          </a>
        )}
      </div>
    )
  }
}

VideoSlide.propTypes = {
  username: PropTypes.string,
  isPreviewShown: PropTypes.bool.isRequired,
  isButtonShown: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
  stateOpacity: PropTypes.bool.isRequired,
  likeVideo: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setCommentsModal: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  createPurchase: PropTypes.func.isRequired,
  purchaseAnimation: PropTypes.bool.isRequired,
  purchaseLoading: PropTypes.bool.isRequired,
  purchaseError: PropTypes.string,
  getUser: PropTypes.func.isRequired,
  setTipModal: PropTypes.func.isRequired,
  setRefillModal: PropTypes.func.isRequired,
  setAuthorizationModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  currentGuest: PropTypes.object,
  clearComments: PropTypes.func.isRequired,
  playVideo: PropTypes.func.isRequired,
  resetTimer: PropTypes.func.isRequired,
  resetPurchaseState: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  realIndex: PropTypes.number,
  translateX: PropTypes.bool.isRequired
}

export default VideoSlide
