import { put, call, takeLatest } from 'redux-saga/effects'

import { request, history, historyRefresh } from '../../services'
import * as actions from './actions'
import { guestCreateRequest, guestResetState } from '../guests/actions'

function* createSession({ data }, { resource }) {
  try {
    const detail = yield request(resource, 'post', data)
    yield put(actions.sessionCreateSuccess(detail))
    yield put(guestResetState())
    if (JSON.parse(localStorage.getItem('fromUploadPage')) && JSON.parse(localStorage.getItem('fromUploadPage')).key) {
      yield history.push('/upload')
    } else {
      yield history.push('/v')
    }
  } catch (e) {
    yield put(actions.sessionCreateFailure(e))
  }
}

function* showSession({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.sessionShowSuccess(detail))
    yield put(guestResetState())
  } catch (e) {
    yield put(actions.sessionShowFailure(e))
    yield put(guestCreateRequest())
  }
}

function* deleteSession({ resource }) {
  try {
    const detail = yield request(resource, 'delete')
    yield put(actions.sessionDeleteSuccess(detail))
    yield put(guestResetState())
    historyRefresh.push('/')
  } catch (e) {
    yield put(actions.sessionDeleteFailure(e))
  }
}

function* watchSessionCreateRequest({ payload, meta }) {
  yield call(createSession, payload, meta)
}

function* watchSessionShowRequest({ meta }) {
  yield call(showSession, meta)
}

function* watchSessionDeleteRequest({ meta }) {
  yield call(deleteSession, meta)
}

export default function* () {
  yield takeLatest(actions.SESSION_CREATE_REQUEST, watchSessionCreateRequest)
  yield takeLatest(actions.SESSION_SHOW_REQUEST, watchSessionShowRequest)
  yield takeLatest(actions.SESSION_DELETE_REQUEST, watchSessionDeleteRequest)
}
