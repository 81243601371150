import React from 'react'
import PropTypes from 'prop-types'
import { isIOS } from 'react-device-detect'

import QwkiLogo from '../../assets/qwki-logo.png'
import {
  AddToHomeScreenIcon, PlusForHomeScreenIcon,
  AddToHomeAndroidIcon, MenuAndroidIcon
} from '../../assets/svg'
import './styles.scss'

const HomeScreenModal = (props) => {
  return (
    <div className='home-screen-modal'>
      <div className='modal-container'>
        <div className='icons-wrap'>
          <div className='icons-container'>
            <img alt='icon' className='icon' src={QwkiLogo} />
            <div className='icon empty' />
            <div className='icon empty' />
            <div className='icon empty' />
            <div className='icon half' />
          </div>
        </div>
        <div className='title-wrap'>
          <p className='text'>How to add Qwki to your{!isIOS && " phone's"} home screen</p>
          <div className='pink-br' />
        </div>
        <div className='content-wrap'>
          <p className='text-head'><b>Benefits...</b></p>
          <div className='text-part'>
            <p className='text'>Full-screen videos.</p>
            <p className='text pink-text'>oh yay!</p>
          </div>
          <div className='text-part'>
            <p className='text'>Instant access, one-click!</p>
            <p className='text pink-text'>sweet!</p>
          </div>
          <p className='text-head bottom-text-head'><b>Ok, so how do I do this???</b></p>
          <p className='text'>Follow these two steps.</p>
        </div>
        <div className='fields-wrap'>
          <div className='field'>
            {
              isIOS
                ? <AddToHomeScreenIcon className='icon-form-field' />
                : <MenuAndroidIcon className='icon-form-field' />
            }
            <p className='field-text'>
              Tap the
              <b>
                {isIOS
                  ? ' share icon'
                  : ' menu icon'}
              </b> in <br /> {isIOS ? "Safari's" : "your browser's"} menu bar
            </p>
          </div>
          <div className='field'>
            {
              isIOS
                ? <PlusForHomeScreenIcon className='icon-form-field' />
                : <AddToHomeAndroidIcon className='icon-form-field' />
            }
            <p className='field-text'>Then select <br /><b>&quot;Add to Home Screen&quot;</b></p>
          </div>
        </div>
        <div className='button-wrap'>
          <div className='grey-br' />
          <button
            className='button'
            onClick={() => {
              props.showModal(false)
              window.history.replaceState({ showAddToHomeModal: false }, '', '/v')
            }}
            type='submit'
          >
            Close instructions
          </button>
        </div>
      </div>
    </div>
  )
}

HomeScreenModal.propTypes = ({
  showModal: PropTypes.func.isRequired
})

export default HomeScreenModal
