import React, { useState, useEffect } from 'react'
import * as EmailValidator from 'email-validator'
import PropTypes from 'prop-types'

import './styles.scss'
import {
  UnFocusUsernameIcon, FocusUsernameIcon,
  FocusPasswordIcon, UnFocusPasswordIcon,
  SuccessIcon, ErrorIcon, SignUpIcon,
  EyeOffIcon, EyeOnIcon
} from '../../assets/svg'
import { history } from '../../../services'

const SignIn = ({
  postAuth,
  errors,
  goToQuill,
  isCreated
}) => {
  const [focusedInput, setFocusedInput] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [changed, setChanged] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [errorPassword, setErrorPassword] = useState('')
  const [validUsername, setValidUsername] = useState(false)
  const [errorUsername, setErrorUsername] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (password.length >= 8) {
      setValidPassword(true)
      setErrorPassword('')
    } else if (password.length > 0 && password.length < 8) {
      setValidPassword(false)
      setErrorPassword('Min. 8 characters, at least 1 letter and number.')
    } else if (!password && changed === 'password') {
      setValidPassword(false)
      setErrorPassword('Is required')
    }
  }, [password, changed])

  useEffect(() => {
    if (username.length >= 4) {
      if (changed === 'username' && focusedInput === 'username') {
        setValidUsername(false)
        setErrorUsername('')
        if (username.includes('@') && EmailValidator.validate(username)) {
          setValidUsername(true)
          setErrorUsername('')
        } else {
          setValidUsername(true)
          setErrorUsername('')
        }
      } else if ((username.includes('@') && !EmailValidator.validate(username) && focusedInput !== 'username')) {
        setValidUsername(false)
        setErrorUsername('Not a valid email.')
      }
    } else if (!username && changed === 'username') {
      setValidUsername(false)
      setErrorUsername('Is required.')
    }
  }, [username, changed, focusedInput])

  const videoId = localStorage.getItem('currentVideoId')

  useEffect(() => {
    localStorage.removeItem('modalClose')
    if (isCreated && videoId) {
      history.push(`/v/${videoId}`)
    }
  }, [isCreated])

  const valid = () => {
    return validUsername && validPassword
  }

  const checkUploadPush = (path) => {
    if (localStorage.getItem('fromUploadPage') && localStorage.getItem('createdVideo')) {
      history.push('/upload')
    } else {
      history.push(`${path}`)
    }
  }

  const checkUploadAuth = (path) => {
    postAuth(username, password)
    if (localStorage.getItem('fromUploadPage') && localStorage.getItem('createdVideo')) {
      history.push('/upload')
    } else {
      history.push(`${path}`)
    }
  }

  const handleCancel = () => {
    if (videoId) {
      history.push(`/v/${videoId}`)
    }
  }

  const hidePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='sign-in-container'>
      <button className='sign-up-button' onClick={() => checkUploadPush('/sign_up')} type='button'>
        <SignUpIcon />
        <p className='sign-in-text'>
          Don’t have an account? Tap <span className='text-underline'>here</span>.
        </p>
      </button>
      <div className='form-wrap'>
        <div className='form'>
          <div className='hr' />
          <div className='header'>
            <p className='title'>Login</p>
          </div>
          <div className='inputs'>
            <div className='little-hr' />
            <div className='input-wrap'>
              {
                focusedInput === 'username' || username.length
                  ? <FocusUsernameIcon className='icon' />
                  : <UnFocusUsernameIcon className='icon' />
              }
              {
                validUsername && !errorUsername && !errors.error &&
                <SuccessIcon className='valid-icon' />
              }
              {
                (errorUsername || errors.error) && (
                  <>
                    <ErrorIcon className='error-icon' />
                    <p className='error-text'>{errorUsername || errors.error}</p>
                  </>
                )
              }
              <input
                className='input'
                onBlur={() => setFocusedInput('')}
                onChange={(e) => { setUsername(e.target.value); setChanged('username') }}
                onFocus={() => setFocusedInput('username')}
                placeholder='Enter username or email'
                type='email'
                value={username}
              />
              <div className='inputs-hr' />
            </div>
            <div className='input-wrap'>
              {
                focusedInput === 'password' || password.length
                  ? <FocusPasswordIcon className='icon' />
                  : <UnFocusPasswordIcon className='icon' />
              }
              {
                validPassword && !errorPassword && !errors.error &&
                <SuccessIcon className='valid-icon' />
              }
              {
                errorPassword && (
                  <>
                    <ErrorIcon className='error-icon' />
                    <p className='error-text'>{errorPassword}</p>
                  </>
                )
              }
              {
                password && password.length && (
                  showPassword
                    ? <EyeOnIcon className='eye-icon' onClick={() => hidePassword()} />
                    : <EyeOffIcon className='eye-icon' onClick={() => hidePassword()} />
                )
              }
              <input
                className='input'
                onBlur={() => { setFocusedInput(''); setChanged('') }}
                onChange={(e) => { setPassword(e.target.value); setChanged('password') }}
                onFocus={() => setFocusedInput('password')}
                placeholder='Enter password'
                type={showPassword ? 'text' : 'password'}
                value={password}
              />
              <div className='inputs-hr bottom' />
            </div>
          </div>
          <div className='buttons-container'>
            <div className='buttons'>
              <button
                className='done button'
                disabled={!valid()}
                onClick={() => checkUploadAuth('/')}
                type='button'
              >
                <p className='p-button done-color'>Done</p>
              </button>
              <button className='cancel button' onClick={() => handleCancel()} type='button'>
                <p className='p-button cancel-color'>Cancel</p>
              </button>
            </div>
            <div className='under-buttons-container'>
              <p className='by-creating-text'>
                By creating an account, you agree to our
                <button
                  className='link'
                  onClick={() => goToQuill('Terms Of Use', 'terms_of_use')}
                  type='button'
                >&nbsp;Terms of Use
                </button> and that you have read our
                <button
                  className='link'
                  onClick={() => goToQuill('Privacy Policy', 'privacy_policy')}
                  type='button'
                >
                  &nbsp;Privacy Policy
                </button> and
                <button
                  className='link'
                  onClick={() => goToQuill('Community Guidelines', 'community_guidlines')}
                  type='button'
                >&nbsp;Community Guidelines
                </button>
              </p>
            </div>
          </div>
          <div className='hr' />
        </div>
      </div>
    </div>
  )
}

SignIn.propTypes = ({
  postAuth: PropTypes.func.isRequired,
  errors: PropTypes.object,
  goToQuill: PropTypes.func.isRequired,
  isCreated: PropTypes.bool
})

export default SignIn
