import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const ProgressBar = ({ customClassName, setIsLongUpload, onAnimationEnd }) => {
  const [completed, setCompleted] = useState(1)
  const coefficient = parseInt(localStorage.getItem('coefficient'))

  useEffect(() => {
    let interval
    if (!localStorage.getItem('createdVideo') || !localStorage.getItem('createdVideoError')) {
      interval = setInterval(() => setCompleted(
        completed => completed !== 100 ? completed + 1 : 100
      ), parseInt(coefficient.toFixed()))
    }

    return () => {
      if (interval) clearInterval(interval)
      setCompleted(100)
    }
  }, [localStorage.getItem('createdVideo'), localStorage.getItem('createdVideoError')])

  useEffect(() => {
    if (completed === 100 && !localStorage.getItem('createdVideo')) {
      const time = coefficient.toFixed() * 0.1
      if (coefficient > 1000) {
        setTimeout(() => setIsLongUpload(true), time)
        localStorage.setItem('createdVideoError', true)
      }
    }
  }, [completed])

  return (
    <div className={`progrres-bar-container ${customClassName}`} onAnimationEnd={onAnimationEnd}>
      <div
        className='progrres-bar'
        style={{ width: `${localStorage.getItem('createdVideo') ? 100 : completed}%` }}
      >
        <span className='progrres-bar-percent'>
          {`${localStorage.getItem('createdVideo') ? 100 : completed}%`}
        </span>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  customClassName: PropTypes.string,
  setIsLongUpload: PropTypes.func.isRequired,
  onAnimationEnd: PropTypes.func
}

export default ProgressBar
