import * as React from 'react'

const CommentIcon = (props) => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M25 12.2778L25 12.2804C25.0045 14.0294 24.5959 15.7547 23.8074 17.3158L23.8055 17.3196C22.8693 19.1927 21.4302 20.7682 19.6492 21.8696C17.8682 22.971 15.8158 23.5547 13.7218 23.5556L13.7196 23.5556C11.9706 23.5601 10.2453 23.1515 8.68417 22.363C8.44618 22.2428 8.17004 22.2226 7.91709 22.3069L1.58114 24.4189L3.69312 18.0829C3.77744 17.83 3.75725 17.5538 3.63704 17.3158C2.84851 15.7547 2.43988 14.0294 2.44444 12.2804L2.44444 12.2782C2.44525 10.1842 3.02904 8.13175 4.13043 6.3508C5.23181 4.56984 6.80728 3.13069 8.68038 2.19454L8.68038 2.19455L8.68417 2.19264C10.2453 1.40411 11.9706 0.995478 13.7196 1.00004H13.7222H14.4163C17.1712 1.15865 19.7721 2.32445 21.7238 4.27616C23.6755 6.22787 24.8413 8.82878 25 11.5837L25 12.2778Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CommentIcon
