export const REPORT_CREATE_REQUEST = 'CREATE_REPORT_REQUEST'
export const REPORT_CREATE_SUCCESS = 'CREATE_REPORT_SUCCEEDED'
export const REPORT_CREATE_FAILURE = 'CREATE_REPORT_FAILED'

export const reportCreateRequest = (id, data) => ({
  type: REPORT_CREATE_REQUEST,
  payload: { data },
  meta: {
    resource: `videos/${id}/reports`
  }
})

export const reportCreateSuccess = (detail) => ({
  type: REPORT_CREATE_SUCCESS,
  payload: detail
})

export const reportCreateFailure = (error) => ({
  type: REPORT_CREATE_FAILURE,
  error: true,
  payload: error
})
