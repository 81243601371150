import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'

import './styles.scss'
import { BackArrowIcon } from '../../assets/svg'
import { history, historyRefresh } from '../../../services'

class Quill extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: this.props.location.state.title || '',
      data: this.props.location.state.data || {}
    }
  }

  render() {
    return (
      <div className='quill-container'>
        <div className='header'>
          {this.props.location.state && this.props.location.state.modal
            ? <BackArrowIcon className='arrow' onClick={() => historyRefresh.push('/')} />
            : <BackArrowIcon className='arrow' onClick={() => history.goBack()} />}
          <p className='title'>{this.state.title}</p>
        </div>
        <div className='quill-content'>
          <ReactQuill
            defaultValue={this.state.data}
            modules={
              { toolbar: false }
            }
            readOnly
          />
        </div>
      </div>
    )
  }
}

Quill.propTypes = ({
  location: PropTypes.shape({
    state: PropTypes.shape({
      title: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired,
      modal: PropTypes.bool
    }).isRequired
  }).isRequired
})

export default Quill
