import * as React from 'react'

const LikedIcon = (props) => {
  return (
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M28.6867 3.387C27.9537 2.63025 27.0833 2.02994 26.1254 1.62037C25.1675 1.21081 24.1407 1 23.1038 1C22.0669 1 21.0402 1.21081 20.0822 1.62037C19.1243 2.02994 18.254 2.63025 17.5209 3.387L15.9996 4.95678L14.4783 3.387C12.9976 1.85914 10.9894 1.00079 8.89541 1.00079C6.80142 1.00079 4.79319 1.85914 3.31251 3.387C1.83184 4.91486 1 6.98708 1 9.1478C1 11.3085 1.83184 13.3807 3.31251 14.9086L4.83382 16.4784L15.9996 28L27.1654 16.4784L28.6867 14.9086C29.4201 14.1522 30.0019 13.2541 30.3988 12.2657C30.7957 11.2772 31 10.2178 31 9.1478C31 8.07785 30.7957 7.01838 30.3988 6.02992C30.0019 5.04147 29.4201 4.14339 28.6867 3.387Z" fill="#FF0000" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LikedIcon
