import * as React from 'react'

const TabletIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.25 1H6.75C5.7835 1 5 1.9402 5 3.1V19.9C5 21.0598 5.7835 22 6.75 22H17.25C18.2165 22 19 21.0598 19 19.9V3.1C19 1.9402 18.2165 1 17.25 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 18H12.01" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TabletIcon
