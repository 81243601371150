import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'

function* createValidations({ data }, { resource }) {
  try {
    const detail = yield request(resource, 'post', data)
    yield put(actions.validationsCreateSuccess(detail))
  } catch (e) {
    yield put(actions.validationsCreateFailure(e))
  }
}

function* watchValidationsCreateRequest({ payload, meta }) {
  yield call(createValidations, payload, meta)
}

export default function* () {
  yield takeLatest(actions.VALIDATIONS_CREATE_REQUEST, watchValidationsCreateRequest)
}
