import * as actions from './actions'

const initialResourceState = {
  packsLoading: false,
  packLoading: true,
  details: [],
  detail: {},
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.GET_PACKS_REQUEST:
    return {
      ...state,
      packsLoading: true,
      errors: {}
    }

  case actions.GET_PACKS_SUCCESS:
    return {
      ...state,
      packsLoading: false,
      details: payload.response
    }

  case actions.GET_PACKS_FAILURE:
    return {
      ...state,
      packsLoading: false,
      errors: payload.response.data
    }

  case actions.GET_PACK_REQUEST:
    return {
      ...state,
      packLoading: true,
      detail: {},
      errors: {}
    }

  case actions.GET_PACK_SUCCESS:
    return {
      ...state,
      packLoading: false,
      detail: payload.response
    }

  case actions.GET_PACK_FAILURE:
    return {
      ...state,
      packLoading: false,
      errors: payload.response.data
    }

  default:
    return state
  }
}
