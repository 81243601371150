import * as actions from './actions'

const initialResourceState = {
  emailPresent: false,
  usernamePresent: false,
  loading: false
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.VALIDATIONS_CREATE_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.VALIDATIONS_CREATE_SUCCESS:
    if ((payload.email === false) || (payload.email === true)) {
      return {
        ...state,
        loading: false,
        emailPresent: payload.email || false
      }
    } else {
      return {
        ...state,
        loading: false,
        usernamePresent: payload.username || false
      }
    }

  case actions.VALIDATIONS_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.RESET_VALIDATION_EMAIL:
    return {
      ...state,
      loading: false,
      emailPresent: false
    }

  case actions.RESET_VALIDATION_USERNAME:
    return {
      ...state,
      loading: false,
      usernamePresent: false
    }

  default:
    return state
  }
}
