import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { osName } from 'react-device-detect'
import axios from 'axios'
import isEmpty from 'lodash.isempty'

import { SignIn } from '../components/pages'
import { sessionCreateRequest, infoIndexRequest } from '../store/actions'
import { history } from '../services'

class AuthContainer extends PureComponent {
  componentDidMount() {
    this.props.getInfo()
    if (isEmpty(this.props.currentGuest) &&
      !isEmpty(this.props.currentUser)) {
      history.push('/')
    }
  }

  postAuth = async (username, password) => {
    let loc

    if (localStorage.getItem('loc')) {
      loc = localStorage.getItem('loc')
    } else {
      loc = await axios('https://api.ipbase.com/v2/info').then(res => { return res.data.data.location.country.alpha2 })
      localStorage.setItem('loc', loc)
    }

    if (username.includes('@')) {
      const data = {
        email: username.toLowerCase(),
        password,
        platform: osName,
        country: loc
      }
      this.props.createSession(data)
    } else if (username && password) {
      const data = {
        username: username.toLowerCase(),
        password,
        platform: osName,
        country: loc
      }
      this.props.createSession(data)
    }
  }

  goToQuill = (name, value) => {
    history.push('/quill', { title: name, data: this.props.info[value] })
  }

  render() {
    return (
      <SignIn
        errors={this.props.errors}
        goToQuill={this.goToQuill}
        postAuth={this.postAuth}
        isCreated={this.props.isCreated}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  createSession: (data) => dispatch(sessionCreateRequest(data)),
  getInfo: () => dispatch(infoIndexRequest())
})

const mapStateToProps = (state) => ({
  errors: state.session.errors,
  info: state.info.details,
  isCreated: state.session.isCreated,
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest
})

AuthContainer.propTypes = ({
  createSession: PropTypes.func.isRequired,
  errors: PropTypes.object,
  getInfo: PropTypes.func.isRequired,
  info: PropTypes.object,
  currentUser: PropTypes.object,
  currentGuest: PropTypes.object,
  isCreated: PropTypes.bool
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)
