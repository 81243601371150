import React, { useEffect, useCallback, useState, useRef } from 'react'
import PropTypes, { instanceOf } from 'prop-types'
import Swiper from 'react-id-swiper'
import Swipe from 'react-easy-swipe'
import 'swiper/swiper.scss'
import Modal from 'react-modal'
import isEmpty from 'lodash.isempty'
import { toastr } from 'react-redux-toastr'
import { TwitterShareButton } from 'react-share'
import { isIOS, isMobile, isSafari } from 'react-device-detect'
import { withCookies, Cookies } from 'react-cookie'
import ReactGA from 'react-ga'
import postscribe from 'postscribe'

import {
  SMSIcon,
  CopyLinkIcon,
  EmailIcon,
  ReportIcon,
  TabletIcon,
  TwitterIcon,
  AboutUsStaffIcon,
  ArrowTopCategories
} from '../../assets/svg'
import Reported from '../../assets/reported.png'
import Tutorial from '../../assets/tutorial-gif.gif'
import './styles.scss'
import Ads from '../Ads'
import Report from '../Report'
import { history, historyRefresh } from '../../../services'
import {
  ActivityIndicator,
  HelloModal,
  UploadModal,
  HomeScreenModal,
  CommentsModal,
  TipModal,
  RefillModal,
  NavigationBar
} from '../../molecules'
import { SignInModalContainer } from '../../../containers'
import Player from '../Player'
import VideoSlide from '../VideoSlide'
import Loading from '../Loading'
import { useVisibility } from '../../../hooks/visibility'

const UserVideos = (props) => {
  const playerRef = useRef()
  const [swiper, updateSwiper] = useState(null)
  const [autoPlay, setAutoPlay] = useState(false)
  const [isShowAds, setIsShowAds] = useState(false)
  const [viewed, setViewed] = useState(false)
  const [lastViewedVideo, setViewedVideo] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showCommentsModal, setCommentsModal] = useState(false)
  const [showTipModal, setTipModal] = useState(false)
  const [showAuthorizationModal, setAuthorizationModal] = useState(false)
  const [showRefillModal, setRefillModal] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [showReported, setShowReported] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState(
    props.usersCategories.map((item) => item.attributes.id)
  )
  const [oldUserCategories, setOldUserCategories] = useState([])
  const [stateOpacity, setStateOpacity] = useState(false)
  const [copyLinkMessage, setCopyLinkMessage] = useState('hide')
  const [isPlayerPaused, setPlayerPaused] = useState(true)
  const [isPreviewShown, setPreviewShown] = useState(true)
  const [prevAdPlayerPaused, setPrevAdPlayerPausedAd] = useState(null)
  const [previewVideoIndex, setPreviewVideoIndex] = useState(0)
  const [isButtonShown, setIsButtonShown] = useState(true)
  const [showHelloModal, setShowHelloModal] = useState(props.showHelloModal)
  const [showUploadModal, setShowUploadModal] = useState(props.showUploadModal)
  const [showAddToHomeScreen, setShowAddToHomeScreen] = useState(
    props.showAddToHomeModal
  )
  const [isPushing, setIsPushing] = useState(false)
  const [showTutorial, setShowTutorial] = useState(true)
  const [unfadeTutorial, setUnfadeTutorial] = useState(false)
  const [fadeTutorial, setFadeTutorial] = useState(false)
  const [translateX, setTranslateX] = useState(false)
  const [height, setHeight] = useState(window.innerHeight)
  const [swipeTutorialText, setSwipeTutorialText] = useState(false)
  const [isInitialPlay, setIsInitialPlay] = useState(true)
  const [showSwipeTutorial, setShowSwipeTutorial] = useState(false)
  const [timer, setTimer] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)

  const resetTimer = () => {
    clearTimeout(timer)
    setStateOpacity(false)
    setTimer(setTimeout(() => setStateOpacity(true), 20 * 1000))
  }

  useEffect(() => {
    localStorage.removeItem('addressBarTutorialOnScreen')
    props.cookies.set('counterForSwipeTutorial', 0, { path: '/' })
    resetTimer()
  }, [])

  const checkIphoneResolutions = (innerHeight) => {
    const addressBarTutorialOnScreen = localStorage.getItem(
      'addressBarTutorialOnScreen'
    )

    if (window.innerHeight === innerHeight && !addressBarTutorialOnScreen) {
      localStorage.setItem('addressBarTutorialOnScreen', true)
    } else if (
      (window.innerHeight > innerHeight && addressBarTutorialOnScreen)
    ) {
      setSwipeTutorialText(false)
      setShowSwipeTutorial(false)
      localStorage.setItem('addressBarTutorialOnScreen', false)
    }
  }

  useEffect(() => {
    if (window.screen.height === 812) {
      if (window.innerHeight === 635) {
        checkIphoneResolutions(635)
      } else {
        checkIphoneResolutions(629)
      }
    } else if (window.screen.height === 896) {
      checkIphoneResolutions(719)
    } else if (window.screen.height === 667) {
      checkIphoneResolutions(553)
    } else if (window.screen.height === 844) {
      checkIphoneResolutions(664)
    } else if (window.screen.height === 926) {
      checkIphoneResolutions(746)
    }

    function handleResize() {
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [height, localStorage.getItem('revisedAddressBarTutorial')])

  const playVideo = () => {
    if (isPlayerPaused) {
      const videoId = props.videos[swiper.realIndex].id
      resetTimer()
      setPlayerPaused(false)
      setIsButtonShown(false)
      if (lastViewedVideo !== videoId) {
        props.setView(videoId)
      }
      setViewedVideo(videoId)
      if (isInitialPlay && window.innerHeight === (635 || 719 || 553)) {
        setShowSwipeTutorial(true)
        setIsInitialPlay(false)
        localStorage.setItem('addressBarTutorialOnScreen', true)
      }
      if (!localStorage.getItem('showedTutorial')) {
        setTimeout(() => {
          setUnfadeTutorial(true)
        }, 1000)
      }
    } else {
      resetTimer()
      setPlayerPaused(true)
      setIsButtonShown(true)
    }
  }

  useVisibility(
    setPlayerPaused,
    setPreviewShown,
    setIsButtonShown,
    isPlayerPaused,
    playerRef,
    isShowAds
  )

  const hideTutorial = (sec) => {
    if (unfadeTutorial) {
      setTimeout(() => {
        setFadeTutorial(true)
        setUnfadeTutorial(false)
        setTimeout(() => {
          setShowTutorial(false)
          localStorage.setItem('showedTutorial', true)
        }, 500)
      }, sec * 1000)
    }
  }

  useEffect(() => {
    return () => {
      props.resetVideoState()
    }
  }, [])

  useEffect(() => {
    setSelectedCategories(
      props.usersCategories.map((item) => item.attributes.id)
    )
  }, [props.usersCategories])

  useEffect(() => {
    Modal.setAppElement('.main-container')
    setTimeout(() => {
      setAutoPlay(true)
    }, 1000)
    const { cookies } = props

    if (props.cookies.get('counterAds') === undefined) {
      cookies.set('counterAds', 0, { path: '/' })
      cookies.set('swipeDown', -1, { path: '/' })
    } else {
      cookies.set('swipeDown', 0, { path: '/' })
    }
    cookies.set('swipeUp', 0, { path: '/' })
  }, [])

  useEffect(() => {
    if (props.scripts.length > 0) {
      props.scripts.map(script => {
        const scriptExists = document.getElementById(`qdf${script.id}`)
        if (scriptExists) {
          return null
        } else {
          return (
            postscribe('#ad-scripts', `<div id=qdf${script.id}>${script.code}</div>`)
          )
        }
      })
    }
  }, [props.scripts])

  useEffect(() => {
    if (unfadeTutorial) {
      setTimeout(() => hideTutorial(0), 6000)
    }
  }, [unfadeTutorial])

  const updateIndex = () => {
    if (!translateX) {
      setTranslateX(false)
    }
    props.cookies.set(
      'counterAds',
      parseInt(props.cookies.get('counterAds')) + 1,
      { path: '/' }
    )
  }

  const prevUpdateIndex = useCallback(() => {
    updateIndex(props.videos, swiper.realIndex)
  }, [swiper, props.videos])

  const changeShowReported = (data) => {
    setShowReport(false)
    setShowReported(true)
    setShowModal(false)
    resetTimer()
    setTimeout(() => {
      setShowReported(false)
      props.createReport(data)
    }, 3000)
  }

  const showPreview = () => {
    if (!translateX) {
      setTranslateX(true)
    }
    setPreviewShown(true)
    setPreviewVideoIndex(swiper.realIndex)
    setStateOpacity(false)
  }

  const hidePreview = () => {
    resetTimer()
    if (translateX) {
      setTranslateX(false)
    }
    if (
      previewVideoIndex === swiper.realIndex &&
      playerRef.current.currentTime
    ) {
      setPreviewShown(false)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      showTutorial && hideTutorial(0)
      if (!isPushing) {
        setIsPushing(true)
        if (event.keyCode === 40) {
          swiper.slideNext(200, true)
          showPreview()
        } else if (event.keyCode === 38) {
          swiper.slidePrev(200, true)
          showPreview()
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown, false)

    return () => {
      window.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [isPushing, swiper])

  useEffect(() => {
    const handleKeyUp = () => {
      showTutorial && hideTutorial(0)
      setIsPushing(false)
    }
    window.addEventListener('keyup', handleKeyUp, false)

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false)
    }
  }, [])

  useEffect(() => {
    if (swiper !== null) {
      swiper.on('slideChange', prevUpdateIndex)
      swiper.on('sliderMove', showPreview)
      swiper.on('transitionEnd', hidePreview)
    }

    return () => {
      if (swiper !== null) {
        swiper.off('slideChange', prevUpdateIndex)
        swiper.off('transitionEnd', hidePreview)
      }
    }
  }, [swiper, prevUpdateIndex, showPreview, hidePreview])

  const firstUpdate = useRef(true)

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }

    if (!localStorage.getItem('addressBarTutorialOnScreen')) {
      props.cookies.set('counterForSwipeTutorial', 0, { path: '/' })
    } else {
      props.cookies.set(
        'counterForSwipeTutorial',
        parseInt(props.cookies.get('counterForSwipeTutorial')) + 1,
        { path: '/' }
      )
    }

    if (parseInt(props.cookies.get('counterForSwipeTutorial')) === 4) {
      localStorage.setItem('revisedAddressBarTutorial', true)
    }

    if (
      parseInt(props.cookies.get('swipeDown')) + 1 === props.frequency - 1 ||
      parseInt(props.cookies.get('swipeUp')) + 1 === props.frequency - 1
    ) {
      props.getAds()
    }

    if (swiper && swiper.realIndex < previewVideoIndex) {
      props.cookies.set('swipeUp', parseInt(props.cookies.get('swipeUp')) + 1, {
        path: '/'
      })

      if (parseInt(props.cookies.get('swipeDown')) > 0) {
        props.cookies.set(
          'swipeDown',
          parseInt(props.cookies.get('swipeDown')) - 1,
          { path: '/' }
        )
      }

      if (parseInt(props.cookies.get('swipeUp')) === props.frequency) {
        setPrevAdPlayerPausedAd(isPlayerPaused)
        setPlayerPaused(true)
        setIsShowAds(true)
        props.cookies.set('swipeDown', 0, { path: '/' })
        props.cookies.set('swipeUp', 0, { path: '/' })
      }
    } else {
      props.cookies.set(
        'swipeDown',
        parseInt(props.cookies.get('swipeDown')) + 1,
        { path: '/' }
      )

      if (parseInt(props.cookies.get('swipeUp')) > 0) {
        props.cookies.set(
          'swipeUp',
          parseInt(props.cookies.get('swipeUp')) - 1,
          { path: '/' }
        )
      }

      if (parseInt(props.cookies.get('swipeDown')) === props.frequency) {
        setPrevAdPlayerPausedAd(isPlayerPaused)
        setPlayerPaused(true)
        setIsShowAds(true)
        props.cookies.set('swipeDown', 0, { path: '/' })
        props.cookies.set('swipeUp', 0, { path: '/' })
      }
    }
  }, [props.cookies.get('counterAds')])

  const likeVideo = (id) => {
    ReactGA.event({
      category: 'Like button',
      action: 'Liked video'
    })
    props.setLikes(id)
    resetTimer()
  }

  const closeAds = () => {
    setIsShowAds(false)
    if (!prevAdPlayerPaused) {
      setPlayerPaused(false)
    }
    props.sendAds({ id: props.ads.id, click: viewed })
    props.resetAdState()
    setViewed(false)
    resetTimer()
  }

  const handleCloseModal = () => {
    document.querySelector('.ReactModal__Overlay').style.cssText =
      'bottom:-100% !important'
    setTimeout(() => {
      setAuthorizationModal(false)
      setShowModal(false)
      setCommentsModal(false)
      setTipModal(false)
      setRefillModal(false)
      resetTimer()
    }, 600)
  }

  const chooseCategory = (categoryId) => {
    setSelectedCategories((selectedCategories) => selectedCategories.includes(categoryId)
      ? selectedCategories.filter((item) => item !== categoryId)
      : [...selectedCategories, categoryId])
  }

  const renderListCategories = () => {
    return props.categories.map((item) => {
      return (
        <div key={item.id} className='modal-category-wrapper'>
          <div
            className={`category modal-category shareCtgBtn
              ${
        selectedCategories.includes(item.attributes.id) &&
                'category-active'
        }`}
            onClick={() => chooseCategory(item.attributes.id)}
            role='button'
            tabIndex='0'
          >
            {item.attributes.title}
          </div>
        </div>
      )
    })
  }

  const handleOpenRefillModal = () => {
    document.querySelector('.ReactModal__Overlay').style.cssText =
      'bottom:-100% !important'
    setTimeout(() => {
      setTipModal(false)
      setRefillModal(true)
    }, 500)
  }

  const handleAfterCloseFunc = () => {
    const isSameArray = oldUserCategories
      .sort((a, b) => a - b)
      .map(
        (item, index) => item === selectedCategories.sort((a, b) => a - b)[index]
      )
    if (
      (oldUserCategories.length !== selectedCategories.length ||
        isSameArray.includes(false)) &&
      !isEmpty(selectedCategories)
    ) {
      selectedCategories.map((item) => props.categories.find((category) => {
        return (
          parseInt(category.id) === item &&
            ReactGA.event({
              category: 'Category selection',
              action: `Selected category - ${category.attributes.title}`
            })
        )
      }))

      props.sendCategories(selectedCategories)
      historyRefresh.push('/v')
    } else if (isEmpty(selectedCategories)) {
      setSelectedCategories(oldUserCategories)
      toastr.error('Choose category', 'Please choose 1 or more categories')
    }
  }

  const copyUrl = `${process.env.SHARE_URL}/v`
  const fakeVideoId =
    swiper &&
    props.videos.length &&
    15000 + parseInt(props.videos[swiper.realIndex].id)

  const copyLink = () => {
    ReactGA.event({
      category: 'Copy button',
      action: 'Clicked copy'
    })
    const textArea = document.createElement('textArea')

    textArea.readOnly = true
    textArea.contentEditable = true
    textArea.value = `${copyUrl}/${fakeVideoId}`
    document.body.appendChild(textArea)

    if (isIOS) {
      const range = document.createRange()

      range.selectNodeContents(textArea)
      const selection = window.getSelection()

      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
    document.execCommand('copy')
    document.body.removeChild(textArea)
    setCopyLinkMessage('show')
    setTimeout(() => {
      setCopyLinkMessage('hide')
    }, 3000)
  }

  const { videos } = props
  const indexVideo = videos.findIndex(o => o.id === props.videoId)
  const params = {
    initialSlide: indexVideo,
    direction: 'vertical',
    observer: true,
    getSwiper: updateSwiper,
    shouldSwiperUpdate: true
  }
  const video = swiper && videos[swiper.realIndex]

  return (
    <div className='main-container' id='mc'>
      {isSafari && isIOS && isMobile && showSwipeTutorial && swipeTutorialText && (
        <div className='swipe-text'>
          <div className='bounce'>
            <ArrowTopCategories />
          </div>
          <div className='swipe-tutorial bounce'>
            swipe up on the address bar for full-screen
          </div>
          <div className='bounce'>
            <ArrowTopCategories />
          </div>
        </div>
      )}
      {!isMobile &&
        !localStorage.getItem('showedTutorial') &&
        !showAddToHomeScreen &&
        !showHelloModal &&
        !showUploadModal &&
        showTutorial &&
        !showReported &&
        !showReport && (
        <div
          className={`tutorial ${
            !fadeTutorial && unfadeTutorial
              ? 'tutorial-show'
              : 'tutorial-hide'
          }`}
          onClick={() => {
            playVideo()
            hideTutorial(3)
          }}
          role='button'
          tabIndex='0'
        >
          <img alt='img' src={Tutorial} />
        </div>
      )}
      {autoPlay && showAddToHomeScreen && (
        <HomeScreenModal showModal={setShowAddToHomeScreen} />
      )}
      {autoPlay && showUploadModal && (
        <UploadModal showModal={setShowUploadModal} />
      )}
      {autoPlay && showHelloModal && (
        <HelloModal
          showModal={setShowHelloModal}
          username={props.currentUser.username}
        />
      )}
      {autoPlay && showRefillModal && (
        <RefillModal
          currentUser={props.currentUser}
          getPacks={props.getPacks}
          handleCloseModal={handleCloseModal}
          packs={props.packs}
          packsLoading={props.packsLoading}
          showRefillModal={showRefillModal}
          videoId={video.id}
        />
      )}
      {showReported && (
        <div className='reported-icon'>
          <img alt='report' className='image' src={Reported} />
        </div>
      )}
      {showReport && (
        <Report
          createReport={changeShowReported}
          openedReport={setShowReport}
          videoId={props.videos[swiper.realIndex].id}
        />
      )}
      {!autoPlay && <ActivityIndicator />}
      {isShowAds && Object.keys(props.ads).length ? (
        <Ads ads={props.ads} closeAds={closeAds} setViewed={setViewed} />
      ) : null}
      {!isPlayerPaused && showSpinner && <Loading />}
      <Swiper {...params}>
        {videos.map((video, index) => (
          <Swipe onSwipeLeft={() => history.push(`/${video.attributes.user.username}`)} tolerance={100}>
            <div key={`${video.id + index}`} style={{ zIndex: 1 }}>
              <VideoSlide
                username={props.username}
                clearComments={props.clearComments}
                createPurchase={props.createPurchase}
                currentGuest={props.currentGuest}
                currentUser={props.currentUser}
                getComments={props.getComments}
                getUser={props.getUser}
                index={index}
                isButtonShown={isButtonShown}
                isPreviewShown={isPreviewShown}
                likeVideo={likeVideo}
                playVideo={playVideo}
                purchaseError={props.purchaseError}
                purchaseLoading={props.purchaseLoading}
                purchaseAnimation={props.purchaseAnimation}
                realIndex={swiper && swiper.realIndex}
                resetPurchaseState={props.resetPurchaseState}
                resetTimer={resetTimer}
                setCommentsModal={setCommentsModal}
                setRefillModal={setRefillModal}
                setShowModal={setShowModal}
                setTipModal={setTipModal}
                setAuthorizationModal={setAuthorizationModal}
                stateOpacity={stateOpacity}
                translateX={translateX}
                video={video}
              />
            </div>
          </Swipe>
        ))}
      </Swiper>
      {swiper && videos[swiper.realIndex] && (
        <>
          <div className='main-player'>
            <Player
              isPlayerPaused={isPlayerPaused}
              isShowAds={isShowAds}
              nextVideo={(videos[swiper.realIndex + 1] === null) ? (
                videos[swiper.realIndex + 1]) : (videos[swiper.realIndex])}
              playerRef={playerRef}
              setPlayerPaused={setPlayerPaused}
              setPreviewShown={setPreviewShown}
              setShowSpinner={setShowSpinner}
              video={videos[swiper.realIndex]}
            />
          </div>
          <CommentsModal
            commentLoading={props.commentLoading}
            commentReplySetLike={props.commentReplySetLike}
            comments={props.comments}
            commentsCount={video.attributes.comments_count}
            commentSetLike={props.commentSetLike}
            commentsLoading={props.commentsLoading}
            createComment={props.createComment}
            currentGuest={props.currentGuest}
            currentUser={props.currentUser}
            getComments={props.getComments}
            handleCloseModal={handleCloseModal}
            hasMoreComments={props.hasMoreComments}
            showCommentsModal={showCommentsModal}
            videoId={video.id}
          />
          <SignInModalContainer
            handleCloseModal={handleCloseModal}
            showAuthorizationModal={showAuthorizationModal}
          />
          <TipModal
            createTip={props.createTip}
            currentUser={props.currentUser}
            handleCloseModal={handleCloseModal}
            handleOpenRefillModal={handleOpenRefillModal}
            receiver={video.attributes.user.username}
            resetTipState={props.resetTipState}
            showTipModal={showTipModal}
            tipCreated={props.tipCreated}
            tipLoading={props.tipLoading}
            videoId={video.id}
          />
          <Modal
            className='Modal'
            contentLabel='onRequestClose Example'
            isOpen={showModal}
            onAfterClose={() => handleAfterCloseFunc()}
            onAfterOpen={() => setOldUserCategories(selectedCategories)}
            onRequestClose={handleCloseModal}
            overlayClassName='Overlay'
          >
            <div className='modal-share-stuff'>
              <p className='modal-title'>Share & stuff</p>
              <div className={`link-copied-block ${copyLinkMessage}`}>
                Link copied to clipboard
              </div>
              <div
                className='modal-buttons-block'
                style={!isMobile ? { justifyContent: 'space-between' } : {}}
              >
                <a
                  className='modal-buttons first-button shareItemBtn'
                  href={`sms:${isIOS ? '&' : '?'}body=${copyUrl}/${
                    props.videos[swiper.realIndex].id
                  }`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'SMS button',
                      action: 'Clicked Share via sms'
                    })
                  }}
                >
                  <div className='modal-svg-wrapper sms-wrapper'>
                    <SMSIcon />
                  </div>
                  <p className='modal-svg-title'>SMS</p>
                </a>
                <div
                  className='modal-buttons'
                  onClick={() => copyLink()}
                  role='button'
                  tabIndex='0'
                >
                  <div className='modal-svg-wrapper link-wrapper'>
                    <CopyLinkIcon />
                  </div>
                  <p className='modal-svg-title'>Copy Link</p>
                </div>
                <a
                  className='modal-buttons mail-button shareItemBtn'
                  href={`mailto:?body=Check out @${
                    props.videos[swiper.realIndex].attributes.user.username
                  }'s video! #qwki %0D%0A%0D%0A${copyUrl}/${
                    props.videos[swiper.realIndex].id
                  }&subject=@${
                    props.videos[swiper.realIndex].attributes.user.username
                  } on Qwki`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Email button',
                      action: 'Clicked Share via email'
                    })
                  }}
                >
                  <div className='modal-svg-wrapper email-wrapper'>
                    <EmailIcon />
                  </div>
                  <p className='modal-svg-title'>Email</p>
                </a>
                <div className='modal-buttons'>
                  <TwitterShareButton
                    title={`Watch ${
                      props.videos[swiper.realIndex].attributes.user.username
                    }'s Qwki - ${
                      props.videos[swiper.realIndex].attributes.description
                    } @QwkiXXX #amateurporn #FlauntStareSwipe`}
                    url={`${copyUrl}/${fakeVideoId}`}
                  >
                    <div className='modal-svg-wrapper twitter-wrapper shareItemBtn'>
                      <TwitterIcon
                        onClick={() => {
                          ReactGA.event({
                            category: 'Twitter button',
                            action: 'Clicked Share via twitter'
                          })
                        }}
                      />
                    </div>
                    <p className='modal-svg-title'>Twitter</p>
                  </TwitterShareButton>
                </div>
                <div className='modal-buttons'>
                  <div className='modal-svg-wrapper fullscreen-wrapper-about shareItemBtn'>
                    <AboutUsStaffIcon
                      onClick={() => {
                        ReactGA.event({
                          category: 'About us button',
                          action: 'Clicked about us'
                        })
                        history.push('/about')
                      }}
                    />
                  </div>
                  <p className='modal-svg-title'>About Us </p>
                </div>
                <div className='modal-buttons'>
                  <div className='modal-svg-wrapper report-wrapper'>
                    <ReportIcon
                      onClick={() => {
                        ReactGA.event({
                          category: 'Report button',
                          action: 'Clicked report'
                        })
                        setShowReport(true)
                      }}
                    />
                  </div>
                  <p className='modal-svg-title'>Report</p>
                </div>
                <div className={`modal-buttons ${isMobile && 'last-button'}`}>
                  <div className='modal-svg-wrapper fullscreen-wrapper shareItemBtn'>
                    <TabletIcon
                      onClick={() => {
                        window.history.replaceState(
                          { showAddToHomeModal: false },
                          '',
                          '/v'
                        )
                        handleCloseModal()
                        setShowAddToHomeScreen(true)
                        ReactGA.event({
                          category: 'Fullscreen button',
                          action: 'Clicked fullscreen'
                        })
                      }}
                    />
                  </div>
                  <p className='modal-svg-title'>Fullscreen</p>
                </div>
              </div>
            </div>
            <div>
              <p className='modal-title'>Show me</p>
              <div className='modal-buttons-block show-me-block'>
                {renderListCategories()}
              </div>
            </div>
            {
              (isEmpty(props.currentUser) && !isEmpty(props.currentGuest))
                ? (
                  <div className='modal-login'>
                    <button
                      className='auth-button'
                      onClick={() => history.push('/sign_up')}
                      type='button'
                    >
                      <p className='modal-login-buttons'>Create an account</p>
                    </button>
                    <p className='modal-login-separator'>|</p>
                    <button
                      className='auth-button'
                      onClick={() => history.push('/sign_in')}
                      type='button'
                    >
                      <p className='modal-login-buttons'>Login</p>
                    </button>
                  </div>
                )
                : (
                  <div className='modal-login'>
                    <button
                      className='auth-button shareProfileBtn'
                      onClick={() => history.push(`/${props.currentUser.username}`)}
                      type='button'
                    >
                      <p>@{props.currentUser.username}</p>
                    </button>
                    <p className='modal-login-separator'>|</p>
                    <button
                      className='auth-button'
                      onClick={() => props.onDeleteSession()}
                      type='button'
                    >
                      <p>Logout</p>
                    </button>
                  </div>
                )
            }
          </Modal>
        </>
      )}
      <NavigationBar
        currentGuest={props.currentGuest}
        currentUser={props.currentUser}
      />
    </div>
  )
}

UserVideos.propTypes = {
  videoId: PropTypes.string,
  username: PropTypes.string,
  videos: PropTypes.instanceOf(Array).isRequired,
  getComments: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  hasMoreComments: PropTypes.bool.isRequired,
  comments: PropTypes.array.isRequired,
  commentsLoading: PropTypes.bool.isRequired,
  commentLoading: PropTypes.bool.isRequired,
  setView: PropTypes.func.isRequired,
  setLikes: PropTypes.func.isRequired,
  createTip: PropTypes.func.isRequired,
  resetTipState: PropTypes.func.isRequired,
  createPurchase: PropTypes.func.isRequired,
  purchaseLoading: PropTypes.bool.isRequired,
  purchaseAnimation: PropTypes.bool,
  purchaseError: PropTypes.string,
  commentSetLike: PropTypes.func.isRequired,
  commentReplySetLike: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
  getVideos: PropTypes.func.isRequired,
  ads: PropTypes.object,
  scripts: PropTypes.array.isRequired,
  packs: PropTypes.array,
  pack: PropTypes.object,
  packsLoading: PropTypes.bool.isRequired,
  getAds: PropTypes.func.isRequired,
  getPacks: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  sendAds: PropTypes.func.isRequired,
  frequency: PropTypes.number,
  usersCategories: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  tipLoading: PropTypes.bool.isRequired,
  tipCreated: PropTypes.bool.isRequired,
  sendCategories: PropTypes.func.isRequired,
  onDeleteSession: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    username: PropTypes.string
  }),
  currentGuest: PropTypes.object,
  resetVideoState: PropTypes.func.isRequired,
  resetPurchaseState: PropTypes.func.isRequired,
  resetAdState: PropTypes.func.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  showHelloModal: PropTypes.bool.isRequired,
  showUploadModal: PropTypes.bool.isRequired,
  showAddToHomeModal: PropTypes.bool.isRequired
}

export default withCookies(UserVideos)
