import * as actions from './actions'

const initialResourceState = {
  loading: false,
  tipCreated: false
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.CREATE_TIP_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.CREATE_TIP_SUCCESS:
    return {
      ...state,
      loading: false,
      tipCreated: true
    }

  case actions.CREATE_TIP_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.RESET_TIP_STATE:
    return {
      ...state,
      loading: false,
      tipCreated: false
    }

  default:
    return state
  }
}
