import * as actions from './actions'

const initialResourceState = {
  loading: true,
  details: [],
  error: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.GET_CARDS_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.GET_CARDS_SUCCESS:
    return {
      ...state,
      loading: false,
      details: payload
    }

  case actions.GET_CARDS_FAILURE:
    return {
      ...state,
      loading: false
    }

  case actions.ADD_CARD_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.ADD_CARD_SUCCESS:
    return {
      ...state,
      loading: false,
      details: [payload.card, ...state.details]
    }

  case actions.ADD_CARD_FAILURE:
    return {
      ...state,
      loading: false,
      error: { message: payload.response.data.error }
    }
  default:
    return state
  }
}
