import * as actions from './actions'

const initialResourceState = {
  loading: false,
  paymentOpen: false,
  detail: { message: null, error: null }
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.PAYMENT_CREATE_REQUEST:
    return {
      ...state,
      loading: true,
      paymentOpen: true
    }

  case actions.PAYMENT_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      detail: { message: payload.message, error: null }
    }

  case actions.PAYMENT_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      detail: { message: null, error: payload.response.data.error }
    }

  case actions.RESET_PAYMENT_STATE:
    return {
      ...state,
      loading: false,
      paymentOpen: false,
      detail: { message: null, error: null }
    }

  default:
    return state
  }
}
