import * as React from 'react'

const NoCheckIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx={8} cy={8} r={7} stroke="#02E8E8" strokeWidth={2} />
    </svg>
  )
}

export default NoCheckIcon
