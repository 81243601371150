export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'

export const getProfileRequest = (username) => ({
  type: GET_PROFILE_REQUEST,
  meta: {
    resource: `profiles/${username}`
  }
})

export const getProfileSuccess = (detail) => ({
  type: GET_PROFILE_SUCCESS,
  payload: detail
})

export const getProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  error: true,
  payload: error
})

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

export const updateProfileRequest = (username, payload) => ({
  type: UPDATE_PROFILE_REQUEST,
  meta: {
    resource: `profiles/${username}`
  },
  payload
})

export const updateProfileSuccess = (detail) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: detail
})

export const updateProfileFailure = (error) => ({
  type: UPDATE_PROFILE_FAILURE,
  error: true,
  payload: error
})

export const GET_PROFILE_VIDEOS_REQUEST = 'GET_PROFILE_VIDEOS_REQUEST'
export const GET_PROFILE_VIDEOS_SUCCESS = 'GET_PROFILE_VIDEOS_SUCCESS'
export const GET_MORE_PROFILE_VIDEOS_SUCCESS = 'GET_MORE_PROFILE_VIDEOS_SUCCESS'
export const GET_PROFILE_VIDEOS_FAILURE = 'GET_PROFILE_VIDEOS_FAILURE'
export const RESET_PROFILE_VIDEOS_STATE = 'RESET_PROFILE_VIDEOS_STATE'

export const getProfileVideosRequest = ({ id, type, page }) => ({
  type: GET_PROFILE_VIDEOS_REQUEST,
  meta: {
    resource: `profiles/${id}/videos/${type}/${page}`
  }
})

export const getProfileVideosSuccess = (detail) => ({
  type: GET_PROFILE_VIDEOS_SUCCESS,
  payload: detail
})

export const getMoreProfileVideosSuccess = (detail) => ({
  type: GET_MORE_PROFILE_VIDEOS_SUCCESS,
  payload: detail
})

export const getProfileVideosFailure = (error) => ({
  type: GET_PROFILE_VIDEOS_FAILURE,
  error: true,
  payload: error
})

export const UPDATE_PROFILE_VIDEO_REQUEST = 'UPDATE_PROFILE_VIDEO_REQUEST'
export const UPDATE_PROFILE_VIDEO_SUCCESS = 'UPDATE_PROFILE_VIDEO_SUCCESS'
export const UPDATE_PROFILE_VIDEO_FAILURE = 'UPDATE_PROFILE_VIDEO_FAILURE'

export const updateProfileVideoRequest = (id, data) => ({
  type: UPDATE_PROFILE_VIDEO_REQUEST,
  meta: {
    resource: `profile/videos/${id}`
  },
  payload: data
})

export const updateProfileVideoSuccess = (detail) => ({
  type: UPDATE_PROFILE_VIDEO_SUCCESS,
  payload: detail
})

export const updateProfileVideoFailure = (error) => ({
  type: UPDATE_PROFILE_VIDEO_FAILURE,
  error: true,
  payload: error
})

export const GET_PROFILE_VIDEO_REQUEST = 'GET_PROFILE_VIDEO_REQUEST'
export const GET_PROFILE_VIDEO_SUCCESS = 'GET_PROFILE_VIDEO_SUCCESS'
export const GET_PROFILE_VIDEO_FAILURE = 'GET_PROFILE_VIDEO_FAILURE'

export const getProfileVideoRequest = (id) => ({
  type: GET_PROFILE_VIDEO_REQUEST,
  meta: {
    resource: `profile/videos/${id}`
  }
})

export const getProfileVideoSuccess = (detail) => ({
  type: GET_PROFILE_VIDEO_SUCCESS,
  payload: detail
})

export const getProfileVideoFailure = (error) => ({
  type: GET_PROFILE_VIDEO_FAILURE,
  error: true,
  payload: error
})

export const REMOVE_PROFILE_VIDEO_REQUEST = 'REMOVE_PROFILE_VIDEO_REQUEST'
export const REMOVE_PROFILE_VIDEO_SUCCESS = 'REMOVE_PROFILE_VIDEO_SUCCESS'
export const REMOVE_PROFILE_VIDEO_FAILURE = 'REMOVE_PROFILE_VIDEO_FAILURE'

export const removeProfileVideoRequest = (id) => ({
  type: REMOVE_PROFILE_VIDEO_REQUEST,
  meta: {
    resource: `profile/videos/${id}`
  }
})

export const removeProfileVideoSuccess = (detail) => ({
  type: REMOVE_PROFILE_VIDEO_SUCCESS,
  payload: detail
})

export const removeProfileVideoFailure = (error) => ({
  type: REMOVE_PROFILE_VIDEO_FAILURE,
  error: true,
  payload: error
})

export const resetProfileVideosState = () => ({
  type: RESET_PROFILE_VIDEOS_STATE
})
