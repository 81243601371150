import * as React from 'react'

const VisaType = (props) => {
  return (
    <svg
      width='68'
      height='23'
      viewBox='0 0 68 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.4'
        d='M29.4786 22.4928H23.93L27.3978 1.18062H32.947L29.4786 22.4928ZM19.2621 1.18062L13.9723 15.8393L13.3463 12.6827L13.3469 12.6839L11.4798 3.09954C11.4798 3.09954 11.2541 1.18062 8.84775 1.18062H0.102616L0 1.54149C0 1.54149 2.67428 2.09789 5.80406 3.97747L10.6247 22.4934H16.406L25.2338 1.18062H19.2621ZM62.9051 22.4928H68L63.5579 1.18005H59.0975C57.0378 1.18005 56.5361 2.76832 56.5361 2.76832L48.2607 22.4928H54.0448L55.2015 19.3271H62.2552L62.9051 22.4928ZM56.7995 14.9539L59.7149 6.97841L61.3551 14.9539H56.7995ZM48.6946 6.30571L49.4864 1.72905C49.4864 1.72905 47.043 0.799805 44.4959 0.799805C41.7424 0.799805 35.2035 2.00326 35.2035 7.85521C35.2035 13.3611 42.878 13.4295 42.878 16.3216C42.878 19.2136 35.9942 18.6954 33.7224 16.8717L32.8975 21.657C32.8975 21.657 35.375 22.8605 39.1604 22.8605C42.9469 22.8605 48.6592 20.9 48.6592 15.5639C48.6592 10.0227 40.9157 9.50676 40.9157 7.09757C40.9163 4.6878 46.3202 4.99736 48.6946 6.30571Z'
        fill='white'
      />
    </svg>
  )
}

export default VisaType
