import React, { useState, useEffect } from 'react'
import * as EmailValidator from 'email-validator'
import PropTypes from 'prop-types'

import './styles.scss'
import {
  LogInIcon, CheckIcon, NoCheckIcon,
  UnFocusUsernameIcon, FocusUsernameIcon, FocusEmailIcon,
  UnFocusEmailIcon, FocusPasswordIcon, UnFocusPasswordIcon,
  SuccessIcon, ErrorIcon, EyeOffIcon, EyeOnIcon
} from '../../assets/svg'
import { history } from '../../../services'

const SignUp = ({
  emailPresent,
  usernamePresent,
  getValidation,
  postAuth,
  goToQuill,
  isUS,
  resetValidEmail,
  resetValidUsername,
  userCreated
}) => {
  const [checked, setChecked] = useState(false)
  const [focusedInput, setFocusedInput] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [changed, setChanged] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [errorPassword, setErrorPassword] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [errorEmail, setErrorEmail] = useState('')
  const [validUsername, setValidUsername] = useState(false)
  const [errorUsername, setErrorUsername] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const regex = /^[0-9a-zA-Z]*$/
  const passwordRegex = /^(?=.*[\d])(?=.*[a-z])[\w!@#$%^&*-/= ]{8,}$/

  useEffect(() => {
    setChecked(isUS)
  }, [isUS])

  useEffect(() => {
    if (password.length >= 8 && passwordRegex.test(password)) {
      setValidPassword(true)
      setErrorPassword('')
    } else if (password.length > 0 && password.length < 8) {
      setValidPassword(false)
      setErrorPassword('Min. 8 characters, at least 1 letter and number.')
    } else if (!password && changed === 'password') {
      setValidPassword(false)
      setErrorPassword('Is required')
    } else if (!passwordRegex.test(password) && changed === 'password') {
      setValidPassword(false)
      setErrorPassword('Min. 8 characters, at least 1 letter and number.')
    }
  }, [password, changed])

  useEffect(() => {
    if (email.length && EmailValidator.validate(email) && focusedInput !== 'email') {
      if (focusedInput !== 'email' && changed === 'email') {
        if (!emailPresent) {
          setErrorEmail('')
          setValidEmail(true)
        } else {
          setValidEmail(false)
          setErrorEmail(`${email} already in use. Please login.`)
        }
      }
    } else if (email.length && !EmailValidator.validate(email) && focusedInput !== 'email') {
      setValidEmail(false)
      setErrorEmail('Not a valid email')
    } else if (!email && changed === 'email') {
      setValidEmail(false)
      setErrorEmail('Is required')
    } else if (changed === 'email' && focusedInput === 'email') {
      setValidEmail(false)
      setErrorEmail('')
    }
  }, [email, changed, getValidation, emailPresent, focusedInput])

  useEffect(() => {
    if (username.length >= 4 && username.length <= 25 && regex.test(username) && focusedInput !== 'username') {
      if (focusedInput !== 'username' && changed === 'username') {
        if (!regex.test(username)) {
          setValidUsername(false)
          setErrorUsername('Not a valid username.')
        } else if (!usernamePresent) {
          setValidUsername(true)
          setErrorUsername('')
        } else {
          setValidUsername(false)
          setErrorUsername('Username already taken.')
        }
      }
    } else if (!username && changed === 'username') {
      setValidUsername(false)
      setErrorUsername('Is required.')
    } else if ((username.length > 0 && username.length < 4)) {
      setValidUsername(false)
      setErrorUsername('Username must be 4-25 characters.')
    } else if (!regex.test(username) && changed === 'username') {
      setValidUsername(false)
      setErrorUsername('No spaces or symbols.')
    } else if (changed === 'username' && focusedInput === 'username') {
      setValidUsername(false)
      setErrorUsername('')
    }
  }, [username, changed, getValidation, focusedInput, usernamePresent])

  useEffect(() => {
    localStorage.removeItem('modalClose')
    const videoId = localStorage.getItem('currentVideoId')
    if (userCreated && videoId) {
      history.push(`/v/${videoId}`)
    }
  }, [userCreated])

  const errors = {
    email: errorEmail,
    password: errorPassword,
    username: errorUsername
  }

  const hidePassword = () => {
    setShowPassword(!showPassword)
  }

  const valid = () => {
    return validEmail && validPassword && validUsername
  }

  return (
    <div className='sign-up-container'>
      <button className='sign-in-button' onClick={() => history.push('/sign_in')} type='button'>
        <LogInIcon />
        <p className='sign-in-text'>
          Already have an account? Login <span className='text-underline'>here</span>.
        </p>
      </button>
      <div className='form-wrap'>
        <div className='form'>
          <div className='hr' />
          <div className='header'>
            <p className='title'>Create account</p>
            <div className='header-text'>
              <p className='p pink'>It&#39;s free.</p>
              <p className='p gray'>Members can...</p>
              <p className='p pink'>Upload videos</p>
              <p className='p pink'>Give unlimited likes</p>
            </div>
          </div>
          <div className='inputs'>
            <div className='little-hr' />
            <div className='input-wrap'>
              {
                focusedInput === 'username' || username.length
                  ? <FocusUsernameIcon className='icon' />
                  : <UnFocusUsernameIcon className='icon' />
              }
              {
                validUsername &&
                <SuccessIcon className='valid-icon' />
              }
              {
                errors.username && (
                  <>
                    <ErrorIcon className='error-icon' />
                    <p className='error-text'>{errors.username}</p>
                  </>
                )
              }
              <input
                autoComplete='off'
                className='input'
                maxLength='25'
                onBlur={() => { setFocusedInput(''); getValidation('username', username) }}
                onChange={(e) => { setUsername(e.target.value); setChanged('username') }}
                onFocus={() => { setFocusedInput('username'); resetValidUsername('') }}
                placeholder='Enter username'
                type='text'
                value={username}
              />
              <div className='inputs-hr' />
            </div>
            <div className='input-wrap'>
              {
                focusedInput === 'email' || email.length
                  ? <FocusEmailIcon className='icon' />
                  : <UnFocusEmailIcon className='icon' />
              }
              {
                validEmail &&
                <SuccessIcon className='valid-icon' />
              }
              {
                errors.email && (
                  <>
                    <ErrorIcon className='error-icon' />
                    <p className='error-text'>{errors.email}</p>
                  </>
                )
              }
              <input
                autoComplete='off'
                className='input'
                onBlur={() => { setFocusedInput(''); getValidation('email', email) }}
                onChange={(e) => { setEmail(e.target.value); setChanged('email') }}
                onFocus={() => { setFocusedInput('email'); resetValidEmail('') }}
                placeholder='Enter email'
                type='text'
                value={email}
              />
              <div className='inputs-hr' />
            </div>
            <div className='input-wrap'>
              {
                focusedInput === 'password' || password.length
                  ? <FocusPasswordIcon className='icon' />
                  : <UnFocusPasswordIcon className='icon' />
              }
              {
                validPassword &&
                <SuccessIcon className='valid-icon' />
              }
              {
                (errors.password ||
                (focusedInput === 'password' && password.length < 8 && password.length >= 1)) && (
                  <>
                    <ErrorIcon className='error-icon' />
                    <p className='error-text'>{errors.password || 'Password too short'}</p>
                  </>
                )
              }
              {
                password && password.length && (
                  showPassword
                    ? <EyeOnIcon className='eye-icon' onClick={() => hidePassword()} />
                    : <EyeOffIcon className='eye-icon' onClick={() => hidePassword()} />
                )
              }
              <input
                autoComplete='new-password'
                className='input'
                onBlur={() => { setFocusedInput(''); setChanged('') }}
                onChange={(e) => { setPassword(e.target.value); setChanged('password') }}
                onFocus={() => setFocusedInput('password')}
                placeholder='Enter password'
                type={showPassword ? 'text' : 'password'}
                value={password}
              />
              <div className='inputs-hr bottom' />
            </div>
          </div>
          <div className='buttons-container'>
            <div className='check-container' onClick={() => setChecked(!checked)} role='button' tabIndex={0}>
              {
                checked
                  ? <CheckIcon />
                  : <NoCheckIcon />
              }
              <p className='check'>
                I agree to receive updates and offers from Qwki. Opt-out anytime.
              </p>
            </div>
            <div className='buttons'>
              <button
                className='done button'
                disabled={!valid()}
                onClick={() => postAuth(username, password, email)}
                type='button'
              >
                <p className='p-button done-color'>Done</p>
              </button>
              <button className='cancel button' onClick={() => history.push('/v')} type='button'>
                <p className='p-button cancel-color'>Cancel</p>
              </button>
            </div>
            <div className='under-buttons-container'>
              <p className='by-creating-text'>
                By creating an account, you agree to our
                <button
                  className='link'
                  onClick={() => goToQuill('Terms Of Use', 'terms_of_use')}
                  type='button'
                >&nbsp;Terms of Use
                </button> and that you have read our
                <button
                  className='link'
                  onClick={() => goToQuill('Privacy Policy', 'privacy_policy')}
                  type='button'
                >
                  &nbsp;Privacy Policy
                </button> and
                <button
                  className='link'
                  onClick={() => goToQuill('Community Guidelines', 'community_guidlines')}
                  type='button'
                >&nbsp;Community Guidelines
                </button>
              </p>
            </div>
          </div>
          <div className='hr' />
        </div>
      </div>
    </div>
  )
}

SignUp.propTypes = ({
  emailPresent: PropTypes.bool,
  usernamePresent: PropTypes.bool,
  getValidation: PropTypes.func.isRequired,
  postAuth: PropTypes.func.isRequired,
  goToQuill: PropTypes.func.isRequired,
  isUS: PropTypes.bool.isRequired,
  resetValidEmail: PropTypes.func.isRequired,
  resetValidUsername: PropTypes.func.isRequired,
  userCreated: PropTypes.bool
})

export default SignUp
