/* eslint-disable */

import React, { Component } from 'react'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import MetaTags from 'react-meta-tags'
import { isIOS, isMacOs, isSafari } from 'react-device-detect'
import ReactGA from 'react-ga'
import classNames from 'classnames'

import './App.scss'
import { store } from './store'
import Session from './services/Session'
import { QRCodeIcon } from './components/assets/svg'

import Iphone5 from '../iphone5_splash.public.png'
import Iphone6 from '../iphone6_splash.public.png'
import IphonePlus from '../iphoneplus_splash.public.png'
import IphoneX from '../iphonex_splash.public.png'
import IphoneXr from '../iphonexr_splash.public.png'
import IphoneMax from '../iphonexsmax_splash.public.png'
import QwkiLogo from '../qwki_logo.public.png'
import FavIcon from '../favicon.public.png'

ReactGA.initialize('UA-52571900-4')

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showQR: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showQR: true })
    }, 1400)
  }

  render() {
    return (
      <Provider store={store}>
        <ReduxToastr
          closeOnToastrClick
          newestOnTop={false}
          position='top-right'
          preventDuplicates
          timeOut={3000}
          transitionIn='fadeIn'
          transitionOut='fadeOut'
        />
        <div className='App'>
          {isIOS && (
            <MetaTags>
              <meta
                content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
                name='viewport'
              />
            </MetaTags>
          )}
          <Session />
        </div>
        <div
          style={ isSafari ? { fontSize: '15px' } : { fontSize: '17px' } }
          className={
            classNames(
              'qr-block',
              { 'qr-block-hide': !this.state.showQR }
            )
          }
        >
          <div className='qr-text'>
            <p>We’re made for phones, but hey, we’re happy you’re here!</p>
            <p>To have the best time, go to qwki.xxx on your iOS or Android, or just scan this QR code with your phone camera.</p>
          </div>
          <QRCodeIcon />
        </div>
      </Provider>
    )
  }
}

export default App
