import * as actions from './actions'

const initialResourceState = {
  loading: false,
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.REPORT_CREATE_REQUEST:
    return {
      ...state,
      loading: true,
      errors: {}
    }

  case actions.REPORT_CREATE_SUCCESS:
    return {
      ...state,
      loading: false
    }

  case actions.REPORT_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload.response.data
    }

  default:
    return state
  }
}
