import { put, call, takeLatest } from 'redux-saga/effects'

import { history, request } from '../../services'
import * as actions from './actions'

function* getFrequency({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.frequencyIndexSuccess(detail))
  } catch (e) {
    yield put(actions.frequencyIndexFailure(e))
  }
}

function* watchFrequencyIndexRequest({ meta }) {
  yield call(getFrequency, meta, history)
}

export default function* () {
  yield takeLatest(actions.FREQUENCY_INDEX_REQUEST, watchFrequencyIndexRequest)
}
