import { put, call, takeLatest } from 'redux-saga/effects'

import { request, history } from '../../services'
import * as actions from './actions'
import { sessionShowRequest } from '../session/actions'

function* createUser({ data }, { resource }) {
  try {
    yield request(resource, 'post', data)
    yield put(actions.userCreateSuccess())
    yield put(sessionShowRequest())
    yield history.push('/v', { showHelloModal: true })
  } catch (e) {
    yield put(actions.userCreateFailure(e))
  }
}

function* watchUserCreateRequest({ payload, meta }) {
  yield call(createUser, payload, meta)
}

export default function* () {
  yield takeLatest(actions.USER_CREATE_REQUEST, watchUserCreateRequest)
}
