import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'
import { sessionShowRequest } from '../session/actions'

function* getPacks({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.packsIndexSuccess(detail))
  } catch (e) {
    yield put(actions.packsIndexFailure(e))
  }
}

function* getPack({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.packGetSuccess(detail))
  } catch (e) {
    yield put(actions.packGetFailure(e))
  }
}

function* watchGetPacksRequest({ meta }) {
  yield put(sessionShowRequest())
  yield call(getPacks, meta)
}

function* watchGetPackRequest({ meta }) {
  yield call(getPack, meta)
}

export default function* () {
  yield takeLatest(actions.GET_PACKS_REQUEST, watchGetPacksRequest)
  yield takeLatest(actions.GET_PACK_REQUEST, watchGetPackRequest)
}
