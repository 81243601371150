import * as React from 'react'

const CheckMarkerIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle opacity="0.9" cx="20" cy="20" r="20" fill="black"/>
      <path d="M29 14L18 25L13 20" stroke="#00FF75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
      <linearGradient id="paint0_linear" x1="13" y1="20.5999" x2="29" y2="20.5999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#00FF75"/>
        <stop offset="1" stopColor="#00FFC2"/>
      </linearGradient>
      </defs>
    </svg>    
  )
}

export default CheckMarkerIcon
