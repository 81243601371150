import * as React from 'react'

const CheckIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M14.667 7.387V8a6.667 6.667 0 11-3.954-6.093"
        stroke="#02E8E8"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.667 2.667L8 9.34l-2-2"
        stroke="#9A9A9A"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckIcon
