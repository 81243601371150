import * as React from "react"

const QwkiCategoriesLogo = (props) => {
  return (
    <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M54.397 69.4428C49.7736 71.7895 44.5407 73.1113 38.9971 73.1113C20.1626 73.1113 4.88873 57.8379 4.88873 38.997C4.88873 20.1623 20.1624 4.88869 38.9971 4.88869C57.8379 4.88869 73.1114 20.1625 73.1114 38.997C73.1114 46.5298 70.6679 53.4862 66.535 59.1289C65.2606 60.7831 63.2583 61.8395 61.008 61.8395C57.1511 61.8395 54.0255 58.7139 54.0255 54.857C54.0255 53.3203 54.5153 51.8907 55.3459 50.7427L55.3465 50.742L55.3494 50.7379L55.3499 50.7372C57.7213 47.4342 59.1227 43.3793 59.1227 38.997C59.1227 27.8821 50.1121 18.8715 38.9971 18.8715C27.8821 18.8715 18.8715 27.8821 18.8715 38.997C18.8715 50.112 27.8821 59.1226 38.9971 59.1226C40.7211 59.1226 42.3921 58.9059 43.988 58.4994L54.397 69.4428ZM61.008 65.5163C64.3612 65.5163 67.366 63.9706 69.3182 61.5374C71.2016 64.0079 70.956 67.6075 68.5818 69.8658C66.0796 72.2458 62.2072 72.1899 59.8542 69.8422L45.3111 54.5522L45.0959 54.3259L45.0934 54.3266L42.4264 51.5227C40.0856 49.0618 40.1868 45.1048 42.7296 42.6862C45.2723 40.2677 49.2294 40.3645 51.5701 42.8255L54.1129 45.4988C53.6409 46.596 53.0531 47.6314 52.3651 48.5901C51.0894 50.3543 50.3486 52.5349 50.3486 54.857C50.3486 60.7445 55.1204 65.5163 61.008 65.5163ZM38.9971 76.7881C45.5229 76.7881 51.6704 75.1316 57.0305 72.2115L57.0771 72.2606C60.8514 76.2287 67.1378 76.3137 71.1159 72.5299C75.0939 68.7461 75.3234 62.4633 71.5491 58.4952L71.4399 58.3804C74.834 52.7132 76.7882 46.0818 76.7882 38.997C76.7882 18.1315 59.8682 1.21187 38.9971 1.21187C18.1317 1.21187 1.21191 18.1317 1.21191 38.997C1.21191 59.8681 18.1315 76.7881 38.9971 76.7881ZM55.2742 41.3848L54.2343 40.2914C50.4599 36.3233 44.1736 36.2383 40.1955 40.0221C36.2174 43.8059 35.9879 50.0887 39.7623 54.0568L40.9722 55.3288C40.3256 55.406 39.6667 55.4458 38.9971 55.4458C29.9128 55.4458 22.5484 48.0814 22.5484 38.997C22.5484 29.9127 29.9128 22.5483 38.9971 22.5483C48.0814 22.5483 55.4458 29.9127 55.4458 38.997C55.4458 39.8086 55.3872 40.6058 55.2742 41.3848Z" fill="white" stroke="white"/>
    </svg>

  )
}
export default QwkiCategoriesLogo
