/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import BeatLoader from 'react-spinners/BeatLoader'
import ReactGA from 'react-ga'

import { history } from '../../../services'
import { ModalCloseIcon } from '../../assets/svg'
import './styles.scss'

const RefillModal = (props) => {
  const {
    showRefillModal,
    handleCloseModal,
    getPacks,
    packs,
    packsLoading,
    currentUser
  } = props

  useEffect(() => {
    if (showRefillModal) {
      getPacks()
    }
  }, [showRefillModal])

  const handleSelectPack = (pack) => {
    ReactGA.event({
      category: 'Refill popup',
      action: `Selected ${pack.title} pack with ${pack.tokens_amount} tokens amount`
    })
    history.push(`/purchase/${pack.tokens_amount}`)
  }

  return (
    <Modal
      ariaHideApp={false}
      className='refill-modal'
      contentLabel='onRequestClose Example'
      isOpen={showRefillModal}
      onRequestClose={handleCloseModal}
      overlayClassName='Overlay'
    >
      <div className='refill-modal__title'>
        <a className='refill-modal__title__close refillCloseBtn' role='button'>
          Refill
          <ModalCloseIcon onClick={() => handleCloseModal()} />
        </a>
        <div className='refill-modal__title__tokens'>
          {currentUser.tokens_count} tokens
        </div>
      </div>
      {packsLoading && (
        <div className='refill-modal__items__loader'>
          <BeatLoader color='#9a9a9a' size={15} />
        </div>
      )}
      {!packsLoading && packs.length === 0 && (
        <div className='refill-modal__items__loader'>
          <p>Sales temporarily suspended</p>
        </div>
      )}
      {!packsLoading && packs.length > 0 && (
        <div className='refill-modal__items'>
          {packs.map((pack) => (
            <a
              key={pack.id}
              className='refill-modal__item refillPackBtn'
              onClick={() => handleSelectPack(pack)}
              role='button'
              tabIndex={0}
            >
              <div className='refill-modal__item__price'>
                <div className='refill-modal__item__price__content'>
                  <span>{pack.tokens_amount}</span>
                  <p>${pack.price}</p>
                  {pack.bonus_title && (
                    <p className='refill-modal__item__price__bonus'>
                      ({pack.bonus_title})
                    </p>
                  )}
                </div>
              </div>
              <div className='refill-modal__item__title'>{pack.title}</div>
            </a>
          ))}
        </div>
      )}
    </Modal>
  )
}

RefillModal.propTypes = {
  showRefillModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  getPacks: PropTypes.func.isRequired,
  packs: PropTypes.array,
  packsLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object
}

export default RefillModal
