/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import PulseLoader from 'react-spinners/PulseLoader'

import './styles.scss'
import { SuccessIcon, ErrorIcon, LockIcon, TipIcon } from '../../assets/svg'
import { history } from '../../../services'

import {
  checkPrice,
  checkDescription,
  descriptionLengthValid,
  checkCategories
} from '../../../lib/validCreateVideo'

const ProfileVideoEdit = ({
  video,
  videoLoading,
  categories,
  categoriesReceived,
  goToQuill,
  updateVideo
}) => {
  const [locked, setLocked] = useState(false)
  const [category, setCategory] = useState(null)
  const [description, setDescription] = useState('')
  const [price, setPrice] = useState('')
  const [changed, setChanged] = useState('')
  const [validDescription, setValidDescription] = useState(false)
  const [validPrice, setValidPrice] = useState(false)
  const [errorPrice, setErrorPrice] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  const [errorCategories, setErrorCategories] = useState('')
  const [textCount, setTetxCount] = useState(0)
  const forbiddenСharacters = (description.match(/\n|\s/g) || []).length

  useEffect(() => {
    if (!videoLoading && !isEmpty(video)) {
      setDescription(video.attributes.description)
      setCategory(video.attributes.category.id)
      setLocked(video.attributes.locked)
      setPrice(video.attributes.price)
    }
  }, [videoLoading, video])

  useEffect(() => {
    checkPrice(locked, price, changed, setValidPrice, setErrorPrice)
  }, [price, changed, locked])

  useEffect(() => {
    setTetxCount(description.length - forbiddenСharacters)
    description &&
      checkDescription(
        description,
        changed,
        forbiddenСharacters,
        setValidDescription,
        setErrorDescription
      )
  }, [description, changed, forbiddenСharacters])

  const errors = {
    price: errorPrice,
    description: errorDescription,
    categories: errorCategories
  }

  const valid = () => {
    return validPrice && validDescription && category
  }

  const handleUpdate = async () => {
    checkDescription(
      description,
      changed,
      forbiddenСharacters,
      setValidDescription,
      setErrorDescription
    )
    checkCategories(category, setCategory, setErrorCategories)
    if (valid()) {
      const { id } = video
      const data = {
        profile_video: {
          description,
          category_id: category,
          locked,
          price: price || 0
        }
      }
      updateVideo(id, data)
    }
  }

  const renderListCategories = () => {
    return categories.map((item) => {
      return (
        <div key={item.id} className='modal-category-wrapper'>
          <div
            className={`category video-edit-category modal-category editVideoCtgBtn
              ${category === item.attributes.id ? 'category-active' : 'undefined'}`}
            onClick={() => setCategory(item.attributes.id)}
            role='button'
            tabIndex='0'
          >
            {item.attributes.title}
          </div>
        </div>
      )
    })
  }

  return (
    <div className='video-edit-container sign-in-container'>
      {isEmpty(video) || videoLoading || !categoriesReceived ? (
        <div className='video-edit-loader'>
          <PulseLoader color='#9a9a9a' size={30} />
        </div>
      ) : (
        <div className='video-edit-block'>
          <div className='description-container'>
            <div className='description-title-block'>
              <p className='description-title'>Description</p>
              {errors.description && <ErrorIcon className='error-icon' />}
            </div>
            {errors.description && (
              <p className='video-edit-page-error-text'>{errors.description}</p>
            )}
            <div className='little-hr' />
            <div className='description-block'>
              <div className='description-textarea'>
                <textarea
                  className='description'
                  defaultValue={description}
                  id='description'
                  maxLength={300 + forbiddenСharacters}
                  onBlur={() => descriptionLengthValid(
                    description,
                    forbiddenСharacters,
                    setValidDescription,
                    setErrorDescription
                  )}
                  onChange={(e) => {
                    setDescription(e.target.value)
                    setChanged('description')
                  }}
                  placeholder='Tell us about your video.'
                  type='text'
                  value={description}
                />
                <p className='description-counter' id='count'>
                  {textCount}/300
                </p>
              </div>
              <div
                className='video-edit-gif'
                style={{
                  backgroundImage: `url(${video.attributes.image || ''})`
                }}
              />
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <div className='description-title-block'>
              <p className='description-title'>Category</p>
              {errors.categories && <ErrorIcon className='error-icon' />}
            </div>
            <div className='little-hr' />
            {errors.categories && (
              <p className='video-edit-page-error-text'>{errors.categories}</p>
            )}
            <div className='modal-show-me'>
              <div className='modal-buttons-block'>
                {renderListCategories()}
              </div>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <div className='description-title-block'>
              <p className='description-title'>Lock this qwki?</p>
            </div>
            <div className='little-hr' />
            <div className='video-edit-page-lock'>
              <label className='switch' htmlFor='checkbox'>
                <input
                  checked={locked}
                  id='checkbox'
                  onChange={() => setLocked(!locked)}
                  type='checkbox'
                />
                <div className={`slider round ${locked && 'active'}`}>
                  <span className={!locked ? 'checked' : 'undefined'}>No</span>
                  <span className={locked ? 'checked' : 'undefined'}>Yes</span>
                </div>
              </label>
            </div>
            {locked && (
              <div className='video-edit-page-lock-form form-wrap'>
                <div className='form'>
                  <div className='inputs'>
                    <div className='input-wrap'>
                      {price.length ? (
                        <TipIcon className='icon focus-video-edit-svg' />
                      ) : (
                        <LockIcon className='icon' />
                      )}
                      {validPrice && <SuccessIcon className='valid-icon' />}
                      {errors.price && (
                        <>
                          <ErrorIcon className='error-icon' />
                          <p className='error-text'>{errors.price}</p>
                        </>
                      )}
                      <input
                        autoComplete='off'
                        className='input'
                        maxLength='25'
                        onChange={(e) => {
                          setPrice(e.target.value)
                          setChanged('price')
                        }}
                        placeholder='Token amount'
                        type='tel'
                        value={price || 0}
                      />
                      <div className='inputs-hr' />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className='buttons'>
              <a
                className={`${valid() ? 'done' : 'disable'} button`}
                onClick={() => handleUpdate()}
                role='button'
              >
                <p className='p-button done-color'>Edit</p>
              </a>
              <a
                className='cancel button video-editCancelBtn'
                onClick={() => history.push(`/${video.attributes.user.username}`)}
                role='button'
              >
                <p className='p-button cancel-color'>Cancel</p>
              </a>
            </div>
            <div className='under-buttons-container'>
              <p className='by-creating-text'>
                By posting, you verify that all parties depicted in the content you&apos;re uploading consent to
                it being posted on Qwki and are aware that you are doing so. You are representing that your files
                do not violate Qwki’s
                <button
                  className='link'
                  onClick={() => goToQuill('Terms Of Use', 'terms_of_use')}
                  type='button'
                >&nbsp;<b>Terms of Use</b>&nbsp;
                </button>
                and that you are willing to provide any 2257 information upon request.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ProfileVideoEdit.propTypes = {
  video: PropTypes.object.isRequired,
  videoLoading: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  categoriesReceived: PropTypes.bool.isRequired,
  updateVideo: PropTypes.func.isRequired,
  goToQuill: PropTypes.func.isRequired
}

export default ProfileVideoEdit
