import * as React from 'react'

const ShareIcon = (props) => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10 10.7199C9.49333 10.7199 9.04 10.9199 8.69333 11.2333L3.94 8.46659C3.97333 8.31325 4 8.15992 4 7.99992C4 7.83992 3.97333 7.68659 3.94 7.53325L8.64 4.79325C9 5.12659 9.47333 5.33325 10 5.33325C11.1067 5.33325 12 4.43992 12 3.33325C12 2.22659 11.1067 1.33325 10 1.33325C8.89333 1.33325 8 2.22659 8 3.33325C8 3.49325 8.02667 3.64659 8.06 3.79992L3.36 6.53992C3 6.20659 2.52667 5.99992 2 5.99992C0.893333 5.99992 0 6.89325 0 7.99992C0 9.10659 0.893333 9.99992 2 9.99992C2.52667 9.99992 3 9.79325 3.36 9.45992L8.10667 12.2333C8.07333 12.3733 8.05333 12.5199 8.05333 12.6666C8.05333 13.7399 8.92667 14.6133 10 14.6133C11.0733 14.6133 11.9467 13.7399 11.9467 12.6666C11.9467 11.5933 11.0733 10.7199 10 10.7199Z'
        fill='white'
      />
    </svg>
  )
}

export default ShareIcon
