import * as actions from './actions'

const initialResourceState = {
  animation: false,
  loading: false,
  error: null
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.PURCHASE_CREATE_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.PURCHASE_CREATE_SUCCESS:
    return {
      ...state,
      animation: true,
      error: null
    }

  case actions.PURCHASE_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      error: payload.response.data.message
    }

  case actions.RESET_PURCHASE_STATE:
    return {
      ...state,
      loading: false,
      error: null
    }

  default:
    return state
  }
}
