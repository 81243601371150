import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MoonLoader from 'react-spinners/MoonLoader'
import ReactGA from 'react-ga'

import NoAvatar from '../../../../no-avatar.png'
import { PlusIcon, SuccessIcon } from '../../assets/svg'
import { history } from '../../../services'
import './styles.scss'

const ProfileEdit = (props) => {
  const {
    profile,
    profileLoading,
    updateProfile,
    usernamePresent,
    getValidation
  } = props
  const [file, setFile] = useState()
  const [avatarPreview, setAvatarPreview] = useState()
  const [username, setUsername] = useState('')
  const [name, setName] = useState('')
  const [bio, setBio] = useState('')
  const [location, setLocation] = useState('')
  const [validUsername, setValidUsername] = useState(true)
  const [errorUsername, setErrorUsername] = useState('')
  const [validName, setValidName] = useState(true)
  const [errorName, setErrorName] = useState('')
  const [validBio, setValidBio] = useState(true)
  const [errorBio, setErrorBio] = useState('')
  const [validLocation, setValidLocation] = useState(true)
  const [errorLocation, setErrorLocation] = useState('')
  const [changed, setChanged] = useState('')
  const [focusedInput, setFocusedInput] = useState('')
  const regex = /^[0-9a-zA-Z]*$/

  useEffect(() => {
    if (profile.username) {
      ReactGA.event({
        category: 'Profile Edit',
        action: 'Profile edit opened'
      })
    }
  }, [profile])

  useEffect(() => {
    if (username.length >= 4 && username.length <= 25 && regex.test(username)) {
      if (changed === 'username') {
        if (!regex.test(username)) {
          setValidUsername(false)
          setErrorUsername('Not a valid username.')
        } else if (!usernamePresent) {
          setValidUsername(true)
          setErrorUsername('')
        } else {
          setValidUsername(false)
          setErrorUsername('Username already taken.')
        }
      }
    } else if (!username && changed === 'username') {
      setValidUsername(false)
      setErrorUsername('Is required.')
    } else if (username.length > 0 && username.length < 4) {
      setValidUsername(false)
      setErrorUsername('Name must be 4-25 characters.')
    } else if (!regex.test(username) && changed === 'username') {
      setValidUsername(false)
      setErrorUsername('No spaces or symbols.')
    } else {
      setValidUsername(true)
      setErrorUsername('')
    }
  }, [username, changed, getValidation, focusedInput, usernamePresent])

  useEffect(() => {
    if (focusedInput === 'name' && name) {
      if (name.length > 60 || name.length < 4) {
        setValidName(false)
        setErrorName('Name must be 4-60 characters.')
      } else {
        setValidName(true)
        setErrorName('')
      }
    } else {
      setValidName(true)
      setErrorName('')
    }
  }, [name, changed, focusedInput])

  useEffect(() => {
    if (focusedInput === 'bio' && bio) {
      if (bio.length > 200) {
        setValidBio(false)
        setErrorBio('Bio must be 1-200 characters.')
      } else {
        setValidBio(true)
        setErrorBio('')
      }
    }
  }, [bio, changed, focusedInput])

  useEffect(() => {
    if (focusedInput === 'location' && location) {
      if (location.length > 40) {
        setValidLocation(false)
        setErrorLocation('Location must be 1-40 characters.')
      } else {
        setValidLocation(true)
        setErrorLocation('')
      }
    } else {
      setValidLocation(true)
      setErrorLocation('')
    }
  }, [location, changed, focusedInput])

  const onAvatarChange = (event) => {
    setFile(event.target.files[0])
    setAvatarPreview(URL.createObjectURL(event.target.files[0]))
  }

  const handleSubmit = () => {
    if (validUsername && validName && validBio && validLocation) {
      const formData = new FormData()
      if (file) {
        formData.append('avatar', file)
      }
      formData.append('nickname', username)
      formData.append('name', name)
      formData.append('bio', bio)
      formData.append('location', location)
      updateProfile(profile.username, formData)
      ReactGA.event({
        category: 'Profile Edit',
        action: 'Profile edit submitted'
      })
    }
  }

  useEffect(() => {
    if (!profileLoading) {
      setAvatarPreview(profile.avatar.url)
      setUsername(profile.username)
      setName(profile.name)
      setBio(profile.bio)
      setLocation(profile.location)
    }
  }, [profileLoading])

  const errors = {
    username: errorUsername,
    name: errorName,
    bio: errorBio,
    location: errorLocation
  }

  const avatar = profile.avatar && avatarPreview

  return profileLoading ? (
    <div className='profile-container profile-container--loader'>
      <MoonLoader color='#9a9a9a' size={90} />
    </div>
  ) : (
    <div className='profile-edit-container'>
      <div className='profile-edit__title'>Edit Profile</div>
      <div className='profile-edit__avatar'>
        <img alt='' src={avatar || NoAvatar} />
        <label className='profile-edit__avatar__edit'>
          <PlusIcon />
          <input
            accept='image/x-png,image/jpg,image/jpeg'
            onChange={(e) => onAvatarChange(e)}
            style={{ display: 'none' }}
            type='file'
          />
        </label>
      </div>
      <form className='profile-edit__form'>
        <div className='inputs'>
          <div className='input-wrap'>
            {validUsername && <SuccessIcon className='valid-icon' />}
            {errors.username && (
              <>
                <p className='error-text'>{errors.username}</p>
              </>
            )}
            <input
              autoComplete='off'
              className='input'
              onBlur={() => {
                setFocusedInput('')
              }}
              onChange={(e) => {
                getValidation('username', e.target.value)
                setUsername(e.target.value)
                setChanged('username')
              }}
              onFocus={() => {
                setFocusedInput('username')
              }}
              placeholder='Username'
              type='text'
              value={username}
            />
            <div className='inputs-hr' />
          </div>
          <div className='input-wrap'>
            {errors.name && (
              <>
                <p className='error-text'>{errors.name}</p>
              </>
            )}
            <input
              autoComplete='off'
              className='input'
              onBlur={() => {
                setFocusedInput('')
              }}
              onChange={(e) => {
                setName(e.target.value)
                setChanged('name')
              }}
              onFocus={() => {
                setFocusedInput('name')
              }}
              placeholder='Display Name'
              type='text'
              value={name}
            />
            <div className='inputs-hr' />
          </div>
          <div className='input-wrap'>
            {errors.bio && (
              <>
                <p className='error-text'>{errors.bio}</p>
              </>
            )}
            <input
              autoComplete='off'
              className='input'
              onBlur={() => {
                setFocusedInput('')
              }}
              onChange={(e) => {
                setBio(e.target.value)
                setChanged('bio')
              }}
              onFocus={() => {
                setFocusedInput('bio')
              }}
              placeholder='Bio'
              type='text'
              value={bio}
            />
            <div className='inputs-hr' />
          </div>
          <div className='input-wrap'>
            {errors.location && (
              <>
                <p className='error-text'>{errors.location}</p>
              </>
            )}
            <input
              autoComplete='off'
              className='input'
              onBlur={() => {
                setFocusedInput('')
              }}
              onChange={(e) => {
                setLocation(e.target.value)
                setChanged('location')
              }}
              onFocus={() => {
                setFocusedInput('location')
              }}
              placeholder='Location'
              type='text'
              value={location}
            />
            <div className='inputs-hr' />
          </div>
        </div>
      </form>
      <div className='profile-edit__form__actions'>
        <button
          disabled={!validBio || !validUsername || !validLocation}
          onClick={() => handleSubmit()}
          type='button'
        >
          Save
        </button>
        <button
          className='cancel'
          onClick={() => history.push(`/${profile.username}`)}
          type='button'
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

ProfileEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  profileLoading: PropTypes.bool.isRequired,
  updateProfile: PropTypes.func.isRequired,
  usernamePresent: PropTypes.bool.isRequired,
  getValidation: PropTypes.func.isRequired
}

export default ProfileEdit
