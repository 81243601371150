import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CheckboxIcon, CheckboxCheckedIcon } from '../../assets/svg'

import './styles.scss'

const Report = (props) => {
  const [choosenReason, setChoosenReason] = useState('')
  const [message, setMessage] = useState('')

  const reasons = [
    { id: 1, name: 'Underage' },
    { id: 2, name: 'Inappropriate content' },
    { id: 3, name: 'Video does not play' },
    { id: 4, name: 'Spam or misleading' },
    { id: 5, name: 'Content removal request' },
    { id: 6, name: 'I don’t like this' }
  ]

  const renderTitles = () => {
    return reasons.map(reason => (
      <React.Fragment key={reason.id}>
        <div
          className='reason-field checkbox'
          onClick={
            choosenReason === reason.name
              ? () => setChoosenReason('')
              : () => setChoosenReason(reason.name)
          }
          role='button'
          tabIndex='0'
        >
          {
            choosenReason === reason.name
              ? <CheckboxCheckedIcon className='checked' />
              : <CheckboxIcon className='check-icon' />
          }
          <p
            className={choosenReason === reason.name ? 'reason check-text' : 'reason'}
          >
            {reason.name}
          </p>
        </div>
        <hr className='hr' />
      </React.Fragment>
    ))
  }

  return (
    <div className='report-container'>
      <div className='report-wrap'>
        <div className='report-title'>
          <p className='title'>Report This Video</p>
          <hr className='pink-hr' />
        </div>
        <div className='report-fields'>
          {renderTitles()}
          <div className='input-field'>
            <textarea
              className='input'
              maxLength='300'
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Enter reason here (optional)'
              type='text'
            />
          </div>
        </div>
        <div className='report-buttons'>
          <button
            className='submit'
            disabled={(choosenReason.length <= 0 && message.length <= 0)}
            onClick={
              () => props.createReport({ id: props.videoId, title: choosenReason, message: message.slice(0, 300) })
            }
            type='button'
          >
            SUBMIT
          </button>
          <button
            className='cancel'
            onClick={() => props.openedReport(false)}
            type='button'
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  )
}

Report.propTypes = ({
  createReport: PropTypes.func.isRequired,
  openedReport: PropTypes.func.isRequired,
  videoId: PropTypes.string.isRequired
})

export default Report
