import React, { Component } from 'react'
import PropTypes /* , { instanceOf } */ from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash.isempty'
import { withCookies /* , Cookies */ } from 'react-cookie'
import axios from 'axios'

import PublicRoutes from '../routes'
import { infoIndexRequest, sessionShowRequest } from '../store/actions'
// import { WelcomeModal } from '../components/molecules'

class Session extends Component {
  // constructor(props) {
  //   super(props)

  //   this.state = {
  //     showWelcomeModal: props.cookies.get('showWelcome') || true
  //   }
  // }

  async componentDidMount() {
    if (localStorage.getItem('loc') == null) {
      localStorage.setItem(
        'loc',
        await axios.get('https://api.ipbase.com/v2/info', {
          headers: {
            'apikey': process.env.GEO_API_KEY
          }
        }).then(res => { return res.data.data.location.country.alpha2 })
      )
    }
    this.props.getUser()
    this.props.getInfo()
  }

  // closeModal = () => {
  //   this.props.cookies.set('showWelcome', false, { path: '/', maxAge: 9999999999 })
  //   this.setState({
  //     showWelcomeModal: false
  //   })
  // }

  render() {
    return (
      <div>
        {/* { (this.state.showWelcomeModal === true && window.location.pathname !== '/quill') &&
          <WelcomeModal closeModal={this.closeModal} info={this.props.info} /> } */}
        { (!isEmpty(this.props.user) || !isEmpty(this.props.guest)) && <PublicRoutes /> }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.session.currentUser,
  info: state.info.details,
  guest: state.guest.currentGuest
})

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(sessionShowRequest()),
  getInfo: () => dispatch(infoIndexRequest())
})

Session.propTypes = ({
  getUser: PropTypes.func.isRequired,
  getInfo: PropTypes.func.isRequired,
  // info: PropTypes.object.isRequired,
  guest: PropTypes.object,
  // cookies: instanceOf(Cookies).isRequired,
  user: PropTypes.object
})

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Session))
