import * as actions from './actions'

const initialResourceState = {
  details: {},
  videos: [],
  video: {},
  profileLoading: true,
  videosLoading: true,
  videoLoading: true,
  hasMore: true,
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.GET_PROFILE_REQUEST:
    return {
      ...state,
      profileLoading: true,
      errors: {}
    }

  case actions.GET_PROFILE_SUCCESS:
    return {
      ...state,
      profileLoading: false,
      details: payload.data.attributes
    }

  case actions.GET_PROFILE_FAILURE:
    return {
      ...state,
      errors: payload.response.data
    }

  case actions.GET_PROFILE_VIDEOS_REQUEST:
    return {
      ...state,
      videosLoading: true,
      errors: {}
    }

  case actions.GET_PROFILE_VIDEOS_SUCCESS:
    return {
      ...state,
      hasMore: payload.has_more,
      videos: payload.response.data,
      videosLoading: false
    }

  case actions.GET_PROFILE_VIDEOS_FAILURE:
    return {
      ...state,
      videosLoading: false,
      hasMore: false,
      errors: payload.response.data
    }

  case actions.GET_MORE_PROFILE_VIDEOS_SUCCESS:
    return {
      ...state,
      hasMore: payload.has_more,
      videosLoading: false,
      videos: [...state.videos, ...payload.response.data]
    }

  case actions.GET_PROFILE_VIDEO_REQUEST:
    return {
      ...state,
      videoLoading: true
    }

  case actions.GET_PROFILE_VIDEO_SUCCESS:
    return {
      ...state,
      video: payload.response.data,
      videoLoading: false
    }

  case actions.GET_PROFILE_VIDEO_FAILURE:
    return {
      ...state,
      videoLoading: false,
      errors: payload.response.data
    }

  case actions.UPDATE_PROFILE_VIDEO_REQUEST:
    return {
      ...state,
      videosLoading: true,
      errors: {}
    }

  case actions.UPDATE_PROFILE_VIDEO_SUCCESS:
    return {
      ...state,
      videosLoading: false
    }

  case actions.UPDATE_PROFILE_VIDEO_FAILURE:
    return {
      ...state,
      videosLoading: false,
      errors: payload
    }

  case actions.REMOVE_PROFILE_VIDEO_REQUEST:
    return {
      ...state,
      errors: {}
    }

  case actions.REMOVE_PROFILE_VIDEO_SUCCESS:
    return {
      ...state,
      videos: state.videos.filter(video => video.id !== payload.id.toString())
    }

  case actions.REMOVE_PROFILE_VIDEO_FAILURE:
    return {
      ...state
    }

  case actions.RESET_PROFILE_VIDEOS_STATE:
    return {
      ...state,
      videosLoading: false,
      videos: []
    }

  case actions.UPDATE_PROFILE_REQUEST:
    return {
      ...state,
      profileLoading: true
    }

  case actions.UPDATE_PROFILE_SUCCESS:
    return {
      ...state
    }

  case actions.UPDATE_PROFILE_FAILURE:
    return {
      ...state,
      profileLoading: false,
      errors: payload
    }

  default:
    return {
      ...state
    }
  }
}
