import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MaskInput from 'react-maskinput'
import PaymentIcon from 'react-payment-icons'
import countryList from 'react-select-country-list'
import CardValidator from 'card-validator'
// import * as EmailValidator from 'email-validator'

import { history } from '../../../services'
import { CheckIcon, NoCheckIcon } from '../../assets/svg'
import './styles.scss'

const PurchaseForm = (props) => {
	const { pack, packLoading, addCard, cardsError, cardsLoading } = props

	const [cardNumber, setCardNumber] = useState('')
	const [cardType, setCardType] = useState('')
	const [codeSize, setCodeSize] = useState()
	const [firstName, setFirstName] = useState('')
	const [validFirstName, setValidFirstName] = useState(false)
	const [errorFirstName, setErrorFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [validLastName, setValidLastName] = useState(false)
	const [errorLastName, setErrorLastName] = useState('')
	const [country, setCountry] = useState('')
	const [validCountry, setValidCountry] = useState(true)
	const [errorCountry, setErrorCountry] = useState('')
	const [city, setCity] = useState('')
	const [validCity, setValidCity] = useState(true)
	const [errorCity, setErrorCity] = useState('')
	const [state, setState] = useState('')
	const [validState, setValidState] = useState(true)
	const [errorState, setErrorState] = useState('')
	const [address, setAddress] = useState('')
	const [validAddress, setValidAddress] = useState(false)
	const [errorAddress, setErrorAddress] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [validPostalCode, setValidPostalCode] = useState(true)
	const [errorPostalCode, setErrorPostalCode] = useState('')
	const [validCardNumber, setValidCardNumber] = useState(true)
	const [errorCardNumber, setErrorCardNumber] = useState('')
	const [expirationDate, setExpirationDate] = useState('')
	const [validExpirationDate, setValidExpirationDate] = useState(true)
	const [errorExpirationDate, setErrorExpirationDate] = useState('')
	const [cvv, setCvv] = useState('')
	const [validCvv, setValidCvv] = useState(true)
	const [errorCvv, setErrorCvv] = useState('')
	const [agree, setAgree] = useState(false)
	const [changed, setChanged] = useState('')
	const [focusedInput, setFocusedInput] = useState('')
	const [cardMask, setCardMask] = useState('0000 0000 0000 0000')
	const [dateMask] = useState('00/00')

	useEffect(() => {
		if (!packLoading && !pack.title) {
			history.push('/')
		}
	}, [packLoading, pack.title])

	useEffect(() => {
		if (cardType) {
			if (cardType === 'american-express') {
				setCardType('amex')
				setCodeSize(4)
			}
		}
	}, [cardType])

	useEffect(() => {
		if (focusedInput === 'firstName') {
			if (!firstName) {
				setValidFirstName(false)
				setErrorFirstName('Is required')
			} else if (firstName.length < 2 || firstName.length > 20) {
				setValidFirstName(false)
				setErrorFirstName('First name must be 2-20 characters.')
			} else {
				setValidFirstName(true)
				setErrorFirstName('')
			}
		}
	}, [firstName, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'lastName') {
			if (!lastName) {
				setValidLastName(false)
				setErrorLastName('Is required')
			} else if (lastName.length < 2 || lastName.length > 20) {
				setValidLastName(false)
				setErrorLastName('Last name must be 2-20 characters.')
			} else {
				setValidLastName(true)
				setErrorLastName('')
			}
		}
	}, [lastName, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'country') {
			const byName = countryList().getValue(country)
			const byCode = countryList().getLabel(country)
			const valid = byName || byCode
			if (!country) {
				setValidCountry(false)
				setErrorCountry('Is required')
			} else if (!valid) {
				setValidCountry(false)
				setErrorCountry('Country is invalid')
			} else {
				setValidCountry(true)
				setErrorCountry('')
			}
		}
	}, [country, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'city') {
			if (!city) {
				setValidCity(false)
				setErrorCity('Is required')
			} else if (city.length < 2 || state.length > 30) {
				setValidCity(false)
				setErrorCity('City must be 2-30 characters.')
			} else {
				setValidCity(true)
				setErrorCity('')
			}
		}
	}, [city, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'state') {
			if (!state) {
				setValidState(false)
				setErrorState('Is required')
			} else if (state.length < 2 || state.length > 30) {
				setValidState(false)
				setErrorState('State must be 2-30 characters.')
			} else {
				setValidState(true)
				setErrorState('')
			}
		}
	}, [state, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'address') {
			if (!address) {
				setValidAddress(false)
				setErrorAddress('Is required')
			} else if (address.length < 2 || address.length > 30) {
				setValidAddress(false)
				setErrorAddress('Address must be 2-30 characters.')
			} else {
				setValidAddress(true)
				setErrorAddress('')
			}
		}
	}, [address, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'postalCode') {
			const valid = CardValidator.postalCode(postalCode)
			if (!postalCode) {
				setValidPostalCode(false)
				setErrorPostalCode('Is required.')
			} else if (changed === 'postalCode' && valid && !valid.isValid) {
				setValidPostalCode(false)
				setErrorPostalCode('Postal code is invalid')
			} else {
				setValidPostalCode(true)
				setErrorPostalCode('')
			}
		}
	}, [postalCode, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'cardNumber') {
			const valid = CardValidator.number(cardNumber)
			valid.card && setCardType(valid.card.type) && setCodeSize(valid.card.code.size)
			if (cardNumber.indexOf('34') === 0 || cardNumber.indexOf('37') === 0) {
				setCardMask('0000 000000 00000')
			} else {
				setCardMask('0000 0000 0000 0000')
			}
			if (!cardNumber) {
				setValidCardNumber(false)
				setErrorCardNumber('Is required.')
				setCardType('')
			} else if (changed === 'cardNumber' && valid && !valid.isValid) {
				setValidCardNumber(false)
				setErrorCardNumber('Card number is invalid')
			} else {
				setValidCardNumber(true)
				setErrorCardNumber('')
			}
		}
	}, [cardNumber, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'expirationDate') {
			const valid = CardValidator.expirationDate(expirationDate)
			if (!expirationDate) {
				setValidExpirationDate(false)
				setErrorExpirationDate('Is required.')
			} else if (changed === 'expirationDate' && valid && !valid.isValid) {
				setValidExpirationDate(false)
				setErrorExpirationDate('Expiration date is invalid')
			} else {
				setValidExpirationDate(true)
				setErrorExpirationDate('')
			}
		}
	}, [expirationDate, changed, focusedInput])

	useEffect(() => {
		if (focusedInput === 'cvv') {
			const valid = cardType === 'amex' ? CardValidator.cvv(cvv, 4) : CardValidator.cvv(cvv)
			if (!cvv) {
				setValidCvv(false)
				setErrorCvv('Is required.')
			} else if (changed === 'cvv' && valid && !valid.isValid) {
				setValidCvv(false)
				setErrorCvv('Code is invalid')
			} else {
				setValidCvv(true)
				setErrorCvv('')
			}
		}
	}, [cvv, cardType, changed, focusedInput])

	const errors = {
		firstName: errorFirstName,
		lastName: errorLastName,
		country: errorCountry,
		city: errorCity,
		state: errorState,
		address: errorAddress,
		postalCode: errorPostalCode,
		cardNumber: errorCardNumber,
		expirationDate: errorExpirationDate,
		cvv: errorCvv,
	}

	const everythingValid =
		validFirstName &&
		validLastName &&
		validCountry &&
		validCity &&
		validState &&
		validAddress &&
		validPostalCode &&
		validCardNumber &&
		validExpirationDate &&
		validCvv &&
		agree

	const handleSubmit = () => {
		if (everythingValid) {
			const newCardNumber = cardNumber.replace(/\s+/g, '')
			const newExpDate = expirationDate.replace(/\\/g, '')
			const formData = new FormData()
			formData.append('pack_id', pack.id)
			formData.append('first_name', firstName)
			formData.append('last_name', lastName)
			formData.append('country', country)
			formData.append('city', city)
			formData.append('state', state)
			formData.append('address', address)
			formData.append('zip_code', postalCode)
			formData.append('card_number', newCardNumber)
			formData.append('exp_date', newExpDate)
			formData.append('cvv', cvv)
			formData.append('brand', cardType)
			addCard(formData)
		}
	}

	const handleBack = () => {
		const videoId = localStorage.getItem('currentVideoId')
		if (videoId) {
			history.push(`/v/${videoId}`)
		} else {
			history.push('/v')
		}
	}

	return (
		<div>
			<form className='purchase__form'>
				<div className='inputs'>
					<div className='purchase__form__inline'>
						<div className='input-wrap'>
							{errors.firstName && (
								<>
									<p className='error-text'>{errors.firstName}</p>
								</>
							)}
							<input
								className='input'
								maxLength='60'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setFirstName(e.target.value)
									setChanged('firstName')
								}}
								onFocus={() => {
									setFocusedInput('firstName')
								}}
								placeholder='First Name'
								type='text'
								value={firstName}
							/>
						</div>
						<div className='inputs-hr vertical' />
						<div className='input-wrap'>
							{errors.lastName && (
								<>
									<p className='error-text'>{errors.lastName}</p>
								</>
							)}
							<input
								className='input'
								maxLength='60'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setLastName(e.target.value)
									setChanged('lastName')
								}}
								onFocus={() => {
									setFocusedInput('lastName')
								}}
								placeholder='Last Name'
								type='text'
								value={lastName}
							/>
						</div>
					</div>
					<div className='purchase__form__inline'>
						<div className='input-wrap'>
							{errors.country && (
								<>
									<p className='error-text'>{errors.country}</p>
								</>
							)}
							<input
								className='input'
								maxLength='60'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setCountry(e.target.value)
									setChanged('country')
								}}
								onFocus={() => {
									setFocusedInput('country')
								}}
								placeholder='Country'
								type='text'
								value={country}
							/>
						</div>
						<div className='inputs-hr vertical' />
						<div className='input-wrap'>
							{errors.state && (
								<>
									<p className='error-text'>{errors.state}</p>
								</>
							)}
							<input
								className='input'
								maxLength='60'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setState(e.target.value)
									setChanged('state')
								}}
								onFocus={() => {
									setFocusedInput('state')
								}}
								placeholder='State'
								type='text'
								value={state}
							/>
						</div>
					</div>
					<div className='purchase__form__inline'>
						<div className='input-wrap'>
							{errors.city && (
								<>
									<p className='error-text'>{errors.city}</p>
								</>
							)}
							<input
								className='input'
								maxLength='60'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setCity(e.target.value)
									setChanged('city')
								}}
								onFocus={() => {
									setFocusedInput('city')
								}}
								placeholder='City'
								type='text'
								value={city}
							/>
						</div>
						<div className='inputs-hr vertical' />
						<div className='input-wrap'>
							{errors.address && (
								<>
									<p className='error-text'>{errors.address}</p>
								</>
							)}
							<input
								className='input'
								maxLength='60'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setAddress(e.target.value)
									setChanged('address')
								}}
								onFocus={() => {
									setFocusedInput('address')
								}}
								placeholder='Address'
								type='text'
								value={address}
							/>
						</div>
					</div>
					<div className='input-wrap'>
						{errors.postalCode && (
							<>
								<p className='error-text'>{errors.postalCode}</p>
							</>
						)}
						<input
							className='input'
							onBlur={() => {
								setFocusedInput('')
							}}
							onChange={(e) => {
								setPostalCode(e.target.value)
								setChanged('postalCode')
							}}
							onFocus={() => {
								setFocusedInput('postalCode')
							}}
							placeholder='Postal Code'
							type='number'
							value={postalCode}
						/>
					</div>
					<div className='input-wrap'>
						{errors.cardNumber && (
							<>
								<p className='error-text'>{errors.cardNumber}</p>
							</>
						)}
						{cardType && (
							<div className='purchase-icon'>
								<PaymentIcon id={cardType} />
							</div>
						)}
						<MaskInput
							className='input'
							mask={cardMask}
							onBlur={() => {
								setFocusedInput('')
							}}
							onChange={(e) => {
								setCardNumber(e.target.value)
								setChanged('cardNumber')
							}}
							onFocus={() => {
								setFocusedInput('cardNumber')
							}}
							placeholder='Card Number'
							size='20'
							type='tel'
							value={cardNumber}
						/>
					</div>
					<div className='purchase__form__inline'>
						<div className='input-wrap without-margin'>
							{errors.expirationDate && (
								<>
									<p className='error-text'>{errors.expirationDate}</p>
								</>
							)}
							<MaskInput
								className='input'
								mask={dateMask}
								maxLength='5'
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setExpirationDate(e.target.value)
									setChanged('expirationDate')
								}}
								onFocus={() => {
									setFocusedInput('expirationDate')
								}}
								placeholder='Expiration Date'
								type='tel'
								value={expirationDate}
							/>
						</div>
						<div className='inputs-hr vertical' />
						<div className='input-wrap without-margin'>
							{errors.cvv && (
								<>
									<p className='error-text'>{errors.cvv}</p>
								</>
							)}
							<input
								autoComplete='off'
								className='input'
								maxLength={codeSize || 3}
								onBlur={() => {
									setFocusedInput('')
								}}
								onChange={(e) => {
									setCvv(e.target.value)
									setChanged('cvv')
								}}
								onFocus={() => {
									setFocusedInput('cvv')
								}}
								placeholder='CVV/CVC'
								type='tel'
								value={cvv}
							/>
						</div>
					</div>
				</div>
				{cardsError.message && <div className='purchase__error'>{cardsError.message}</div>}
			</form>
			<div className='purchase__content__wrapper'>
				<div className='purchase__check' onClick={() => setAgree(!agree)} role='button' tabIndex={0}>
					{agree ? <CheckIcon /> : <NoCheckIcon />}
					<div className='purchase__check__content'>
						<p className='purchase__check__text'>
							By submitting this form, you agree to our Terms of Use, certify that you are 18 years of age or older and
							understand the terms of billing.
						</p>
					</div>
				</div>
				<div className='purchase__actions'>
					<button disabled={!everythingValid || cardsLoading} onClick={() => handleSubmit()} type='button'>
						Buy
					</button>
					<button className='cancel' onClick={() => handleBack()} type='button'>
						Cancel
					</button>
				</div>
				<div className='purchase__info'>
					<p>Qwki will appear on your billing statement.</p>
					<p>
						You agree that Qwki will store your payment information so that you may make future purchases without
						re-entering the full payment information.
					</p>
					<p>Support@qwki.me • Qwki • San Francisco, CA</p>
				</div>
			</div>
		</div>
	)
}

PurchaseForm.propTypes = {
	pack: PropTypes.object.isRequired,
	packLoading: PropTypes.bool.isRequired,
	addCard: PropTypes.func.isRequired,
	cardsError: PropTypes.object.isRequired,
	cardsLoading: PropTypes.bool.isRequired,
}

export default PurchaseForm
