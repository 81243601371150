/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { osName } from 'react-device-detect'
import axios from 'axios'

import {
  userVideosRequest,
  videoIndexRequest,
  resetVideoState,
  setViewRequest,
  setLikeRequest,
  commentCreateRequest,
  commentLikeRequest,
  commentReplyLikeRequest,
  commentsIndexRequest,
  commentsClearRequest,
  tipCreateRequest,
  tipResetState,
  purchaseCreateRequest,
  resetPurchaseState,
  adsIndexRequest,
  adsSendRequest,
  packsIndexRequest,
  packGetRequest,
  resetAdState,
  categoriesIndexRequest,
  chosenCategoriesIndexRequest,
  categoriesSendRequest,
  frequencyIndexRequest,
  sessionDeleteRequest,
  reportCreateRequest,
  platformUpdateRequest,
  sessionShowRequest
} from '../store/actions'
import { UserVideos } from '../components/pages'

class UserVideosContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      haveFirstVideo: false,
      data: {
        videos: props.videos,
        videosReceived: props.videosReceived,
        comments: props.comments,
        chosenReceived: props.chosenReceived,
        categories: props.categories,
        usersCategories: props.usersCategories,
        firstVideo: props.firstVideo,
        firstVideoReceived: props.firstVideoReceived
      }
    }
  }

  componentDidMount() {
    localStorage.removeItem('fromUploadPage')
    this.props.getVideos(this.props.match.params.username, this.props.match.params.type, this.props.match.params.id)
    this.props.getFrequency()
    if (!this.props.chosenReceived) {
      this.props.getCategories()
    }
    this.props.getChosenCategories()

    let loc
    if (localStorage.getItem('loc')) {
      loc = localStorage.getItem('loc')
      this.props.sendPlatform({ platform: osName, country: loc })
    } else {
      loc = axios('https://api.ipbase.com/v2/info').then(res => { return res.data.data.location.country.alpha2 })
      localStorage.setItem('loc', loc)
      this.props.sendPlatform({ platform: osName, country: loc })
    }
  }

  static getDerivedStateFromProps(props) {
    const newData = {}
    if (props.videosReceived) {
      newData.videosReceived = props.videosReceived
      newData.videos = props.videos
    }

    if (props.chosenReceived) {
      newData.chosenReceived = props.chosenReceived
      newData.categories = props.categories
      newData.usersCategories = props.usersCategories
    }

    if (props.firstVideoReceived && props.videosReceived) {
      if (props.firstVideo !== {}) {
        const arrayVideo = newData.videos.filter(
          (item) => item.id !== props.firstVideo.id
        )
        arrayVideo.unshift(props.firstVideo)
        newData.videos = arrayVideo
      }
      newData.firstVideoReceived = props.firstVideoReceived
    }

    return { data: newData }
  }

  createReport = (data) => {
    const reportData = {
      title: data.title,
      message: data.message
    }
    this.props.createReport(data.id, reportData)
  }

  createComment = (data) => {
    const commentData = {
      message: data.message,
      reply: data.reply
    }
    this.props.createComment(data.videoId, commentData)
  }

  render() {
    return this.props.videos.length &&
      this.props.chosenReceived &&
      (this.state.haveFirstVideo
        ? this.props.firstVideoReceived
        : true) ? (
          <UserVideos
            videoId={this.props.match.params.id}
            username={this.props.match.params.username}
            ads={(this.props.ads && this.props.ads.attributes) || {}}
            categories={this.state.data.categories}
            clearComments={this.props.clearComments}
            commentLoading={this.props.commentLoading}
            commentReplySetLike={this.props.commentReplySetLike}
            comments={this.props.comments}
            commentSetLike={this.props.commentSetLike}
            commentsLoading={this.props.commentsLoading}
            createComment={this.createComment}
            createPurchase={this.props.createPurchase}
            createReport={this.createReport}
            createTip={this.props.createTip}
            currentGuest={this.props.currentGuest}
            currentUser={this.props.currentUser}
            frequency={this.props.frequency}
            getAds={this.props.getAds}
            getComments={this.props.getComments}
            getPack={this.props.getPack}
            getPacks={this.props.getPacks}
            getUser={this.props.getUser}
            getVideos={this.props.getVideos}
            hasMoreComments={this.props.hasMoreComments}
            onDeleteSession={this.props.deleteSession}
            pack={this.props.pack}
            packs={this.props.packs}
            packsLoading={this.props.packsLoading}
            purchaseError={this.props.purchaseError}
            purchaseLoading={this.props.purchaseLoading}
            purchaseAnimation={this.props.purchaseAnimation}
            resetAdState={this.props.resetAdState}
            resetPurchaseState={this.props.resetPurchaseState}
            resetTipState={this.props.resetTipState}
            resetVideoState={this.props.resetVideoState}
            scripts={this.props.scripts}
            sendAds={this.props.sendAds}
            sendCategories={this.props.sendCategories}
            setLikes={this.props.setLikes}
            setView={this.props.setView}
            showAddToHomeModal={
            (this.props.location.state &&
            this.props.location.state.showAddToHomeModal) ||
          false
          }
            showHelloModal={
            (this.props.location.state &&
            this.props.location.state.showHelloModal) ||
          false
          }
            showUploadModal={
            (this.props.location.state &&
            this.props.location.state.showUploadModal) ||
          false
          }
            tipCreated={this.props.tipCreated}
            tipLoading={this.props.tipLoading}
            usersCategories={this.state.data.usersCategories}
            videos={this.state.data.videos}
          />
      ) : null
  }
}

const mapStateToProps = (state) => ({
  videos: state.videos.details,
  comments: state.comments.details,
  hasMoreComments: state.comments.hasMore,
  commentsLoading: state.comments.commentsLoading,
  commentLoading: state.comments.commentLoading,
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest,
  firstVideo: state.videos.firstVideo,
  firstVideoReceived: state.videos.firstVideoReceived,
  ads: state.ads.details,
  scripts: state.scripts.details,
  packs: state.packs.details,
  pack: state.packs.detail,
  packsLoading: state.packs.packsLoading,
  tipLoading: state.tips.loading,
  tipCreated: state.tips.tipCreated,
  purchaseAnimation: state.purchase.animation,
  purchaseLoading: state.purchase.loading,
  purchaseError: state.purchase.error,
  frequency: state.frequency.details,
  videosReceived: state.videos.videosReceived,
  categories: state.categories.details,
  usersCategories: state.categories.chosen,
  chosenReceived: state.categories.chosenReceived
})

const mapDispatchToProps = (dispatch) => ({
  getVideos: (username, type, id) => dispatch(userVideosRequest(username, type, id)),
  getVideo: (id) => dispatch(videoIndexRequest(id)),
  getComments: (id, page) => dispatch(commentsIndexRequest(id, page)),
  clearComments: () => dispatch(commentsClearRequest()),
  createComment: (id, data) => dispatch(commentCreateRequest(id, data)),
  createTip: (data) => dispatch(tipCreateRequest(data)),
  resetTipState: () => dispatch(tipResetState()),
  createPurchase: (data) => dispatch(purchaseCreateRequest(data)),
  resetPurchaseState: () => dispatch(resetPurchaseState()),
  setView: (id) => dispatch(setViewRequest(id)),
  setLikes: (id) => dispatch(setLikeRequest(id)),
  commentSetLike: (id) => dispatch(commentLikeRequest(id)),
  commentReplySetLike: (id) => dispatch(commentReplyLikeRequest(id)),
  getAds: () => dispatch(adsIndexRequest()),
  getPacks: () => dispatch(packsIndexRequest()),
  getPack: (amount) => dispatch(packGetRequest(amount)),
  getUser: () => dispatch(sessionShowRequest()),
  sendAds: (data) => dispatch(adsSendRequest(data)),
  sendPlatform: (data) => dispatch(platformUpdateRequest(data)),
  getFrequency: () => dispatch(frequencyIndexRequest()),
  getCategories: () => dispatch(categoriesIndexRequest()),
  getChosenCategories: () => dispatch(chosenCategoriesIndexRequest()),
  sendCategories: (array) => dispatch(categoriesSendRequest(array)),
  deleteSession: () => dispatch(sessionDeleteRequest()),
  resetVideoState: () => dispatch(resetVideoState()),
  createReport: (id, data) => dispatch(reportCreateRequest(id, data)),
  resetAdState: () => dispatch(resetAdState())
})

UserVideosContainer.propTypes = {
  getVideos: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGuest: PropTypes.object.isRequired,
  getComments: PropTypes.func.isRequired,
  commentsLoading: PropTypes.bool.isRequired,
  hasMoreComments: PropTypes.bool.isRequired,
  createComment: PropTypes.func.isRequired,
  createTip: PropTypes.func.isRequired,
  createPurchase: PropTypes.func.isRequired,
  purchaseAnimation: PropTypes.bool.isRequired,
  purchaseLoading: PropTypes.bool.isRequired,
  purchaseError: PropTypes.string,
  commentLoading: PropTypes.bool.isRequired,
  clearComments: PropTypes.func.isRequired,
  commentSetLike: PropTypes.func.isRequired,
  commentReplySetLike: PropTypes.func.isRequired,
  comments: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
  setView: PropTypes.func.isRequired,
  setLikes: PropTypes.func.isRequired,
  getAds: PropTypes.func.isRequired,
  sendPlatform: PropTypes.func.isRequired,
  getPacks: PropTypes.func.isRequired,
  getPack: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  ads: PropTypes.object,
  scripts: PropTypes.array.isRequired,
  packs: PropTypes.array,
  pack: PropTypes.object,
  packsLoading: PropTypes.bool.isRequired,
  sendAds: PropTypes.func.isRequired,
  getFrequency: PropTypes.func.isRequired,
  frequency: PropTypes.number,
  videosReceived: PropTypes.bool,
  categories: PropTypes.array,
  tipLoading: PropTypes.bool.isRequired,
  tipCreated: PropTypes.bool.isRequired,
  resetTipState: PropTypes.func.isRequired,
  usersCategories: PropTypes.array,
  getCategories: PropTypes.func.isRequired,
  getChosenCategories: PropTypes.func.isRequired,
  chosenReceived: PropTypes.bool,
  sendCategories: PropTypes.func.isRequired,
  firstVideo: PropTypes.object,
  firstVideoReceived: PropTypes.bool,
  match: PropTypes.object.isRequired,
  deleteSession: PropTypes.func.isRequired,
  resetVideoState: PropTypes.func.isRequired,
  resetPurchaseState: PropTypes.func.isRequired,
  createReport: PropTypes.func.isRequired,
  resetAdState: PropTypes.func.isRequired,
  location: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(UserVideosContainer)
