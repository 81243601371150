import { put, call, takeLatest } from 'redux-saga/effects'

import { request, history } from '../../services'
import * as actions from './actions'
import { sessionShowRequest } from '../session/actions'

function* getProfile({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.getProfileSuccess(detail))
  } catch (e) {
    yield put(actions.getProfileFailure(e))
    yield history.push('/')
  }
}

function* getProfileVideos({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    if (detail.page === 1) {
      yield put(actions.getProfileVideosSuccess(detail))
    } else {
      yield put(actions.getMoreProfileVideosSuccess(detail))
    }
  } catch (e) {
    yield put(actions.getProfileVideosFailure(e))
  }
}

function* getProfileVideo({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.getProfileVideoSuccess(detail))
  } catch (e) {
    yield put(actions.getProfileVideoFailure(e))
  }
}

function* updateProfileVideo(payload, { resource }) {
  try {
    const detail = yield request(resource, 'patch', payload)
    yield put(actions.updateProfileVideoSuccess(detail))
    history.push(`/${detail.response.data.attributes.user.username}`)
  } catch (e) {
    yield put(actions.updateProfileVideoFailure(e))
  }
}

function* removeProfileVideo({ resource }) {
  try {
    const detail = yield request(resource, 'delete')
    yield put(actions.removeProfileVideoSuccess(detail))
  } catch (e) {
    yield put(actions.removeProfileVideoFailure(e))
  }
}

function* updateProfile(payload, { resource }) {
  try {
    const detail = yield request(resource, 'patch', payload)
    yield put(actions.updateProfileSuccess(detail))
    yield put(sessionShowRequest())
    yield history.push(`/${detail.data.attributes.username}`)
  } catch (e) {
    yield put(actions.updateProfileFailure(e))
  }
}

function* watchGetProfileRequest({ meta }) {
  yield call(getProfile, meta)
}

function* watchGetProfileVideosRequest({ meta }) {
  yield call(getProfileVideos, meta)
}

function* watchGetProfileVideoRequest({ meta }) {
  yield call(getProfileVideo, meta)
}

function* watchRemoveProfileVideoRequest({ meta }) {
  yield call(removeProfileVideo, meta)
}

function* watchUpdateProfileRequest({ payload, meta }) {
  yield call(updateProfile, payload, meta, history)
}

function* watchUpdateProfileVideoRequest({ payload, meta }) {
  yield call(updateProfileVideo, payload, meta, history)
}

export default function* () {
  yield takeLatest(actions.GET_PROFILE_REQUEST, watchGetProfileRequest)
  yield takeLatest(actions.GET_PROFILE_VIDEOS_REQUEST, watchGetProfileVideosRequest)
  yield takeLatest(actions.GET_PROFILE_VIDEO_REQUEST, watchGetProfileVideoRequest)
  yield takeLatest(actions.UPDATE_PROFILE_VIDEO_REQUEST, watchUpdateProfileVideoRequest)
  yield takeLatest(actions.REMOVE_PROFILE_VIDEO_REQUEST, watchRemoveProfileVideoRequest)
  yield takeLatest(actions.UPDATE_PROFILE_REQUEST, watchUpdateProfileRequest)
}
