import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Rules } from '../components/pages'
import {
  resetVideoCreatedState, videoIndexCreateSuccess, videoIndexCreateFailure
} from '../store/actions'

class RulesContainer extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      videoId: null
    }
  }

  componentDidMount() {
    const videoId = localStorage.getItem('currentVideoId')
    if (videoId) {
      this.setState({ videoId })
    }
  }

  render() {
    return (
      <Rules
        createdVideoId={this.props.createdVideoId}
        createVideoFailure={this.props.createVideoFailure}
        createVideoSuccess={this.props.createVideoSuccess}
        resetVideo={this.props.resetVideo}
        videoId={this.state.videoId}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  createdVideoId: state.videos.createdVideoId
})

const mapDispatchToProps = (dispatch) => ({
  resetVideo: () => dispatch(resetVideoCreatedState()),
  createVideoSuccess: (response) => dispatch(videoIndexCreateSuccess(response)),
  createVideoFailure: (error) => dispatch(videoIndexCreateFailure(error))
})

RulesContainer.propTypes = {
  resetVideo: PropTypes.func.isRequired,
  createVideoFailure: PropTypes.func.isRequired,
  createVideoSuccess: PropTypes.func.isRequired,
  createdVideoId: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(RulesContainer)
