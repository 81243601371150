import { put, call, takeLatest } from 'redux-saga/effects'

import { history, request } from '../../services'
import * as actions from './actions'

function* indexAds({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.adsIndexSuccess(detail))
  } catch (e) {
    yield put(actions.adsIndexFailure(e))
  }
}

function* sendAds(payload, { resource }) {
  try {
    yield request(resource, 'put', { click: payload })
    yield put(actions.adsSendSuccess())
  } catch (e) {
    yield put(actions.adsSendFailure(e))
  }
}

function* watchAdsIndexRequest({ meta }) {
  yield call(indexAds, meta, history)
}

function* watchAdsSendRequest({ payload, meta }) {
  yield call(sendAds, payload, meta, history)
}

export default function* () {
  yield takeLatest(actions.ADS_INDEX_REQUEST, watchAdsIndexRequest)
  yield takeLatest(actions.ADS_SEND_REQUEST, watchAdsSendRequest)
}
