import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const UploadModal = (props) => {
  return (
    <div className='upload-modal-container'>
      <div className='title-wrap'>
        <p className='title-text'>Upload complete</p>
        <div className='pink-br' />
      </div>
      <div className='content-wrap'>
        <p className='content-text'>We got your Qwki. It will go live as soon as it’s approved.</p>
        <div className='br' />
      </div>
      <div className='buttons-wrap'>
        <button
          className='button'
          onClick={() => {
            props.showModal(false)
            window.history.replaceState({ showUploadModal: false }, '', '/v')
          }}
          type='button'
        >
          <p className='button-text'>Dismiss</p>
        </button>
      </div>
    </div>
  )
}

UploadModal.propTypes = ({
  showModal: PropTypes.func.isRequired
})

export default UploadModal
