import * as React from 'react'

const VisaIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.67019 13.3803H7.03823L8.05818 7.11201H9.69031L8.67019 13.3803ZM5.66533 7.11201L4.10949 11.4234L3.92539 10.495L3.92555 10.4953L3.37643 7.67639C3.37643 7.67639 3.31003 7.11201 2.60228 7.11201H0.0301811L0 7.21814C0 7.21814 0.786553 7.38179 1.70708 7.93461L3.12492 13.3805H4.82529L7.4217 7.11201H5.66533ZM18.5015 13.3803H20L18.6935 7.11184H17.3816C16.7758 7.11184 16.6283 7.57897 16.6283 7.57897L14.1943 13.3803H15.8955L16.2357 12.4492H18.3104L18.5015 13.3803ZM16.7057 11.163L17.5632 8.81724L18.0456 11.163H16.7057ZM14.3219 8.61938L14.5548 7.27331C14.5548 7.27331 13.8362 7 13.087 7C12.2772 7 10.354 7.35396 10.354 9.07512C10.354 10.6945 12.6112 10.7146 12.6112 11.5652C12.6112 12.4158 10.5865 12.2634 9.91834 11.727L9.67572 13.1345C9.67572 13.1345 10.4044 13.4884 11.5178 13.4884C12.6315 13.4884 14.3115 12.9118 14.3115 11.3424C14.3115 9.71261 12.034 9.56087 12.034 8.85228C12.0342 8.14353 13.6236 8.23457 14.3219 8.61938Z'
        fill='#2566AF'
      />
      <path
        d='M4.88776 10.2113L4.42563 7.83898C4.42563 7.83898 4.36975 7.36401 3.77413 7.36401H1.60951L1.58411 7.45334C1.58411 7.45334 2.62451 7.66895 3.62243 8.47679C4.57661 9.24894 4.88776 10.2113 4.88776 10.2113Z'
        fill='#E6A540'
      />
    </svg>
  )
}

export default VisaIcon
