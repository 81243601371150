import * as React from "react"

const SignUpIcon = (props) => {
  return (
    <svg width={16} height={15} viewBox="0 0 16 15" fill="none" {...props}>
      <path
        d="M13.893 2.074a3.667 3.667 0 00-5.186 0L8 2.78l-.707-.706A3.668 3.668 0 002.107 7.26l.706.707L8 13.154l5.187-5.187.706-.707a3.667 3.667 0 000-5.186v0z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SignUpIcon
