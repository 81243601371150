import * as actions from './actions'

const initialResourceState = {
  details: [],
  chosen: [],
  loading: false,
  defaultCategoriesGif: null,
  categoriesReceived: false,
  chosenReceived: false,
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.CATEGORIES_INDEX_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.CATEGORIES_INDEX_SUCCESS:
    return {
      ...state,
      loading: false,
      details: payload.categories.data,
      defaultCategoriesGif: payload.default_gif,
      categoriesReceived: true
    }

  case actions.CATEGORIES_INDEX_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.CATEGORIES_SEND_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.CATEGORIES_SEND_SUCCESS:
    return {
      ...state,
      loading: false
    }

  case actions.CATEGORIES_SEND_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.CHOSEN_CATEGORIES_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.CHOSEN_CATEGORIES_SUCCESS:
    return {
      ...state,
      chosen: payload.data,
      loading: false,
      chosenReceived: true
    }

  case actions.CHOSEN_CATEGORIES_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  default:
    return state
  }
}
