const resource = 'payments'

export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST'
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS'
export const PAYMENT_CREATE_FAILURE = 'PAYMENT_CREATE_FAILURE'
export const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE'

export const paymentCreateRequest = (payload) => ({
  type: PAYMENT_CREATE_REQUEST,
  meta: {
    resource
  },
  payload
})

export const paymentCreateSuccess = (detail) => ({
  type: PAYMENT_CREATE_SUCCESS,
  payload: detail
})

export const paymentCreateFailure = (error) => ({
  type: PAYMENT_CREATE_FAILURE,
  error: true,
  payload: error
})

export const resetPaymentState = () => ({
  type: RESET_PAYMENT_STATE
})
