/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import { history } from '../../../services'
import './styles.scss'

const VideoActionsModal = (props) => {
  const {
    show,
    close,
    videoId,
    videoIndex,
    videoApproved,
    shareVideo,
    deleteVideo
  } = props

  const handleShareVideo = () => {
    close()
    shareVideo(videoIndex)
  }

  const handleEditVideo = () => {
    close()
    history.push(`/profile/videos/${videoId}/edit`)
  }

  const handleDeleteVideo = () => {
    if (window.confirm('Are you sure?')) {
      close()
      deleteVideo(videoId)
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      className='video-actions-modal'
      isOpen={show}
      onRequestClose={close}
      overlayClassName='Overlay'
    >
      <div className='video-actions-modal_wrapper'>
        <div className='video-actions-modal_actions'>
          {videoApproved && (
            <span onClick={() => handleShareVideo()} role='button' tabIndex={0}>
              Share video
            </span>
          )}
          <span onClick={() => handleEditVideo()} role='button' tabIndex={0}>
            Edit video
          </span>
          <span onClick={() => handleDeleteVideo()} role='button' tabIndex={0}>
            Delete video
          </span>
        </div>
      </div>
    </Modal>
  )
}

VideoActionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  videoIndex: PropTypes.number,
  videoId: PropTypes.string,
  videoApproved: PropTypes.bool,
  shareVideo: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func.isRequired
}

export default VideoActionsModal
