import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Swipe from 'react-easy-swipe'
import { isMobile } from 'react-device-detect'
import postscribe from 'postscribe'

import { CrossIcon, ArrowTopCategories } from '../../assets/svg'
import './styles.scss'

const Ads = (props) => {
  const [counter, setCounter] = useState(props.ads.delay)
  const [screenX, setScreenX] = useState(0)
  const [screenY, setScreenY] = useState(0)

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => clearInterval(timer)
  }, [counter])

  useEffect(() => {
    if (props.ads.file_type === 'Video') {
      const adsContainer = document.querySelector('.banner-container')

      if (adsContainer.children.length === 0) {
        postscribe(
          `#${props.ads.id}`,
          `<script src=${props.ads.url}></script>`
        )
      }
    }
  })

  const renderCross = () => {
    if (counter > 0) {
      return <p className='cross-timer'>Skip ad in { counter > 0 && counter}</p>
    } else {
      return <CrossIcon className='cross' onClick={() => props.closeAds()} />
    }
  }

  const renderMobileСlosing = () => {
    if (props.ads.close_by_swipe) {
      return (
        <div className='closes-swipe'>
          <div className='arrow'>
            <ArrowTopCategories />
          </div>
          <div className='swipe-info'>
            {
              counter > 0
                ? <p className='text'>Skip ad in {counter}</p>
                : <p className='text'>Swipe to Dismiss</p>
            }
          </div>
        </div>
      )
    } else {
      return renderCross()
    }
  }

  const onSwipeStart = (event) => {
    setScreenX(event.nativeEvent.touches[0].screenX)
    setScreenY(event.nativeEvent.touches[0].screenY)
  }

  const onSwipeEnd = (event) => {
    if (props.ads.close_by_swipe && counter === 0) {
      if (
        event.nativeEvent.changedTouches[0].screenX !== screenX &&
          event.nativeEvent.changedTouches[0].screenY !== screenY
      ) props.closeAds()
    }
  }

  const checkClick = (event) => {
    if (event.target.tagName !== 'svg') {
      props.setViewed(true)
    }
  }

  return (
    <Swipe
      onSwipeEnd={onSwipeEnd}
      onSwipeStart={onSwipeStart}
    >
      { !isMobile ? renderCross() : renderMobileСlosing() }
      <div
        className='banner-container'
        id={props.ads.id}
      >
        {
          props.ads.file_type !== 'Video' && (
            <div className='banner-wrapper' onClick={(event) => checkClick(event)} role='button' tabIndex='0'>
              <a
                className='banner-link'
                href={props.ads.url}
                rel='noopener noreferrer'
                target='_blank'
              >
                <div className='banner-image' style={{ backgroundImage: `url(${props.ads.image})` }} />
              </a>
            </div>
          )
        }
      </div>
    </Swipe>
  )
}

Ads.propTypes = ({
  closeAds: PropTypes.func.isRequired,
  ads: PropTypes.object.isRequired,
  setViewed: PropTypes.func.isRequired
})

export default Ads
