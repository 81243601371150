/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { history } from '../../../../services'
import { MoreDotsIcon, ModalCloseIcon } from '../../../assets/svg'
import '../styles.scss'

const ProfileVideosItem = (props) => {
  const {
    id,
    index,
    image,
    isEditable,
    isApproved,
    handleMore,
    moreIsOpen,
    duration
  } = props
  const [currentMore, setCurrentMore] = useState(false)

  useEffect(() => {
    if (!moreIsOpen) {
      setCurrentMore(false)
    }
  }, [moreIsOpen])

  const openMore = () => {
    setCurrentMore(!currentMore)
    handleMore(index, id, isApproved)
  }

  return (
    <div className='profile__video'>
      {isEditable && (
        <div>
          {!isApproved && (
            <div className='profile__video__pending'>
              <span>Pending approval</span>
            </div>
          )}
          {isApproved && duration && <div className='video__duration'>{duration}</div>}
          <div
            className='profile__video__more'
            onClick={() => openMore()}
            role='button'
            tabIndex={0}
          >
            {currentMore ? (
              <ModalCloseIcon height={12} strokecolor='#ffffff' width={12} />
            ) : (
              <MoreDotsIcon />
            )}
          </div>
          {currentMore && <div className='profile__video__overlay' />}
        </div>
      )}
      <a
        className='profile__video__image profileVideoBtn'
        onClick={() => isApproved && history.push(`/${props.username}/${props.type}/${props.id}`)}
        role='button'
        tabIndex={0}
      >
        <img alt='' src={image} />
        {isApproved && duration && <div className='video__duration'>{duration}</div>}
      </a>
    </div>
  )
}

ProfileVideosItem.propTypes = {
  username: PropTypes.string,
  id: PropTypes.string.isRequired,
  duration: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  handleMore: PropTypes.func.isRequired,
  moreIsOpen: PropTypes.bool.isRequired
}

export default ProfileVideosItem
