import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import BeatLoader from 'react-spinners/BeatLoader'

import SuccessGif from '../../assets/paymentsuccess.gif'
import { ModalCloseIcon } from '../../assets/svg'
import './styles.scss'

const PaymentModal = (props) => {
  const { show, onClose, onBack, loading, message, error } = props

  return (
    <Modal
      ariaHideApp={false}
      className='payment-modal'
      isOpen={show}
      onRequestClose={() => onClose()}
      overlayClassName='Overlay Overlay--payment'
    >
      {loading ? (
        <div className='payment-modal__loader'>
          <BeatLoader color='#9a9a9a' size={25} />
        </div>
      ) : (
        <div className='payment-modal__wrapper'>
          <div className='payment-modal__icon'>
            {message && <img alt='success' src={SuccessGif} />}
            {error && <ModalCloseIcon />}
          </div>
          <div className='payment-modal__message'>
            {error || message}
          </div>
          <div className='payment-modal__actions'>
            {error ? (
              <button
                className='soon-modal__btn'
                onClick={() => onClose()}
                type='button'
              >
                Try again
              </button>
            ) : (
              <button
                className='soon-modal__btn'
                onClick={() => onBack()}
                type='button'
              >
                Continue
              </button>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

PaymentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  message: PropTypes.string,
  error: PropTypes.string
}

export default PaymentModal
