import * as React from "react"

const CheckboxCheckedIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2z"
        stroke={`url(${window.location.pathname}#prefix__paint0_linear`}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9l-6.188 6L8 12.273"
        stroke="#48D2A0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={3}
          y1={13.8}
          x2={21}
          y2={13.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FF75" />
          <stop offset={1} stopColor="#00FFC2" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default CheckboxCheckedIcon
