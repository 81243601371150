import { put, call, takeLatest } from 'redux-saga/effects'

import { history, request } from '../../services'
import * as actions from './actions'
import { userCreateRequest } from '../users/actions'

function* indexVideos({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.videosIndexSuccess(detail))
  } catch (e) {
    yield put(actions.videosIndexFailure(e))
  }
}

function* userVideos({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.userVideosSuccess(detail))
  } catch (e) {
    yield put(actions.videosIndexFailure(e))
  }
}

function* setView({ resource }) {
  try {
    const detail = yield request(resource, 'put')
    yield put(actions.setViewSuccess(detail))
  } catch (e) {
    yield put(actions.setViewFailure(e))
  }
}

function* setLike({ resource }) {
  try {
    const detail = yield request(resource, 'put')
    yield put(actions.setLikeSuccess(detail))
  } catch (e) {
    yield put(actions.setLikeFailure(e))
  }
}

function* indexVideo({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.videoIndexSuccess(detail))
  } catch (e) {
    yield put(actions.videoIndexFailure(e))
  }
}

function* updateVideo(payload, { resource }) {
  try {
    if (payload.loginData) {
      yield put(userCreateRequest(payload.loginData))
    }
    const detail = yield request(resource, 'put', payload.data)
    yield put(actions.videoUpdateSuccess(detail))
    yield put(actions.resetVideoCreatedState())
    yield history.push('/v', { showUploadModal: true })
  } catch (e) {
    yield put(actions.videoUpdateFailure(e))
  }
}

function* removeVideo({ resource }) {
  try {
    yield request(resource, 'delete')
    yield put(actions.removeVideoSuccess())
  } catch (e) {
    yield put(actions.removeVideoFailure(e))
  }
}

function* watchVideosIndexRequest({ meta }) {
  yield call(indexVideos, meta, history)
}

function* watchUserVideosRequest({ meta }) {
  yield call(userVideos, meta, history)
}

function* watchSetViewRequest({ meta }) {
  yield call(setView, meta, history)
}

function* watchSetLikeRequest({ meta }) {
  yield call(setLike, meta, history)
}

function* watchVideoIndexRequest({ meta }) {
  yield call(indexVideo, meta, history)
}

function* watchVideoUpdateRequest({ payload, meta }) {
  yield call(updateVideo, payload, meta, history)
}

function* watchRemoveVideo({ meta }) {
  yield call(removeVideo, meta, history)
}

export default function* () {
  yield takeLatest(actions.VIDEOS_INDEX_REQUEST, watchVideosIndexRequest)
  yield takeLatest(actions.USER_VIDEOS_REQUEST, watchUserVideosRequest)
  yield takeLatest(actions.SET_VIEW_REQUEST, watchSetViewRequest)
  yield takeLatest(actions.SET_LIKE_REQUEST, watchSetLikeRequest)
  yield takeLatest(actions.VIDEO_INDEX_REQUEST, watchVideoIndexRequest)
  yield takeLatest(actions.VIDEO_UPDATE_REQUEST, watchVideoUpdateRequest)
  yield takeLatest(actions.REMOVE_VIDEO, watchRemoveVideo)
}
