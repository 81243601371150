/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import BeatLoader from 'react-spinners/BeatLoader'
import InfiniteScroll from 'react-infinite-scroller'
import isEmpty from 'lodash.isempty'
import ReactGA from 'react-ga'

import { history } from '../../../services'
import CommentsModalComment from './comment'
import { ModalCloseIcon, SendIcon } from '../../assets/svg'

import './styles.scss'

const CommentsModal = (props) => {
  const [pagyPage, setPagyPage] = useState(2)
  const [reply, setReply] = useState()
  const [replyUsername, setReplyUsername] = useState('')
  const [message, setMessage] = useState('')
  const topOfComments = useRef()

  const {
    videoId,
    getComments,
    hasMoreComments,
    comments,
    commentsCount,
    commentsLoading,
    commentLoading,
    showCommentsModal,
    handleCloseModal,
    commentSetLike,
    commentReplySetLike,
    createComment,
    currentUser,
    currentGuest
  } = props

  const loadMore = (page) => {
    if (!commentsLoading) {
      getComments(videoId, page)
      setPagyPage(page + 1)
    }
  }

  const scrollCommentsToTop = () => {
    topOfComments.current.scrollIntoView({
      behavior: 'smooth'
    })
  }

  const close = () => {
    setMessage('')
    setReply('')
    setPagyPage(2)
    handleCloseModal()
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    createComment({ videoId, message, reply })
    ReactGA.event({
      category: 'Comment send button',
      action: 'Comment created'
    })
  }

  useEffect(() => {
    if (commentLoading) {
      setMessage('')
      if (!reply) {
        scrollCommentsToTop()
      }
    }
  }, [commentLoading, reply])

  return (
    <Modal
      className='comments-modal'
      contentLabel='onRequestClose Example'
      isOpen={showCommentsModal}
      onRequestClose={close}
      overlayClassName='Overlay comments'
    >
      {commentsLoading && comments.length === 0 ? (
        <div className='comments-modal__loading'>
          <BeatLoader color='#9a9a9a' size={25} />
        </div>
      ) : (
        <div className='comments-modal__wrapper'>
          <a className='comments-modal__title commentsCloseBtn' role='button'>
            <p>Comments {commentsCount > 0 && `(${commentsCount})`}</p>
            <ModalCloseIcon onClick={close} />
          </a>
          <div className='comments-modal__body'>
            <div ref={topOfComments} />
            {comments.length > 0 ? (
              <InfiniteScroll
                hasMore={hasMoreComments}
                initialLoad={false}
                loader={(
                  <div key={0} className='loader'>
                    <BeatLoader color='#9a9a9a' size={10} />
                  </div>
                )}
                loadMore={() => loadMore(pagyPage)}
                threshold={100}
                useWindow={false}
              >
                {comments.map((comment) => (
                  <CommentsModalComment
                    key={comment.id}
                    avatar={comment.attributes.user.avatar}
                    commentReplySetLike={commentReplySetLike}
                    commentSetLike={commentSetLike}
                    currentUser={currentUser}
                    id={comment.id}
                    is_liked={comment.attributes.is_liked}
                    likes_count={comment.attributes.likes_count}
                    message={comment.attributes.message}
                    profileId={comment.attributes.user.id}
                    replies={comment.attributes.replies.data}
                    replies_count={comment.attributes.replies_count}
                    reply={reply}
                    setMessage={setMessage}
                    setReply={setReply}
                    setReplyUsername={setReplyUsername}
                    tip={comment.attributes.tip}
                    tipMessage={comment.attributes.tip_message}
                    username={comment.attributes.user.username}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <div className='comments-modal__body__empty'>
                <h4>No comments yet</h4>
              </div>
            )}
          </div>
          <div className='comments-modal__form__wrapper'>
            {isEmpty(currentUser) && !isEmpty(currentGuest) ? (
              <div className='comments-modal__form--login'>
                <button
                  className='auth-button'
                  onClick={() => history.push('/sign_up')}
                  type='button'
                >
                  <p className='modal-login-buttons'>Create an account</p>
                </button>
                <p className='modal-login-separator'>|</p>
                <button
                  className='auth-button'
                  onClick={() => history.push('/sign_in')}
                  type='button'
                >
                  <p className='modal-login-buttons'>Login</p>
                </button>
              </div>
            ) : (
              <form
                className='comments-modal__form'
                onSubmit={(e) => handleSubmit(e)}
              >
                <input
                  autoComplete='off'
                  disabled={commentLoading}
                  id='message'
                  minLength='2'
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={
                    reply ? `Reply ${replyUsername}...` : 'Add comment...'
                  }
                  required
                  value={message}
                />
                <button className='comments-modal__form__submit commentSendBtn' type='submit'>
                  <SendIcon />
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

CommentsModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  comments: PropTypes.array.isRequired,
  commentsCount: PropTypes.number.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  showCommentsModal: PropTypes.bool.isRequired,
  commentSetLike: PropTypes.func.isRequired,
  commentReplySetLike: PropTypes.func.isRequired,
  commentsLoading: PropTypes.bool.isRequired,
  commentLoading: PropTypes.bool.isRequired,
  createComment: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  getComments: PropTypes.func.isRequired,
  hasMoreComments: PropTypes.bool.isRequired,
  currentGuest: PropTypes.object
}

export default CommentsModal
