import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { history } from '../services'
import {
  getProfileRequest,
  updateProfileRequest,
  validationsCreateRequest,
  resetValidationUsername,
  sessionShowRequest
} from '../store/actions'

import ProfileEdit from '../components/pages/ProfileEdit'

class ProfileEditContainer extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    if (this.props.match.params.username && this.props.isOwn) {
      this.props.getProfile(this.props.match.params.username)
    } else {
      history.push('/v')
    }
  }

  onGetValidation = (field, value) => {
    if (value) {
      this.props.getValidation({ [field]: value.toLowerCase() })
    }
  }

  render() {
    return (
      <ProfileEdit
        getValidation={this.onGetValidation}
        profile={this.props.profile}
        profileLoading={this.props.profileLoading}
        updateProfile={this.props.updateProfile}
        usernamePresent={this.props.usernamePresent}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile.details,
  profileLoading: state.profile.profileLoading,
  isOwn: state.profile.details.is_own,
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest,
  usernamePresent: state.validation.usernamePresent
})

const mapDispatchToProps = (dispatch) => ({
  getProfile: (username) => dispatch(getProfileRequest(username)),
  updateProfile: (username, data) => dispatch(updateProfileRequest(username, data)),
  getValidation: (data) => dispatch(validationsCreateRequest(data)),
  resetValidUsername: () => dispatch(resetValidationUsername()),
  getUser: () => dispatch(sessionShowRequest())
})

ProfileEditContainer.propTypes = {
  getProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  profileLoading: PropTypes.bool.isRequired,
  getValidation: PropTypes.func.isRequired,
  usernamePresent: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  isOwn: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditContainer)
