const resource = 'users'

export const USER_CREATE_REQUEST = 'CREATE_USER_REQUEST'
export const USER_CREATE_SUCCESS = 'CREATE_USER_SUCCEEDED'
export const USER_CREATE_FAILURE = 'CREATE_USER_FAILED'

export const userCreateRequest = (data) => ({
  type: USER_CREATE_REQUEST,
  payload: { data },
  meta: {
    resource
  }
})

export const userCreateSuccess = () => ({
  type: USER_CREATE_SUCCESS
})

export const userCreateFailure = (error) => ({
  type: USER_CREATE_FAILURE,
  error: true,
  payload: error
})
