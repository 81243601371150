import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'

function* getInfo({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.infoIndexSuccess(detail))
  } catch (e) {
    yield put(actions.infoIndexFailure(e))
  }
}

function* watchInfoIndexRequest({ meta }) {
  yield call(getInfo, meta)
}

export default function* () {
  yield takeLatest(actions.INFO_INDEX_REQUEST, watchInfoIndexRequest)
}
