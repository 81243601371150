import { put, call, takeLatest } from 'redux-saga/effects'

import { request, historyRefresh } from '../../services'
import * as actions from './actions'

function* createPurchase(payload, { resource }) {
  try {
    const delay = time => new Promise(resolve => setTimeout(resolve, time))
    const detail = yield request(resource, 'post', payload)
    yield put(actions.purchaseCreateSuccess(detail))
    yield call(delay, 1500)
    yield historyRefresh.push(`/v/${15000 + parseInt(payload.id)}`)
  } catch (e) {
    yield put(actions.purchaseCreateFailure(e))
  }
}

function* watchCreatePurchaseRequest({ payload, meta }) {
  yield call(createPurchase, payload, meta)
}

export default function* () {
  yield takeLatest(actions.PURCHASE_CREATE_REQUEST, watchCreatePurchaseRequest)
}
