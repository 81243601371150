const resource = 'sessions'

export const SESSION_CREATE_REQUEST = 'CREATE_SESSION_REQUEST'
export const SESSION_CREATE_SUCCESS = 'CREATE_SESSION_SUCCEEDED'
export const SESSION_CREATE_FAILURE = 'CREATE_SESSION_FAILED'

export const sessionCreateRequest = (data) => ({
  type: SESSION_CREATE_REQUEST,
  payload: { data },
  meta: {
    resource
  }
})

export const sessionCreateSuccess = (detail) => ({
  type: SESSION_CREATE_SUCCESS,
  payload: detail
})

export const sessionCreateFailure = (error) => ({
  type: SESSION_CREATE_FAILURE,
  error: true,
  payload: error
})

export const SESSION_SHOW_REQUEST = 'SHOW_SESSION_REQUEST'
export const SESSION_SHOW_SUCCESS = 'SHOW_SESSION_SUCCEEDED'
export const SESSION_SHOW_FAILURE = 'SHOW_SESSION_FAILED'

export const sessionShowRequest = () => ({
  type: SESSION_SHOW_REQUEST,
  meta: {
    resource: 'users'
  }
})

export const sessionShowSuccess = (detail) => ({
  type: SESSION_SHOW_SUCCESS,
  payload: detail
})

export const sessionShowFailure = (error) => ({
  type: SESSION_SHOW_FAILURE,
  error: true,
  payload: error
})

export const SESSION_DELETE_REQUEST = 'DELETE_SESSION_REQUEST'
export const SESSION_DELETE_SUCCESS = 'DELETE_SESSION_SUCCEEDED'
export const SESSION_DELETE_FAILURE = 'DELETE_SESSION_FAILED'

export const sessionDeleteRequest = () => ({
  type: SESSION_DELETE_REQUEST,
  meta: {
    resource
  }
})

export const sessionDeleteSuccess = (detail) => ({
  type: SESSION_DELETE_SUCCESS,
  payload: detail
})

export const sessionDeleteFailure = (error) => ({
  type: SESSION_DELETE_FAILURE,
  error: true,
  payload: error
})
