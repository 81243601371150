import * as React from 'react'

const HomeIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.566 1.25253C10.4159 1.1025 10.2125 1.01822 10.0003 1.01822C9.78811 1.01822 9.58463 1.1025 9.4346 1.25253L0.34369 10.3434C0.0312701 10.6559 0.0312702 11.1624 0.34369 11.4748C0.656109 11.7872 1.16264 11.7872 1.47506 11.4748L2.83686 10.113V18.182C2.83686 18.6238 3.19503 18.982 3.63686 18.982H16.3641C16.806 18.982 17.1641 18.6238 17.1641 18.182V10.1134L18.5255 11.4748C18.8379 11.7872 19.3445 11.7872 19.6569 11.4748C19.9693 11.1624 19.9693 10.6559 19.6569 10.3434L16.9375 7.62405C16.9324 7.61883 16.9273 7.61368 16.922 7.60861L10.566 1.25253Z'
        fill='url(#paint1_linear)'
      />
      <defs>
        <linearGradient
          id='paint1_linear'
          x1='1.34626e-07'
          y1='1'
          x2='20'
          y2='19'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF1C19' />
          <stop offset='1' stopColor='#FF1ACD' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default HomeIcon
