import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import BeatLoader from 'react-spinners/BeatLoader'
import InfiniteScroll from 'react-infinite-scroller'

import Item from './item'
import { VideoActionsModal } from '../../../molecules'
import {
  GridVideosIcon,
  GridVideosActiveIcon,
  LikedVideosIcon,
  LikedVideosActiveIcon,
  LockIcon,
  LockActiveIcon
} from '../../../assets/svg'
import '../styles.scss'

const ProfileVideos = (props) => {
  const {
    videos,
    profile,
    deleteVideo,
    getVideos,
    isOwn,
    videosLoading,
    haveVideos,
    handleShareVideo,
    resetProfileVideos,
    hasMoreVideos
  } = props
  const [pagyPage, setPagyPage] = useState(2)
  const [ownIsOpen, setOwnIsOpen] = useState(false)
  const [likedIsOpen, setLikedIsOpen] = useState(false)
  const [purchasedIsOpen, setPurchasedIsOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const [videoIndex, setVideoIndex] = useState(null)
  const [videoApproved, setVideoApproved] = useState(null)
  const [moreIsOpen, setMoreOpen] = useState(false)

  const buildType = () => {
    if (isOwn && ownIsOpen) {
      return 'my'
    } else if (ownIsOpen) {
      return 'own'
    } else if (likedIsOpen) {
      return 'liked'
    } else {
      return 'purchased'
    }
  }

  useEffect(() => {
    resetProfileVideos()
    getVideos({ id: profile.id, type: buildType(), page: 1 })
  }, [ownIsOpen, likedIsOpen, purchasedIsOpen])

  const loadMore = (page) => {
    if (!videosLoading && hasMoreVideos) {
      getVideos({ id: profile.id, type: buildType(), page: pagyPage })
      setPagyPage(page + 1)
    }
  }

  const handleOpenOwn = () => {
    setPagyPage(2)
    setLikedIsOpen(false)
    setOwnIsOpen(true)
    setPurchasedIsOpen(false)
  }

  const handleOpenLiked = () => {
    setPagyPage(2)
    setOwnIsOpen(false)
    setLikedIsOpen(true)
    setPurchasedIsOpen(false)
  }

  const handleOpenPurchased = () => {
    setPagyPage(2)
    setOwnIsOpen(false)
    setLikedIsOpen(false)
    setPurchasedIsOpen(true)
  }

  useEffect(() => {
    if (!haveVideos) {
      handleOpenLiked()
    } else {
      handleOpenOwn()
    }
  }, [])

  const handleMore = (videoIndex, videoId, approved) => {
    if (moreIsOpen) {
      setMoreOpen(false)
    } else {
      setVideoApproved(approved)
      setVideoIndex(videoIndex)
      setVideoId(videoId)
      setMoreOpen(true)
    }
  }

  return (
    <div className='profile__videos'>
      <div className='profile__videos__menu'>
        <div
          className='profile__videos__menu__item__wrapper'
          onClick={() => handleOpenOwn()}
          role='button'
          tabIndex={0}
        >
          <span
            className={`profile__videos__menu__item ${
              ownIsOpen && 'profile__videos__menu--active'
            }`}
          >
            {ownIsOpen ? <GridVideosActiveIcon /> : <GridVideosIcon />}
          </span>
        </div>
        <div
          className='profile__videos__menu__item__wrapper'
          onClick={() => handleOpenLiked()}
          role='button'
          tabIndex={0}
        >
          <span
            className={`profile__videos__menu__item ${
              likedIsOpen && 'profile__videos__menu--active'
            }`}
          >
            {likedIsOpen ? <LikedVideosActiveIcon /> : <LikedVideosIcon />}
          </span>
        </div>
        {profile.is_own && (
          <div
            className='profile__videos__menu__item__wrapper'
            onClick={() => handleOpenPurchased()}
            role='button'
            tabIndex={0}
          >
            <span
              className={`profile__videos__menu__item ${
                purchasedIsOpen && 'profile__videos__menu--active'
              }`}
            >
              {purchasedIsOpen ? <LockActiveIcon /> : <LockIcon />}
            </span>
          </div>
        )}
      </div>
      {profile.is_own && (
        <VideoActionsModal
          close={handleMore}
          deleteVideo={deleteVideo}
          shareVideo={handleShareVideo}
          show={moreIsOpen}
          videoApproved={videoApproved}
          videoId={videoId}
          videoIndex={videoIndex}
        />
      )}
      <InfiniteScroll
        hasMore={hasMoreVideos}
        initialLoad={false}
        loadMore={() => loadMore(pagyPage)}
        useWindow
      >
        <div className='profile__videos__content'>
          {videos.length > 0 &&
            videos.map((video, index) => (
              <Item
                key={`${video.id + index}`}
                id={video.id}
                index={index}
                image={video.attributes.image}
                duration={video.attributes.duration}
                isApproved={video.attributes.approved}
                isEditable={
                  isOwn &&
                  ownIsOpen &&
                  video.attributes.user.username === profile.username
                }
                type={buildType()}
                username={props.profile.username}
                moreIsOpen={moreIsOpen}
                handleMore={handleMore}
              />
            ))}
        </div>
        {videosLoading && (
          <div key={0} className='profile__videos__content__loader'>
            <BeatLoader color='#9a9a9a' size={10} />
          </div>
        )}
        {!videosLoading && videos.length === 0 && (
          <div className='profile__videos__content--empty'>No videos</div>
        )}
      </InfiniteScroll>
    </div>
  )
}

ProfileVideos.propTypes = {
  getVideos: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func.isRequired,
  videos: PropTypes.array.isRequired,
  videosLoading: PropTypes.bool.isRequired,
  handleShareVideo: PropTypes.func.isRequired,
  resetProfileVideos: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  isOwn: PropTypes.bool.isRequired,
  haveVideos: PropTypes.bool.isRequired,
  hasMoreVideos: PropTypes.bool.isRequired
}

export default ProfileVideos
