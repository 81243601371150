import React, { lazy, Suspense } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'

import { history } from '../services'
import { Quill } from '../components/pages'
import {
  SignUpContainer, SignInContainer,
  AboutContainer, RulesContainer
} from '../containers'

const MainContainer = lazy(() => import('../containers/MainContainer'))
const CategoriesContainer = lazy(() => import('../containers/CategoriesContainer'))
const UploadVideoContainer = lazy(() => import('../containers/UploadVideoContainer'))
const ProfileContainer = lazy(() => import('../containers/ProfileContainer'))
const ProfileEditContainer = lazy(() => import('../containers/ProfileEditContainer'))
const ProfileVideoEditContainer = lazy(() => import('../containers/ProfileVideoEditContainer'))
const PurchaseContainer = lazy(() => import('../containers/PurchaseContainer'))
const UserVideosContainer = lazy(() => import('../containers/UserVideosContainer'))

const PublicRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Router history={history}>
      <Switch>
        <Route component={MainContainer} path='/v/:id?' />
        <Route component={CategoriesContainer} exact path='/' />
        <Route component={SignUpContainer} exact path='/sign_up' />
        <Route component={SignInContainer} exact path='/sign_in' />
        <Route component={AboutContainer} exact path='/about' />
        <Route component={Quill} exact path='/quill' />
        <Route component={RulesContainer} exact path='/rules' />
        <Route component={UploadVideoContainer} exact path='/upload' />
        <Route component={PurchaseContainer} exact path='/purchase/:amount?' />

        <Route component={ProfileContainer} exact path='/:username?' />
        <Route component={ProfileEditContainer} exact path='/:username?/edit' />
        <Route component={UserVideosContainer} exact path='/:username?/:type/:id' />
        <Route component={ProfileVideoEditContainer} exact path='/profile/videos/:id/edit' />

        <Redirect exact to='/' />
      </Switch>
    </Router>
  </Suspense>
)

export default PublicRoutes
