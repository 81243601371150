import * as React from 'react'

const ArrowUpIcon = (props) => {
  return (
    <svg
      width='11'
      height='7'
      viewBox='0 0 11 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0.5 6.5L5.5 1.5L10.5 6.5' stroke='#9A9A9A' strokeWidth='1.3' />
    </svg>
  )
}

export default ArrowUpIcon
