import * as React from 'react'

const EditIcon = (props) => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='34' height='34' rx='17' fill='white' />
      <path
        d='M10 21.0837V24H12.9163L21.5173 15.399L18.601 12.4827L10 21.0837ZM23.7725 13.1437C24.0758 12.8404 24.0758 12.3505 23.7725 12.0472L21.9528 10.2275C21.6495 9.92418 21.1596 9.92418 20.8563 10.2275L19.4331 11.6506L22.3494 14.5669L23.7725 13.1437Z'
        fill='black'
      />
    </svg>
  )
}

export default EditIcon
