import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import isEmpty from 'lodash.isempty'

import { history } from '../../../services'
import { HomeIcon, UploadIcon, ProfileIcon } from '../../assets/svg'
import './styles.scss'

const NavigationBar = (props) => {
  const animateButton = (id) => {
    const animation = document.getElementById(`nav-${id}-animation`)

    animation.classList.remove('animate')
    animation.classList.add('animate')
    const target = document.getElementsByClassName(`nav-${id}-class`)[0]
    target.style.transform = 'scale(0.8)'
    setTimeout(() => {
      target.style.transform = 'scale(1)'
      target.classList.remove('animate')
    }, 700)
  }

  const pushToTheHome = () => {
    animateButton('home')
  }

  const pushToTheRules = () => {
    animateButton('upload')
    setTimeout(() => {
      ReactGA.event({
        category: 'Upload button',
        action: 'Clicked upload'
      })
      history.push('/rules')
    }, 200)
  }

  const pushToTheProfile = () => {
    animateButton('profile')
    setTimeout(() => {
      if (!isEmpty(props.currentUser) && isEmpty(props.currentGuest)) {
        history.push(`/${props.currentUser.username}`)
      } else {
        history.push('/sign_in')
      }
    }, 200)
  }

  return (
    <div className='main-nav-bar'>
      <div className='main-nav-bar_wrapper'>
        <span
          className='nav-animation'
          onClick={() => pushToTheHome()}
          role='button'
          tabIndex={0}
        >
          <button
            className='nav-bubble'
            id='nav-home-animation'
            type='button'
          />
          <HomeIcon className='nav-home-class' />
        </span>
        <span
          className='nav-animation'
          onClick={() => pushToTheRules()}
          role='button'
          tabIndex={0}
        >
          <button
            className='nav-bubble'
            id='nav-upload-animation'
            type='button'
          />
          <UploadIcon className='nav-upload-class' />
        </span>
        <span
          className='nav-animation'
          onClick={() => pushToTheProfile()}
          role='button'
          tabIndex={0}
        >
          <button
            className='nav-bubble'
            id='nav-profile-animation'
            type='button'
          />
          <ProfileIcon className='nav-profile-class' />
        </span>
      </div>
    </div>
  )
}

NavigationBar.propTypes = {
  currentGuest: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
}

export default NavigationBar
