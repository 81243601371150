import * as React from 'react'

const CopyRightIcon = (props) => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M18.2727 33.7729C26.557 33.7729 33.2727 27.0572 33.2727 18.7729C33.2727 10.4887 26.557 3.77295 18.2727 3.77295C9.98843 3.77295 3.27271 10.4887 3.27271 18.7729C3.27271 27.0572 9.98843 33.7729 18.2727 33.7729Z" stroke="white" strokeWidth="3.34091" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.7624 24.9841C17.4929 24.9841 16.3737 24.7085 15.4048 24.1573C14.4359 23.606 13.6675 22.8376 13.0996 21.852C12.5483 20.8665 12.2727 19.7389 12.2727 18.4694C12.2727 17.1998 12.5483 16.0723 13.0996 15.0867C13.6675 14.1011 14.4359 13.3327 15.4048 12.7815C16.3737 12.2302 17.4929 11.9546 18.7624 11.9546C20.3494 11.9546 21.6857 12.3722 22.7715 13.2074C23.8573 14.026 24.5505 15.1619 24.8512 16.6152H21.4686C21.3015 16.0138 20.9674 15.5461 20.4663 15.212C19.9819 14.8612 19.4055 14.6858 18.7374 14.6858C17.852 14.6858 17.1003 15.0199 16.4823 15.6881C15.8642 16.3562 15.5551 17.2833 15.5551 18.4694C15.5551 19.6554 15.8642 20.5825 16.4823 21.2507C17.1003 21.9189 17.852 22.2529 18.7374 22.2529C19.4055 22.2529 19.9819 22.0859 20.4663 21.7518C20.9674 21.4177 21.3015 20.9416 21.4686 20.3236H24.8512C24.5505 21.7267 23.8573 22.8543 22.7715 23.7062C21.6857 24.5582 20.3494 24.9841 18.7624 24.9841Z" fill="white"/>
    </svg>
  )
}

export default CopyRightIcon
