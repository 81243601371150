import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'
import { sessionShowRequest } from '../session/actions'
import { videoUpdateCommentsCountRequest } from '../videos/actions'

function* createTip({ data }, { resource }) {
  try {
    yield request(resource, 'post', data)
    if (data.video && data.anon === false) {
      yield put(videoUpdateCommentsCountRequest(data.video))
    }
    yield put(actions.tipCreateSuccess())
    yield put(sessionShowRequest())
  } catch (e) {
    yield put(actions.tipCreateFailure(e))
  }
}

function* watchCreateTipRequest({ payload, meta }) {
  yield call(createTip, payload, meta)
}

export default function* () {
  yield takeLatest(actions.CREATE_TIP_REQUEST, watchCreateTipRequest)
}
