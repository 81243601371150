import * as actions from './actions'

const initialResourceState = {
  loading: false
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.PLATFORM_UPDATE_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.PLATFORM_UPDATE_SUCCESS:
    return {
      ...state,
      loading: false
    }

  case actions.PLATFORM_UPDATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  default:
    return state
  }
}
