import React from 'react'
import PropTypes from 'prop-types'

import NoAvatar from '../../../../no-avatar.png'
import { CommentLikeIcon, CommentLikedIcon } from '../../assets/svg'
import { history } from '../../../services'
import './styles.scss'

const CommentsModalReply = (props) => {
  const {
    id,
    avatar,
    username,
    message,
    isLiked,
    likesCount,
    commentReplySetLike
  } = props

  const avatarUrl = avatar && avatar.thumbnail.url

  return (
    <div className='comments-modal__body__reply'>
      <div
        className='comment__avatar reply--avatar'
        onClick={() => history.push(`/${username}`)}
        role='button'
        tabIndex={0}
      >
        <img alt='' src={avatarUrl || NoAvatar} />
      </div>
      <div className='comment__wrapper'>
        <div className='comment__name'>{username}</div>
        <div className='comment__message reply--message '>{message}</div>
      </div>
      <div className='comment__like reply--like'>
        {isLiked ? (
          <CommentLikedIcon onClick={() => commentReplySetLike(id)} />
        ) : (
          <CommentLikeIcon onClick={() => commentReplySetLike(id)} />
        )}
        <span>{likesCount > 0 && likesCount}</span>
      </div>
    </div>
  )
}

CommentsModalReply.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isLiked: PropTypes.bool.isRequired,
  likesCount: PropTypes.number.isRequired,
  commentReplySetLike: PropTypes.func.isRequired
}

export default CommentsModalReply
