import * as React from "react"

const ArrowRightIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        opacity={0.08}
        d="M9 18l6-6-6-6"
        stroke="#DCE8EC"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowRightIcon
