import React from 'react'
import MoonLoader from 'react-spinners/MoonLoader'

import './styles.scss'

const Loading = () => {
  return (
    <div className='loader-page'>
      <MoonLoader
        color='pink'
        size={40}
      />
    </div>
  )
}

export default Loading
