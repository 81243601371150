import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { About } from '../components/pages'
import { infoIndexRequest } from '../store/actions'
import { ActivityIndicator } from '../components/molecules'

class AboutContainer extends Component {
  componentDidMount() {
    this.props.getInfo()
  }

  onShare = async () => {
    const title = 'Qwki'
    const url = 'https://qwki.xxx'
    const text = 'Welcome to Qwki'

    try {
      await navigator.share({
        title,
        text,
        url
      })
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Not supported')
    }
  }

  render() {
    return (
      this.props.loading
        ? <ActivityIndicator />
        : <About info={this.props.info} onShare={this.onShare} />
    )
  }
}

const mapStateToProps = (state) => ({
  info: state.info.details,
  loading: state.info.loading,
  errors: state.info.errors
})

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(infoIndexRequest())
})

AboutContainer.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getInfo: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer)
