const resource = 'platform'

export const PLATFORM_UPDATE_REQUEST = 'UPDATE_PLATFORM_REQUEST'
export const PLATFORM_UPDATE_SUCCESS = 'UPDATE_PLATFORM_SUCCEEDED'
export const PLATFORM_UPDATE_FAILURE = 'UPDATE_PLATFORM_FAILED'

export const platformUpdateRequest = (data) => ({
  type: PLATFORM_UPDATE_REQUEST,
  payload: { data },
  meta: {
    resource
  }
})

export const platformUpdateSuccess = (detail) => ({
  type: PLATFORM_UPDATE_SUCCESS,
  payload: detail
})

export const platformUpdateFailure = (error) => ({
  type: PLATFORM_UPDATE_FAILURE,
  error: true,
  payload: error
})
