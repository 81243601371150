import * as React from 'react'

const LikedVideosActiveIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M19.7221 4.39106C19.2182 3.8708 18.6198 3.45809 17.9612 3.17651C17.3026 2.89493 16.5968 2.75 15.8839 2.75C15.171 2.75 14.4651 2.89493 13.8065 3.17651C13.148 3.45809 12.5496 3.8708 12.0456 4.39106L10.9997 5.47029L9.95384 4.39106C8.93587 3.34066 7.55522 2.75054 6.1156 2.75054C4.67598 2.75054 3.29532 3.34066 2.27735 4.39106C1.25939 5.44146 0.6875 6.86612 0.6875 8.35161C0.6875 9.83711 1.25939 11.2618 2.27735 12.3122L3.32325 13.3914L10.9997 21.3125L18.6762 13.3914L19.7221 12.3122C20.2263 11.7921 20.6263 11.1747 20.8992 10.4952C21.172 9.81559 21.3125 9.0872 21.3125 8.35161C21.3125 7.61602 21.172 6.88764 20.8992 6.20807C20.6263 5.52851 20.2263 4.91108 19.7221 4.39106Z'
        fill='white'
      />
    </svg>
  )
}

export default LikedVideosActiveIcon
