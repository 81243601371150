import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'

function* createPayment(payload, { resource }) {
  try {
    const detail = yield request(resource, 'post', payload)
    yield put(actions.paymentCreateSuccess(detail))
  } catch (e) {
    yield put(actions.paymentCreateFailure(e))
  }
}

function* watchCreatePaymentRequest({ payload, meta }) {
  yield call(createPayment, payload, meta)
}

export default function* () {
  yield takeLatest(actions.PAYMENT_CREATE_REQUEST, watchCreatePaymentRequest)
}
