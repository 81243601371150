import * as React from 'react'

const CommentLikedIcon = (props) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.8434 1.6935C13.4768 1.31512 13.0417 1.01497 12.5627 0.810187C12.0837 0.605403 11.5704 0.5 11.0519 0.5C10.5335 0.5 10.0201 0.605403 9.54111 0.810187C9.06215 1.01497 8.62698 1.31512 8.26046 1.6935L7.49981 2.47839L6.73916 1.6935C5.99882 0.929568 4.9947 0.500396 3.94771 0.500396C2.90071 0.500396 1.89659 0.929568 1.15626 1.6935C0.415918 2.45743 7.80073e-09 3.49354 0 4.5739C-7.80073e-09 5.65426 0.415918 6.69037 1.15626 7.4543L1.91691 8.23919L7.49981 14L13.0827 8.23919L13.8434 7.4543C14.21 7.07611 14.5009 6.62707 14.6994 6.13284C14.8979 5.63861 15 5.10888 15 4.5739C15 4.03892 14.8979 3.50919 14.6994 3.01496C14.5009 2.52073 14.21 2.07169 13.8434 1.6935Z'
        fill='#FF1C1A'
      />
    </svg>
  )
}

export default CommentLikedIcon
