import React from 'react'
import PropTypes from 'prop-types'
import emoji from 'emoji-dictionary'

import { history } from '../../../services'
import './styles.scss'

const HelloModal = (props) => {
  return (
    <div className='hello-modal-container'>
      {localStorage.getItem('userFirstVideoCreated') ? (
        <div>
          <div className='text-wrap'>
            <p className='hello-text'>Hello,</p>
            <p className='hello-text'>@{props.username}!</p>
            <div className='pink-br' />
          </div>
          <div className='content'>
            <p className='content-text'>Thanks for creating an account.<br />Your video is pending verification.</p>
            <div className='br' />
          </div>
          <div className='buttons'>
            <button
              className='button watching'
              onClick={() => {
                props.showModal(false)
                window.history.replaceState({ showHelloModal: false }, '', '/v')
                localStorage.removeItem('userFirstVideoCreated')
              }}
              type='button'
            >
              <p className='watching-text'>Ok</p>
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className='text-wrap'>
            <p className='hello-text'>Hello,</p>
            <p className='hello-text'>@{props.username}!</p>
            <div className='pink-br' />
          </div>
          <div className='content'>
            <p className='content-text'>Thanks for creating an account.<br />You can upload naked videos now.</p>
            <p className='content-text'>{emoji.getUnicode('partying')}</p>
            <div className='br' />
          </div>
          <div className='buttons'>
            <button
              className='button upload'
              onClick={() => {
                window.history.replaceState({ showHelloModal: false }, '', '/v')
                history.push('/rules')
                props.showModal(false)
                localStorage.removeItem('userFirstVideoCreated')
              }}
              type='button'
            >
              <p className='upload-text'>Upload</p>
            </button>
            <button
              className='button watching'
              onClick={() => {
                props.showModal(false)
                window.history.replaceState({ showHelloModal: false }, '', '/v')
                localStorage.removeItem('userFirstVideoCreated')
              }}
              type='button'
            >
              <p className='watching-text'>Keep watching</p>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

HelloModal.propTypes = ({
  username: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired
})

export default HelloModal
