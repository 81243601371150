const resource = 'ads_scripts'

export const SCRIPTS_INDEX_REQUEST = 'SCRIPTS_INDEX_REQUEST'
export const SCRIPTS_INDEX_SUCCESS = 'SCRIPTS_INDEX_SUCCESS'
export const SCRIPTS_INDEX_FAILURE = 'SCRIPTS_INDEX_FAILURE'
export const SCRIPTS_SEND_REQUEST = 'SCRIPTS_SEND_REQUEST'
export const SCRIPTS_SEND_SUCCESS = 'SCRIPTS_SEND_SUCCESS'
export const SCRIPTS_SEND_FAILURE = 'SCRIPTS_SEND_FAILURE'

export const scriptsIndexRequest = () => ({
  type: SCRIPTS_INDEX_REQUEST,
  meta: {
    resource
  }
})

export const scriptsIndexSuccess = (detail) => ({
  type: SCRIPTS_INDEX_SUCCESS,
  payload: detail
})

export const scriptsIndexFailure = (error) => ({
  type: SCRIPTS_INDEX_FAILURE,
  error: true,
  payload: error
})

export const RESET_SCRIPTS_STATE = 'RESET_AD_STATE'

export const resetScriptsState = () => ({
  type: RESET_SCRIPTS_STATE
})
