import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'
import { paymentCreateRequest, resetPaymentState } from '../payments/actions'

function* getCards({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.cardsIndexSuccess(detail))
    yield put(resetPaymentState())
  } catch (e) {
    yield put(actions.cardsIndexFailure(e))
  }
}

function* addCard(data, { resource }) {
  try {
    const detail = yield request(resource, 'post', data)
    const sendData = { pack_id: data.get('pack_id'), card_id: detail.card.id }
    yield put(actions.addCardSuccess(detail))
    yield put(paymentCreateRequest(sendData))
  } catch (e) {
    yield put(actions.addCardFailure(e))
  }
}

function* watchGetCardsRequest({ meta }) {
  yield call(getCards, meta)
}

function* watchAddCardRequest({ payload, meta }) {
  yield call(addCard, payload, meta)
}

export default function* () {
  yield takeLatest(actions.GET_CARDS_REQUEST, watchGetCardsRequest)
  yield takeLatest(actions.ADD_CARD_REQUEST, watchAddCardRequest)
}
