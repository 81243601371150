import * as React from 'react'

const RefreshIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23 4V10H17" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1 20V14H7" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.51 9.33494C4.01717 7.82171 4.87913 6.46878 6.01547 5.40241C7.1518 4.33603 8.52547 3.59096 10.0083 3.23672C11.4911 2.88248 13.0348 2.93062 14.4952 3.37664C15.9556 3.82266 17.2853 4.65202 18.36 5.78734L23 10.3908M1 14.6141L5.64 19.2175C6.71475 20.3529 8.04437 21.1822 9.50481 21.6282C10.9652 22.0743 12.5089 22.1224 13.9917 21.7682C15.4745 21.4139 16.8482 20.6689 17.9845 19.6025C19.1209 18.5361 19.9828 17.1832 20.49 15.6699" stroke="#FF0000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default RefreshIcon
