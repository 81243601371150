import { put, call, takeLatest } from 'redux-saga/effects'

import { history, request } from '../../services'
import * as actions from './actions'

function* indexScripts({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.scriptsIndexSuccess(detail))
  } catch (e) {
    yield put(actions.scriptsIndexFailure(e))
  }
}

function* watchScriptsIndexRequest({ meta }) {
  yield call(indexScripts, meta, history)
}

export default function* () {
  yield takeLatest(actions.SCRIPTS_INDEX_REQUEST, watchScriptsIndexRequest)
}
