import * as React from "react"

const EyeOnIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <g
        clipPath={`url(${window.location.pathname}#prefix__clip1)`}
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M.667 8S3.334 2.667 8 2.667C12.667 2.667 15.334 8 15.334 8S12.667 13.334 8 13.334C3.334 13.334.667 8 .667 8z" />
        <path d="M8 10a2 2 0 100-4 2 2 0 000 4z" />
      </g>
      <defs>
        <clipPath id="prefix__clip1">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EyeOnIcon
