import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import { history, request } from '../../services'
import * as actions from './actions'
import { videoUpdateCommentsCountRequest } from '../videos/actions'

function* indexComments({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.commentsIndexSuccess(detail))
  } catch (e) {
    yield put(actions.commentsIndexFailure(e))
  }
}

function* likeComment({ resource }) {
  try {
    const detail = yield request(resource, 'put')
    yield put(actions.commentLikeSuccess(detail))
  } catch (e) {
    yield put(actions.commentLikeFailure(e))
  }
}

function* likeCommentReply({ resource }) {
  try {
    const detail = yield request(resource, 'put')
    yield put(actions.commentReplyLikeSuccess(detail))
  } catch (e) {
    yield put(actions.commentReplyLikeFailure(e))
  }
}

function* createComment({ data, id }, { resource }) {
  try {
    const detail = yield request(resource, 'post', data)
    yield put(actions.commentCreateSuccess(detail))
    yield put(videoUpdateCommentsCountRequest(id))
  } catch (e) {
    yield put(actions.commentCreateFailure(e))
  }
}

function* watchCommentsIndexRequest({ meta }) {
  yield call(indexComments, meta, history)
}

function* watchLikeCommentRequest({ meta }) {
  yield call(likeComment, meta, history)
}

function* watchLikeCommentReplyRequest({ meta }) {
  yield call(likeCommentReply, meta, history)
}

function* watchCreateCommentRequest({ payload, meta }) {
  yield call(createComment, payload, meta, history)
}

export default function* () {
  yield takeEvery(actions.COMMENTS_INDEX_REQUEST, watchCommentsIndexRequest)
  yield takeLatest(actions.CREATE_COMMENT_REQUEST, watchCreateCommentRequest)
  yield takeLatest(actions.COMMENT_LIKE_REQUEST, watchLikeCommentRequest)
  yield takeLatest(
    actions.COMMENT_REPLY_LIKE_REQUEST,
    watchLikeCommentReplyRequest
  )
}
