import * as React from 'react'

const TwitterIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23 3.00999C22.0424 3.68547 20.9821 4.2021 19.86 4.53999C19.2577 3.84751 18.4573 3.35669 17.567 3.13392C16.6767 2.91116 15.7395 2.96719 14.8821 3.29445C14.0247 3.62171 13.2884 4.2044 12.773 4.96371C12.2575 5.72303 11.9877 6.62233 12 7.53999V8.53999C10.2426 8.58556 8.50127 8.19581 6.93101 7.40544C5.36074 6.61507 4.01032 5.44863 3 4.00999C3 4.00999 -1 13.01 8 17.01C5.94053 18.408 3.48716 19.1089 1 19.01C10 24.01 21 19.01 21 7.50999C20.9991 7.23145 20.9723 6.95359 20.92 6.67999C21.9406 5.67349 22.6608 4.40271 23 3.00999V3.00999Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default TwitterIcon
