/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes, { instanceOf } from 'prop-types'
import ReactGA from 'react-ga'
import { withCookies, Cookies } from 'react-cookie'
import postscribe from 'postscribe'

import './styles.scss'
import { historyRefresh } from '../../../services'
import { QwkiCategoriesLogo } from '../../assets/svg'
import { categoriesSendRequest } from '../../../store/categories/actions'
import { useScrollBlock } from '../../../hooks/blockScroll'
import { isDev } from '../../../lib/environment'
import DevDefaultGif from '../../assets/dev-default.gif'

const Categories = props => {
  const [currentCategory, setCurrentCategory] = useState(
    (props.usersCategories.length && props.usersCategories.slice(-1)[0].attributes.image) || props.defaultCategoriesGif
  )
  const [chosenCategories, setChosenCategories] = useState(props.usersCategories.map(item => item.attributes.id))
  const dispatch = useDispatch()

  useScrollBlock()

  useEffect(() => {
    props.cookies.set('counterAds', 0, { path: '/' })
  }, [props.cookies])

  useEffect(() => {
    localStorage.removeItem('userFirstVideoCreated')
    localStorage.removeItem('currentVideoId')
    const gifs = props.categories.map((item) => {
      return item.attributes.image
    })
    gifs.push(props.defaultCategoriesGif)

    gifs.forEach((image) => {
      new Image().src = image
    })
  })

  useEffect(() => {
    if (props.scripts.length > 0) {
      props.scripts.map(script => {
        const scriptExists = document.getElementById(`qdf${script.id}`)
        if (scriptExists) {
          return null
        } else {
          return (
            postscribe('#ad-scripts', `<div id=qdf${script.id}>${script.code}</div>`)
          )
        }
      })
    }
  }, [props.scripts])

  const chooseCategory = (categoryId) => {
    let lastGif = 0
    chosenCategories.indexOf(categoryId) !== chosenCategories.length - 1
      ? lastGif = -1
      : lastGif = -2

    setCurrentCategory(
      chosenCategories.includes(categoryId)
        ? props.categories.filter(item => {
          return item.attributes.id === chosenCategories.slice(lastGif)[0]
        })[0].attributes.image
        : props.categories.filter(item => item.attributes.id === categoryId)[0].attributes.image
    )

    setChosenCategories(
      chosenCategories.includes(categoryId)
        ? chosenCategories.filter(item => item !== categoryId)
        : [...chosenCategories, categoryId]
    )
  }

  const renderListCategories = () => {
    return props.categories.map(item => {
      return (
        <a
          key={item.id}
          className={`category category-item ${
            chosenCategories.includes(item.attributes.id) && 'category-active'
          } homeCtgBtn`}
          onClick={() => chooseCategory(item.attributes.id)}
          role='button'
          tabIndex='0'
        >
          {item.attributes.title}
        </a>
      )
    })
  }

  const sendData = () => {
    ReactGA.event({
      category: 'Enter button',
      action: 'Clicked Enter'
    })
    chosenCategories.map(item => (
      props.categories.find(category => {
        return parseInt(category.id) === item &&
          ReactGA.event({
            category: 'Category selection',
            action: `Selected category - ${category.attributes.title}`
          })
      })
    ))
    dispatch(categoriesSendRequest(chosenCategories))
  }

  setTimeout(() => {
    document.getElementById('categories-container').style.visibility = 'visible'
    document.getElementById('logo-container').style.visibility = 'visible'
  }, 900)

  setTimeout(() => {
    document.getElementById('bottom-rules').style.visibility = 'visible'
    document.getElementById('title-top').style.visibility = 'visible'
  }, 2100)

  const backgroundImage = isDev
    ? `url(${DevDefaultGif})`
    : `url(${chosenCategories.length ? currentCategory : props.defaultCategoriesGif})`

  return (
    <div
      className='container' style={{
        backgroundImage
      }}
    >
      <div className='top-gradient'>
        <div className='title-container'>
          <div className='title-top-wrap'>
            <p className='title-top' id='title-top'>Free amateur porn made to fit your phone</p>
          </div>
        </div>
        <div className='logo-container' id='logo-container'>
          <QwkiCategoriesLogo className='qwki-logo' />
          <div className='title-block'>
            <p className='title'>
              Let’s have a Qwki.
            </p>
            <p className='title second'>
              What are you into?
            </p>
          </div>
        </div>
        <div className='categories-container' id='categories-container'>
          <div className='categories-block' id='categories-block'>
            {renderListCategories()}
          </div>
          <div
            className='bottom-container'
          >
            <a
              className={`categories-buttom 
              ${chosenCategories.length > 0 ? 'button-show' : 'button-hide'} homeContinueBtn`}
              disabled={!(chosenCategories.length > 0)}
              onClick={() => {
                chosenCategories.length > 0 && sendData()
              }}
              type='button'
            >
              <p className='categories-swipe-text'>Continue</p>
            </a>
            <div className='bottom-rules' id='bottom-rules'>
              <p className='bottom-rules-text'>You must be 18+ years of age to enter.</p>
              <p className='bottom-rules-text'>
                See Qwki&apos;s full&nbsp;
                <button
                  className='rules-link'
                  onClick={
                    () => historyRefresh.push(
                      '/quill',
                      { title: 'Terms of Use', data: props.info.terms_of_use, modal: true }
                    )
                  }
                  type='button'
                >Terms of Use
                </button> and&nbsp;
                <button
                  className='rules-link'
                  onClick={
                    () => historyRefresh.push(
                      '/quill',
                      { title: 'Privacy Policy', data: props.info.privacy_policy, modal: true }
                    )
                  }
                  type='button'
                >Privacy Policy.
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
  scripts: PropTypes.array.isRequired,
  usersCategories: PropTypes.array.isRequired,
  defaultCategoriesGif: PropTypes.string,
  info: PropTypes.shape({
    privacy_policy: PropTypes.string.isRequired,
    terms_of_use: PropTypes.string.isRequired
  }).isRequired,
  cookies: instanceOf(Cookies).isRequired
}

export default memo(withCookies(Categories))
