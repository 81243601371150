import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MoonLoader from 'react-spinners/MoonLoader'

import PurchaseCards from './cards'
import PurchaseForm from './form'
import { PaymentModal } from '../../molecules'
import { history } from '../../../services'
import { MasterIcon, VisaIcon, DiscIcon, AmexIcon } from '../../assets/svg'
import './styles.scss'

const Purchase = (props) => {
	const {
		pack,
		packLoading,
		cards,
		cardsLoading,
		cardsError,
		addCard,
		createPayment,
		resetPaymentState,
		paymentLoading,
		paymentOpen,
	} = props
	const [isHaveCards, setHaveCards] = useState(false)
	const [isFormOpen, setFormOpen] = useState(true)

	useEffect(() => {
		if (cards.length) {
			setHaveCards(true)
			setFormOpen(false)
		}
	}, [cards])

	const handleBack = () => {
		const videoId = localStorage.getItem('currentVideoId')
		if (videoId) {
			history.push(`/v/${videoId}`)
		} else {
			history.push('/v')
		}
	}

	return (
		<div className='purchase'>
			{cardsLoading || packLoading ? (
				<div className='purchase__loader'>
					<MoonLoader color='#9a9a9a' size={90} />
				</div>
			) : null}
			{}
			{!cardsLoading && !packLoading && pack.title && (
				<>
					<div className='purchase__content__wrapper'>
						<PaymentModal
							error={props.paymentError}
							loading={props.paymentLoading}
							message={props.paymentMessage}
							onBack={handleBack}
							onClose={resetPaymentState}
							show={paymentOpen}
						/>
						<div className='purchase__title'>
							<span>Secure Purchase</span>
							<span className='cards'>
								<VisaIcon />
								<MasterIcon />
								<AmexIcon />
								<DiscIcon />
							</span>
						</div>
						<div className='purchase__pack'>
							<div className='purchase__pack__info'>
								<span>{pack.tokens_amount} tokens</span>
								<span>$ {pack.price}</span>
							</div>
							<span className='purchase__pack__title'>{pack.title}</span>
						</div>
						<div className='purchase__title purchase__title--m0'>
							<span>Card Details</span>
							{isHaveCards && (
								<span
									className='purchase__title__button'
									onClick={() => setFormOpen(!isFormOpen)}
									role='button'
									tabIndex={0}
								>
									{isFormOpen ? 'Back' : '+'}
								</span>
							)}
						</div>
					</div>
					{isFormOpen ? (
						<PurchaseForm
							addCard={addCard}
							cardsError={cardsError}
							cardsLoading={cardsLoading}
							pack={pack}
							packLoading={packLoading}
						/>
					) : (
						<PurchaseCards
							cards={cards}
							createPayment={createPayment}
							handleBack={handleBack}
							pack={pack}
							paymentLoading={paymentLoading}
						/>
					)}
				</>
			)}
		</div>
	)
}

Purchase.propTypes = {
	// currentUser: PropTypes.object,
	// currentGuest: PropTypes.object,
	addCard: PropTypes.func.isRequired,
	createPayment: PropTypes.func.isRequired,
	resetPaymentState: PropTypes.func.isRequired,
	pack: PropTypes.object.isRequired,
	packLoading: PropTypes.bool.isRequired,
	cards: PropTypes.array.isRequired,
	cardsLoading: PropTypes.bool.isRequired,
	cardsError: PropTypes.object.isRequired,
	paymentLoading: PropTypes.bool.isRequired,
	paymentOpen: PropTypes.bool.isRequired,
	paymentError: PropTypes.string,
	paymentMessage: PropTypes.string,
}

export default Purchase
