const resource = 'company_info'

export const INFO_INDEX_REQUEST = 'INDEX_INFO_REQUEST'
export const INFO_INDEX_SUCCESS = 'INDEX_INFO_SUCCEEDED'
export const INFO_INDEX_FAILURE = 'INDEX_INFO_FAILED'

export const infoIndexRequest = () => ({
  type: INFO_INDEX_REQUEST,
  meta: {
    resource
  }
})

export const infoIndexSuccess = (detail) => ({
  type: INFO_INDEX_SUCCESS,
  payload: detail
})

export const infoIndexFailure = (error) => ({
  type: INFO_INDEX_FAILURE,
  error: true,
  payload: error
})
