import * as React from 'react'

const VideoIcon = (props) => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip3)">
      <path d="M34.5001 11L24.0001 18.5L34.5001 26V11Z" stroke="white" strokeWidth="3.33818" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.0001 8H4.50012C2.84327 8 1.50012 9.34315 1.50012 11V26C1.50012 27.6569 2.84327 29 4.50012 29H21.0001C22.657 29 24.0001 27.6569 24.0001 26V11C24.0001 9.34315 22.657 8 21.0001 8Z" stroke="white" strokeWidth="3.33818" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip3">
      <rect y="0.5" width="36" height="36" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default VideoIcon
