import * as React from 'react'

const MoneyIcon = (props) => {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.6795 3.3205L15.6795 3.32052C17.3392 4.98017 18.25 7.17277 18.25 9.5C18.25 11.8272 17.3392 14.0198 15.6795 15.6795L15.6795 15.6795C14.0198 17.3392 11.8272 18.25 9.5 18.25C7.17277 18.25 4.98017 17.3392 3.32052 15.6795L3.3205 15.6795C1.66082 14.0198 0.75 11.8272 0.75 9.5C0.75 7.17277 1.66082 4.98017 3.3205 3.32052L3.32052 3.3205C4.98017 1.66082 7.17277 0.75 9.5 0.75C11.8272 0.75 14.0198 1.66082 15.6795 3.3205Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M11.928 11.7455C11.928 11.7455 11.9279 11.7456 11.9279 11.7456H7.46143C7.46139 11.7456 7.46135 11.7455 7.46134 11.7455L6.56379 8.79295C6.56376 8.79287 6.56385 8.79279 6.56393 8.79283L7.8255 9.40701C7.95079 9.46799 8.10133 9.43719 8.1926 9.33197L9.69461 7.60018C9.69465 7.60014 9.69472 7.60014 9.69476 7.60018L11.1968 9.33197C11.288 9.4372 11.4386 9.46797 11.5639 9.40701L12.8254 8.79283C12.8255 8.79279 12.8256 8.79287 12.8256 8.79295L11.928 11.7455Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </svg>
  )
}

export default MoneyIcon
