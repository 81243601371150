import * as React from 'react'

const LikedVideosIcon = (props) => {
  return (
    <svg
      width='24'
      height='23'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20.7221 4.39106C20.2182 3.8708 19.6198 3.45809 18.9612 3.17651C18.3026 2.89493 17.5968 2.75 16.8839 2.75C16.171 2.75 15.4651 2.89493 14.8065 3.17651C14.148 3.45809 13.5496 3.8708 13.0456 4.39106L11.9997 5.47029L10.9538 4.39106C9.93587 3.34066 8.55522 2.75054 7.1156 2.75054C5.67598 2.75054 4.29532 3.34066 3.27735 4.39106C2.25939 5.44146 1.6875 6.86612 1.6875 8.35161C1.6875 9.83711 2.25939 11.2618 3.27735 12.3122L4.32325 13.3914L11.9997 21.3125L19.6762 13.3914L20.7221 12.3122C21.2263 11.7921 21.6263 11.1747 21.8992 10.4952C22.172 9.81559 22.3125 9.0872 22.3125 8.35161C22.3125 7.61602 22.172 6.88764 21.8992 6.20807C21.6263 5.52851 21.2263 4.91108 20.7221 4.39106V4.39106Z'
        stroke='#9A9A9A'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LikedVideosIcon
