export const COMMENTS_INDEX_REQUEST = 'COMMENTS_INDEX_REQUEST'
export const COMMENTS_INDEX_SUCCESS = 'COMMENTS_INDEX_SUCCESS'
export const COMMENTS_INDEX_FAILURE = 'COMMENTS_INDEX_FAILURE'

export const commentsIndexRequest = (id, page) => ({
  type: COMMENTS_INDEX_REQUEST,
  meta: {
    resource: `comments?video_id=${id}&page=${page}`
  }
})

export const commentsIndexSuccess = (detail) => ({
  type: COMMENTS_INDEX_SUCCESS,
  payload: detail
})

export const commentsIndexFailure = (error) => ({
  type: COMMENTS_INDEX_FAILURE,
  error: true,
  payload: error
})

export const COMMENTS_CLEAR_REQUEST = 'COMMENTS_CLEAR_REQUEST'

export const commentsClearRequest = () => ({
  type: COMMENTS_CLEAR_REQUEST
})

export const COMMENT_LIKE_REQUEST = 'COMMENT_LIKE_REQUEST'
export const COMMENT_LIKE_SUCCESS = 'COMMENT_LIKE_SUCCESS'
export const COMMENT_LIKE_FAILURE = 'COMMENT_LIKE_FAILURE'

export const commentLikeRequest = (detail) => ({
  type: COMMENT_LIKE_REQUEST,
  meta: {
    resource: `comments/${detail}/likes`
  }
})

export const commentLikeSuccess = (detail) => ({
  type: COMMENT_LIKE_SUCCESS,
  payload: detail
})

export const commentLikeFailure = (error) => ({
  type: COMMENT_LIKE_FAILURE,
  error: true,
  payload: error
})

export const COMMENT_REPLY_LIKE_REQUEST = 'COMMENT_REPLY_LIKE_REQUEST'
export const COMMENT_REPLY_LIKE_SUCCESS = 'COMMENT_REPLY_LIKE_SUCCESS'
export const COMMENT_REPLY_LIKE_FAILURE = 'COMMENT_REPLY_LIKE_FAILURE'

export const commentReplyLikeRequest = (detail) => ({
  type: COMMENT_REPLY_LIKE_REQUEST,
  meta: {
    resource: `comments/${detail}/likes`
  }
})

export const commentReplyLikeSuccess = (detail) => ({
  type: COMMENT_REPLY_LIKE_SUCCESS,
  payload: detail
})

export const commentReplyLikeFailure = (error) => ({
  type: COMMENT_REPLY_LIKE_FAILURE,
  error: true,
  payload: error
})

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST'
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE'

export const commentCreateRequest = (id, data) => ({
  type: CREATE_COMMENT_REQUEST,
  payload: { data, id },
  meta: {
    resource: `videos/${id}/comments`
  }
})

export const commentCreateSuccess = (detail) => ({
  type: CREATE_COMMENT_SUCCESS,
  payload: detail
})

export const commentCreateFailure = (error) => ({
  type: CREATE_COMMENT_FAILURE,
  error: true,
  payload: error
})
