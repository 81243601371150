export const VIDEOS_INDEX_REQUEST = 'INDEX_VIDEOS_REQUEST'
export const VIDEOS_INDEX_SUCCESS = 'INDEX_VIDEOS_SUCCEEDED'
export const VIDEOS_INDEX_FAILURE = 'INDEX_VIDEOS_FAILED'
export const USER_VIDEOS_REQUEST = 'USER_VIDEOS_REQUEST'
export const USER_VIDEOS_SUCCESS = 'USER_VIDEOS_SUCCESS'

export const videosIndexRequest = (detail) => ({
  type: VIDEOS_INDEX_REQUEST,
  meta: {
    resource: `videos?page=${detail}`
  }
})

export const userVideosRequest = (detail, type, id) => ({
  type: USER_VIDEOS_REQUEST,
  meta: {
    resource: `videos?username=${detail}&type=${type}&id=${id}`
  }
})

export const userVideosSuccess = (detail) => ({
  type: USER_VIDEOS_SUCCESS,
  payload: detail
})

export const videosIndexSuccess = (detail) => ({
  type: VIDEOS_INDEX_SUCCESS,
  payload: detail
})

export const videosIndexFailure = (error) => ({
  type: VIDEOS_INDEX_FAILURE,
  error: true,
  payload: error
})

export const SET_VIEW_REQUEST = 'SET_VIEW_REQUEST'
export const SET_VIEW_SUCCESS = 'SET_VIEW_SUCCESS'
export const SET_VIEW_FAILURE = 'SET_VIEW_FAILED'

export const setViewRequest = (detail) => ({
  type: SET_VIEW_REQUEST,
  meta: {
    resource: `videos/${detail}/views`
  }
})

export const setViewSuccess = (detail) => ({
  type: SET_VIEW_SUCCESS,
  payload: detail
})

export const setViewFailure = (error) => ({
  type: SET_VIEW_FAILURE,
  error: true,
  payload: error
})

export const SET_LIKE_REQUEST = 'SET_LIKE_REQUEST'
export const SET_LIKE_SUCCESS = 'SET_LIKE_SUCCEEDED'
export const SET_LIKE_FAILURE = 'SET_LIKE_FAILED'

export const setLikeRequest = (detail) => ({
  type: SET_LIKE_REQUEST,
  meta: {
    resource: `videos/${detail}/likes`
  }
})

export const setLikeSuccess = (detail) => ({
  type: SET_LIKE_SUCCESS,
  payload: detail
})

export const setLikeFailure = (error) => ({
  type: SET_LIKE_FAILURE,
  error: true,
  payload: error
})

export const VIDEO_INDEX_REQUEST = 'INDEX_VIDEO_REQUEST'
export const VIDEO_INDEX_SUCCESS = 'INDEX_VIDEO_SUCCEEDED'
export const VIDEO_INDEX_FAILURE = 'INDEX_VIDEO_FAILED'

export const videoIndexRequest = (detail) => ({
  type: VIDEO_INDEX_REQUEST,
  meta: {
    resource: `videos/${detail}`
  }
})

export const videoIndexSuccess = (detail) => ({
  type: VIDEO_INDEX_SUCCESS,
  payload: detail
})

export const videoIndexFailure = (error) => ({
  type: VIDEO_INDEX_FAILURE,
  error: true,
  payload: error
})

export const VIDEO_UPDATE_COMMENTS_COUNT_REQUEST = 'VIDEO_UPDATE_COMMENTS_COUNT_REQUEST'

export const videoUpdateCommentsCountRequest = (id) => ({
  type: VIDEO_UPDATE_COMMENTS_COUNT_REQUEST,
  payload: id
})

export const RESET_VIDEO_STATE = 'RESET_VIDEO_STATE'

export const resetVideoState = () => ({
  type: RESET_VIDEO_STATE
})

export const RESET_VIDEO_CREATED_STATE = 'RESET_VIDEO_CREATED_STATE'

export const resetVideoCreatedState = () => ({
  type: RESET_VIDEO_CREATED_STATE
})

export const VIDEO_CREATE_INDEX_SUCCESS = 'INDEX_VIDEO_CREATE_SUCCEEDED'
export const VIDEO_CREATE_INDEX_FAILURE = 'INDEX_VIDEO_CREATE_FAILED'

export const videoIndexCreateSuccess = (detail) => ({
  type: VIDEO_CREATE_INDEX_SUCCESS,
  payload: detail
})

export const videoIndexCreateFailure = (error) => ({
  type: VIDEO_CREATE_INDEX_FAILURE,
  error: true,
  payload: error
})

export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST'
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCEEDED'
export const VIDEO_UPDATE_FAILURE = 'VIDEO_UPDATE_FAILED'

export const videoUpdateRequest = (id, payload) => ({
  type: VIDEO_UPDATE_REQUEST,
  meta: {
    resource: `videos/${id}`
  },
  payload
})

export const videoUpdateSuccess = (detail) => ({
  type: VIDEO_UPDATE_SUCCESS,
  payload: detail
})

export const videoUpdateFailure = (error) => ({
  type: VIDEO_UPDATE_FAILURE,
  error: true,
  payload: error
})

export const REMOVE_VIDEO = 'REMOVE_VIDEO'
export const REMOVE_VIDEO_SUCCESS = 'REMOVE_VIDEO_SUCCEEDED'
export const REMOVE_VIDEO_FAILURE = 'REMOVE_VIDEO_FAILED'

export const removeVideo = (id) => ({
  type: REMOVE_VIDEO,
  meta: {
    resource: `videos/${id}`
  }
})

export const removeVideoSuccess = () => ({
  type: REMOVE_VIDEO_SUCCESS
})

export const removeVideoFailure = (error) => ({
  type: REMOVE_VIDEO_FAILURE,
  error: true,
  payload: error
})

