import * as actions from './actions'

const initialResourceState = {
  loading: false,
  currentGuest: {},
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.GUEST_CREATE_REQUEST:
    return {
      ...state,
      loading: true,
      errors: {}
    }

  case actions.GUEST_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      currentGuest: payload.data.attributes
    }

  case actions.GUEST_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload.response.data
    }

  case actions.GUEST_RESET_STATE:
    return {
      ...state,
      loading: false,
      currentGuest: {},
      errors: {}
    }

  default:
    return state
  }
}
