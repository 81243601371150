/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import isEmpty from 'lodash.isempty'
import ReactGA from 'react-ga'
import { TwitterShareButton } from 'react-share'
import { isIOS } from 'react-device-detect'

import { history } from '../../../services'
import {
  SMSIcon,
  CopyLinkIcon,
  EmailIcon,
  TwitterIcon,
  AboutUsStaffIcon
} from '../../assets/svg'
import './styles.scss'

const ProfileShareModal = (props) => {
  const {
    isOpen,
    isProfile,
    onRequestClose,
    videos,
    index,
    profile,
    onDeleteSession,
    currentUser,
    currentGuest
  } = props
  const [copyLinkMessage, setCopyLinkMessage] = useState('hide')
  const copyUrl = isProfile ? process.env.SHARE_URL : `${process.env.SHARE_URL}/v`
  const identifier = isProfile ? profile.username : 15000 + parseInt(videos[index].id)
  const username = isProfile ? profile.username : videos[index].attributes.user.username
  const description = isProfile ? 'Profile' : videos[index].attributes.description

  const copyLink = () => {
    ReactGA.event({
      category: 'Copy button',
      action: 'Clicked copy'
    })
    const textArea = document.createElement('textArea')

    textArea.readOnly = true
    textArea.contentEditable = true
    textArea.value = `${copyUrl}/${identifier}`
    document.body.appendChild(textArea)

    if (isIOS) {
      const range = document.createRange()

      range.selectNodeContents(textArea)
      const selection = window.getSelection()

      selection.removeAllRanges()
      selection.addRange(range)
      textArea.setSelectionRange(0, 999999)
    } else {
      textArea.select()
    }
    document.execCommand('copy')
    document.body.removeChild(textArea)
    setCopyLinkMessage('show')
    setTimeout(() => {
      setCopyLinkMessage('hide')
    }, 3000)
  }
  return (
    <Modal
      className='Profile-Share-Modal'
      contentLabel='onRequestClose Example'
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='Overlay'
    >
      <div className='modal-share-stuff'>
        <p className='modal-title'>Share & stuff</p>
        <div className={`link-copied-block ${copyLinkMessage}`}>
          Link copied to clipboard
        </div>
        <div
          className='modal-buttons-block'
          style={{ justifyContent: 'center' }}
        >
          <a
            className='modal-buttons first-button shareItemBtn'
            href={`sms:${isIOS ? '&' : '?'}body=${copyUrl}/${identifier}`}
            onClick={() => {
              ReactGA.event({
                category: 'SMS button',
                action: 'Clicked Share via sms'
              })
            }}
          >
            <div className='modal-svg-wrapper sms-wrapper'>
              <SMSIcon />
            </div>
            <p className='modal-svg-title'>SMS</p>
          </a>
          <a
            className='modal-buttons shareItemBtn'
            onClick={() => copyLink()}
            role='button'
            tabIndex='0'
          >
            <div className='modal-svg-wrapper link-wrapper'>
              <CopyLinkIcon />
            </div>
            <p className='modal-svg-title'>Copy Link</p>
          </a>
          <a
            className='modal-buttons mail-button shareItemBtn'
            href={`mailto:?body=Check out @${username}'s 
            video! #qwki %0D%0A%0D%0A${copyUrl}/${identifier}&subject=@${username} on Qwki`}
            onClick={() => {
              ReactGA.event({
                category: 'Email button',
                action: 'Clicked Share via email'
              })
            }}
          >
            <div className='modal-svg-wrapper email-wrapper'>
              <EmailIcon />
            </div>
            <p className='modal-svg-title'>Email</p>
          </a>
          <a className='modal-buttons shareItemBtn'>
            <TwitterShareButton
              title={`Watch ${username}'s Qwki - ${description} @QwkiXXX #amateurporn #FlauntStareSwipe`}
              url={`${copyUrl}/${identifier}`}
            >
              <div className='modal-svg-wrapper twitter-wrapper'>
                <TwitterIcon
                  onClick={() => {
                    ReactGA.event({
                      category: 'Twitter button',
                      action: 'Clicked Share via twitter'
                    })
                  }}
                />
              </div>
              <p className='modal-svg-title'>Twitter</p>
            </TwitterShareButton>
          </a>
          <a className='modal-buttons shareItemBtn'>
            <div className='modal-svg-wrapper fullscreen-wrapper-about'>
              <AboutUsStaffIcon
                onClick={() => {
                  ReactGA.event({
                    category: 'About us button',
                    action: 'Clicked about us'
                  })
                  history.push('/about')
                }}
              />
            </div>
            <p className='modal-svg-title'>About Us </p>
          </a>
        </div>
      </div>
      {isEmpty(currentUser) && !isEmpty(currentGuest) ? (
        <div className='modal-login'>
          <button
            className='auth-button'
            onClick={() => history.push('/sign_up')}
            type='button'
          >
            <p className='modal-login-buttons'>Create an account</p>
          </button>
          <p className='modal-login-separator'>|</p>
          <button
            className='auth-button'
            onClick={() => history.push('/sign_in')}
            type='button'
          >
            <p className='modal-login-buttons'>Login</p>
          </button>
        </div>
      ) : (
        <a className='modal-login shareProfileBtn'>
          <button className='auth-button' type='button'>
            <p>@{currentUser.username}</p>
          </button>
          <p className='modal-login-separator'>|</p>
          <button
            className='auth-button'
            onClick={() => onDeleteSession()}
            type='button'
          >
            <p>Logout</p>
          </button>
        </a>
      )}
    </Modal>
  )
}

ProfileShareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isProfile: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  videos: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  profile: PropTypes.object,
  onDeleteSession: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGuest: PropTypes.object.isRequired
}

export default ProfileShareModal
