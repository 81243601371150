import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

export const useDocumentBackground = color => {
  useEffect(() => {
    const body = document.querySelector('body')
    body.style.backgroundColor = isMobile && color
    return () => body.style = null
  }, [])
}
