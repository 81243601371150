import React from 'react'
import Loading from '../../assets/loadingblack.gif'

import './styles.scss'

const ActivityIndicator = () => {
  return (
    <div className='sentry-wrap'>
      <img alt='loading' src={Loading} />
    </div>
  )
}

export default ActivityIndicator
