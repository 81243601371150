import * as actions from './actions'

const initialResourceState = {
  loading: false,
  userCreated: false
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.USER_CREATE_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.USER_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      userCreated: true
    }

  case actions.USER_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  default:
    return state
  }
}
