import * as React from 'react'

const MasterType = (props) => {
  return (
    <svg
      width='44'
      height='28'
      viewBox='0 0 44 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.7'
        d='M16.426 14.4617C16.426 10.1533 18.4109 6.31545 21.5017 3.84215C19.1621 1.96723 16.2684 0.949642 13.2889 0.954116C5.94948 0.954116 0 7.00157 0 14.4617C0 21.9218 5.94948 27.9693 13.2889 27.9693C16.2685 27.9738 19.1623 26.9562 21.5019 25.0812C18.4113 22.6084 16.426 18.7702 16.426 14.4617Z'
        fill='white'
      />
      <path
        opacity='0.4'
        d='M43.0056 14.4627C43.0056 21.9228 37.056 27.9702 29.7166 27.9702C26.7367 27.9747 23.8426 26.9571 21.5024 25.0822C24.5942 22.6089 26.579 18.7712 26.579 14.4627C26.579 10.1541 24.5942 6.31643 21.5024 3.84313C23.8425 1.96826 26.7366 0.950697 29.7163 0.955092C37.0558 0.955092 43.0053 7.00255 43.0053 14.4627'
        fill='white'
      />
      <path
        d='M21.5006 25.0818H21.5026C21.5026 25.0818 21.5027 25.0819 21.5026 25.0818C24.5943 22.6085 26.5791 18.7707 26.5791 14.4622C26.5791 10.1537 24.5943 6.31608 21.5026 3.84277H21.5004C18.4103 6.31611 16.426 10.1535 16.426 14.4612C16.426 18.7698 18.4113 22.6079 21.502 25.0807C21.5015 25.0811 21.501 25.0815 21.5006 25.0818Z'
        fill='white'
      />
    </svg>
  )
}

export default MasterType
