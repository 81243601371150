import * as React from 'react'

const LogInIcon = (props) => {
  return (
    <svg width={16} height={17} viewBox='0 0 16 17' fill='none' {...props}>
      <path
        d='M10 2.5h2.667A1.333 1.333 0 0114 3.833v9.334a1.334 1.334 0 01-1.333 1.333H10M6.667 11.833L10 8.5 6.667 5.167M10 8.5H2'
        stroke='#fff'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LogInIcon
