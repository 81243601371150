import * as React from 'react'

const CoinIcon = (props) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.0031 2.99688L15.0031 2.99689C16.6146 4.60837 17.5 6.739 17.5 9C17.5 11.261 16.6146 13.3916 15.0031 15.0031L15.0031 15.0031C13.3916 16.6146 11.261 17.5 9 17.5C6.739 17.5 4.60837 16.6146 2.99689 15.0031L2.99688 15.0031C1.38536 13.3916 0.5 11.261 0.5 9C0.5 6.739 1.38536 4.60837 2.99688 2.99689L2.99689 2.99688C4.60837 1.38536 6.739 0.5 9 0.5C11.261 0.5 13.3916 1.38536 15.0031 2.99688Z'
        stroke='black'
      />
      <path
        d='M11.3002 11.1274C11.3002 11.1274 11.3002 11.1275 11.3001 11.1275H7.06873C7.06869 11.1275 7.06865 11.1274 7.06864 11.1274L6.21833 8.33028C6.2183 8.33019 6.21839 8.33012 6.21847 8.33016L7.41363 8.912C7.53232 8.96977 7.67494 8.9406 7.76141 8.84091L9.18436 7.20028C9.1844 7.20024 9.18447 7.20024 9.18451 7.20028L10.6075 8.84091C10.6939 8.94061 10.8366 8.96976 10.9552 8.912L12.1504 8.33016C12.1505 8.33012 12.1506 8.33019 12.1505 8.33028L11.3002 11.1274Z'
        stroke='black'
      />
    </svg>
  )
}

export default CoinIcon
