import * as actions from './actions'

const initialResourceState = {
  details: [],
  firstVideo: {},
  firstVideoReceived: false,
  pagyInfo: {},
  loading: false,
  likeLoading: false,
  commentLoading: false,
  videosReceived: false,
  createdVideoId: 0,
  createdVideoImg: null,
  errors: {}
}

const likeFirstVideo = (state, payload) => {
  if (state.firstVideo.id === payload.data.id) {
    const newFirstVideo = { ...state.firstVideo }
    newFirstVideo.attributes.likes_count = payload.data.attributes.likes_count
    newFirstVideo.attributes.is_liked = payload.data.attributes.is_liked
    return newFirstVideo
  } else {
    return state.firstVideo
  }
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.VIDEOS_INDEX_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.USER_VIDEOS_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.USER_VIDEOS_SUCCESS:
    return {
      loading: false,
      details: [...payload.response.data],
      pagyInfo: payload.pagy,
      videosReceived: true
    }

  case actions.VIDEOS_INDEX_SUCCESS:
    return {
      ...state,
      loading: false,
      details: [...state.details, ...payload.response.data],
      pagyInfo: payload.pagy,
      videosReceived: true
    }

  case actions.VIDEOS_INDEX_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case 'CREATE_REPORT_SUCCEEDED':
    return {
      ...state,
      details: state.details.filter((item) => item.id !== payload.id)
    }

  case actions.RESET_VIDEO_STATE:
    return {
      ...state,
      loading: false,
      details: [],
      errors: {}
    }

  case actions.RESET_VIDEO_CREATED_STATE:
    return {
      ...state,
      loading: false,
      createdVideoId: 0,
      createdVideoImg: null,
      errors: {}
    }

  case actions.SET_LIKE_REQUEST:
    return {
      ...state,
      likeLoading: true
    }

  case actions.SET_LIKE_SUCCESS:
    return {
      ...state,
      likeLoading: false,
      details: state.details.map((item) => {
        if (item.id === payload.data.id) {
          item.attributes.likes_count = payload.data.attributes.likes_count
          item.attributes.is_liked = payload.data.attributes.is_liked
          return item
        } else {
          return item
        }
      }),
      firstVideo: likeFirstVideo(state, payload)
    }

  case actions.SET_LIKE_FAILURE:
    return {
      ...state,
      likeLoading: false,
      errors: payload
    }

  case actions.VIDEO_INDEX_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.VIDEO_INDEX_SUCCESS:
    return {
      ...state,
      loading: false,
      firstVideo: payload.data,
      firstVideoReceived: true
    }

  case actions.VIDEO_INDEX_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.VIDEO_UPDATE_COMMENTS_COUNT_REQUEST:
    return {
      ...state,
      details: state.details.map(item => {
        if (item.id === payload) {
          const newCount = item.attributes.comments_count + 1
          item.attributes.comments_count = newCount
        }
        return item
      })
    }

  case actions.VIDEO_CREATE_INDEX_SUCCESS:
    localStorage.setItem('createdVideo', payload.id)
    localStorage.setItem('createdVideoImg', payload.image)
    return {
      ...state,
      loading: false,
      createdVideoId: payload.id,
      createdVideoImg: payload.image
    }

  case actions.VIDEO_CREATE_INDEX_FAILURE:
    localStorage.setItem('createdVideoError', true)
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.VIDEO_UPDATE_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.VIDEO_UPDATE_SUCCESS:
    localStorage.removeItem('createdVideo')
    localStorage.removeItem('createdVideoImg')
    localStorage.removeItem('fromUploadPage')
    localStorage.removeItem('videoDuration')
    localStorage.removeItem('createdVideoError')
    return {
      ...state,
      loading: false
    }

  case actions.VIDEO_UPDATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.REMOVE_VIDEO:
    return {
      ...state,
      loading: true
    }

  case actions.REMOVE_VIDEO_SUCCESS:
    return {
      ...state,
      loading: false
    }

  case actions.REMOVE_VIDEO_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  default:
    return state
  }
}
