import * as React from 'react'

const LockIcon = (props) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.0001 18.6271C11.4856 18.6271 11.8767 18.2361 11.8767 17.7505V15.6107C11.8767 15.1251 11.4856 14.7341 11.0001 14.7341C10.5146 14.7341 10.1235 15.1251 10.1235 15.6107V17.7505C10.1235 18.2361 10.5146 18.6271 11.0001 18.6271Z'
        fill='#9A9A9A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.2436 9.08359L7.19316 9.0793V6.03281C7.19316 3.93164 8.89902 2.22578 11.0002 2.22578C12.454 2.22578 13.7185 3.04242 14.3598 4.2416L14.3612 4.24082C14.5111 4.50549 14.7954 4.68408 15.1213 4.68408C15.6034 4.68408 15.9943 4.29321 15.9943 3.81104C15.9943 3.6608 15.9564 3.51944 15.8895 3.39599L15.8932 3.39397C14.9524 1.6564 13.1128 0.472656 11.0002 0.472656C7.93223 0.472656 5.44004 2.96914 5.44004 6.03281V9.08359H4.75684C4.01348 9.08359 3.40332 9.68945 3.40332 10.4328V20.1738C3.40332 20.9172 4.00918 21.5273 4.75684 21.5273H17.2479C17.9912 21.5273 18.6014 20.9215 18.6014 20.1738V10.4371C18.5971 9.68945 17.9912 9.08359 17.2436 9.08359ZM5.16074 19.7742V10.8367H16.8439V19.7742H16.8396H5.16074Z'
        fill='#9A9A9A'
      />
    </svg>
  )
}

export default LockIcon
