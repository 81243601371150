import { useEffect } from 'react'
import { isMobile, isSafari } from 'react-device-detect'

export const useVisibility = (
  setPlayerPaused,
  setPreviewShown,
  setIsButtonShown,
  isPlayerPaused,
  playerRef,
  isShowAds
) => {
  let hidden
  let visibilityChange
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      setPlayerPaused(true)
      setPreviewShown(false)
      isShowAds ? setIsButtonShown(false) : setIsButtonShown(true)
    } else if (!document[hidden] && playerRef.current.paused === false) {
      setPlayerPaused(false)
      setPreviewShown(false)
      setIsButtonShown(false)
    }
  }

  useEffect(() => {
    isMobile && isSafari && document.addEventListener(visibilityChange, handleVisibilityChange, false)
  }, [isPlayerPaused])
}
