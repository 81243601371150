import * as React from "react"

const AddToHomeScreenIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4 13v8a2 2 0 002 2h12a2 2 0 002-2v-8M16 5l-4-4-4 4M12 1v13"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8H6a3 3 0 00-3 3v8a1 1 0 102 0v-8a1 1 0 011-1h3V8zm6 2h3a1 1 0 011 1v8a1 1 0 102 0v-8a3 3 0 00-3-3h-3v2z"
        fill="#fff"
      />
    </svg>
  )
}

export default AddToHomeScreenIcon
