import * as React from 'react'

const TokenBlackIcon = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 11L11 14L16 9'
        stroke='black'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.32401 1H14.8947C15.9891 2.94286 17.9968 4.17486 20.2188 4.27468V11.1446C20.2188 13.8105 19.3871 16.3521 17.8113 18.5023C16.2538 20.6273 14.1131 22.1743 11.6094 22.9852C9.10564 22.1743 6.96493 20.6273 5.40749 18.5023C3.83164 16.3521 3 13.8105 3 11.1446V4.27452C5.22376 4.1735 7.23004 2.94211 8.32401 1Z'
        stroke='black'
        strokeWidth='2'
      />
    </svg>
  )
}

export default TokenBlackIcon
