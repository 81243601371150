import { put, call, takeLatest } from 'redux-saga/effects'

import { history, request } from '../../services'
import * as actions from './actions'

function* indexCategories({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.categoriesIndexSuccess(detail))
  } catch (e) {
    yield put(actions.categoriesIndexFailure(e))
  }
}

function* sendCategories(payload, { resource }) {
  try {
    yield request(resource, 'put', { id: payload })
    yield put(actions.categoriesSendSuccess())
    history.push('/v')
  } catch (e) {
    yield put(actions.categoriesSendFailure(e))
  }
}

function* chosenCategories({ resource }) {
  try {
    const detail = yield request(resource, 'get')
    yield put(actions.chosenCategoriesIndexSuccess(detail))
  } catch (e) {
    yield put(actions.chosenCategoriesIndexFailure(e))
  }
}

function* watchCategoriesIndexRequest({ meta }) {
  yield call(indexCategories, meta, history)
}

function* watchCategoriesSendRequest({ payload, meta }) {
  yield call(sendCategories, payload, meta, history)
}

function* watchChosenCategoriesSendRequest({ meta }) {
  yield call(chosenCategories, meta, history)
}

export default function* () {
  yield takeLatest(actions.CATEGORIES_INDEX_REQUEST, watchCategoriesIndexRequest)
  yield takeLatest(actions.CATEGORIES_SEND_REQUEST, watchCategoriesSendRequest)
  yield takeLatest(actions.CHOSEN_CATEGORIES_REQUEST, watchChosenCategoriesSendRequest)
}
