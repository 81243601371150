/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import { osName } from 'react-device-detect'
import axios from 'axios'

import './styles.scss'
import {
  CheckIcon, NoCheckIcon,
  UnFocusUsernameIcon, FocusUsernameIcon, FocusEmailIcon,
  UnFocusEmailIcon, FocusPasswordIcon, UnFocusPasswordIcon,
  SuccessIcon, ErrorIcon, SignUpIcon, CheckMarkerIcon, RefreshIcon,
  EyeOffIcon, EyeOnIcon, LockIcon, TipIcon
} from '../../assets/svg'
import DicktGif from '../../assets/dick-animation.gif'
import { history } from '../../../services'
import { ProgressBar } from '../../molecules'

import {
  checkPrice,
  checkEmail,
  checkUsername,
  checkPassword,
  checkDescription,
  descriptionLengthValid,
  checkCategories
} from '../../../lib/validCreateVideo'

const UploadVideo = ({
  emailPresent,
  usernamePresent,
  getValidation,
  goToQuill,
  categories,
  createdVideoId,
  currentUser,
  updateVideo,
  createdVideoImg,
  isUS,
  resetValidEmail,
  resetValidUsername,
  createdVideoError,
  removeCreatedVideo,
  videoId
}) => {
  const [selectedCategories, setSelectedCategories] = useState(null)
  const [checked, setChecked] = useState(false)
  const [locked, setLocked] = useState(false)
  const [focusedInput, setFocusedInput] = useState('')
  const [price, setPrice] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [description, setDescription] = useState('')
  const [changed, setChanged] = useState('')
  const [validPassword, setValidPassword] = useState(false)
  const [validDescription, setValidDescription] = useState(false)
  const [validPrice, setValidPrice] = useState(false)
  const [errorPrice, setErrorPrice] = useState('')
  const [errorPassword, setErrorPassword] = useState('')
  const [errorDescription, setErrorDescription] = useState('')
  const [errorCategories, setErrorCategories] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [errorEmail, setErrorEmail] = useState('')
  const [validUsername, setValidUsername] = useState(false)
  const [errorUsername, setErrorUsername] = useState('')
  const [videoCreated, setVideoCreated] = useState(false)
  const [textCount, setTetxCount] = useState(0)
  const [isLongUpload, setIsLongUpload] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const forbiddenСharacters = (description.match(/\n|\s/g) || []).length
  const [isProgresBarAnimate, setIsProgresBarAnimate] = useState(false)

  useEffect(() => {
    setChecked(isUS)
  }, [isUS])

  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    setVideoCreated(true)
    localStorage.setItem('userVideoCreated', true)
  }, [createdVideoId])

  useEffect(() => {
    if (isEmpty(currentUser)) {
      localStorage.setItem('userFirstVideoCreated', true)
    }
  }, [])
  useEffect(() => {
    checkPrice(locked, price, changed, setValidPrice, setErrorPrice)
  }, [price, changed, locked])

  useEffect(() => {
    password && checkPassword(password, changed, setValidPassword, setErrorPassword)
  }, [password, changed])

  useEffect(() => {
    email && checkEmail(email, focusedInput, changed, emailPresent, setValidEmail, setErrorEmail)
  }, [email, changed, getValidation, emailPresent, focusedInput])

  useEffect(() => {
    username && checkUsername(username, focusedInput, changed, usernamePresent, setValidUsername, setErrorUsername)
  }, [username, changed, getValidation, focusedInput, usernamePresent])

  useEffect(() => {
    setTetxCount(description.length - forbiddenСharacters)
    description && checkDescription(description, changed, forbiddenСharacters, setValidDescription, setErrorDescription)
  }, [description, changed, forbiddenСharacters])

  useEffect(() => {
    if (localStorage.getItem('fromUploadPage')) {
      setDescription(JSON.parse(localStorage.getItem('fromUploadPage')).storageDescription)
      setSelectedCategories(JSON.parse(localStorage.getItem('fromUploadPage')).storageSelectedCategories)
    }
  }, [])

  const errors = {
    price: errorPrice,
    email: errorEmail,
    password: errorPassword,
    username: errorUsername,
    description: errorDescription,
    categories: errorCategories
  }

  const valid = () => {
    if (!isEmpty(currentUser)) {
      return validPrice && (videoCreated || localStorage.getItem('createdVideo')) && validDescription &&
        selectedCategories
    } else {
      return validPrice && validEmail && validPassword && validUsername &&
        (videoCreated || localStorage.getItem('createdVideo')) && validDescription && selectedCategories
    }
  }

  const createPost = async () => {
    checkDescription(description, changed, forbiddenСharacters, setValidDescription, setErrorDescription)
    checkUsername(username, focusedInput, changed, usernamePresent, setValidUsername, setErrorUsername)
    checkEmail(email, focusedInput, changed, emailPresent, setValidEmail, setErrorEmail)
    checkPassword(password, changed, setValidPassword, setErrorPassword)
    checkCategories(selectedCategories, setSelectedCategories, setErrorCategories)
    if (valid()) {
      let loc

      if (localStorage.getItem('loc') !== null) {
        loc = localStorage.getItem('loc')
      } else {
        loc = await axios('https://api.ipbase.com/v2/info').then(
          res => { return res.data.data.location.country.alpha2 }
        )
        localStorage.setItem('loc', loc)
      }
      const id = localStorage.getItem('createdVideo')
      const data = {
        description,
        category_id: selectedCategories,
        locked,
        price: price || 0
      }
      const loginData = {
        username: username.toLowerCase(),
        password,
        password_confirmation: password,
        email: email.toLowerCase(),
        platform: osName,
        country: loc
      }
      if (!isEmpty(currentUser)) {
        updateVideo(id, { data })
      } else {
        updateVideo(id, { data, loginData })
      }
    }
  }

  const setCategory = (id) => {
    if (id === selectedCategories) {
      setSelectedCategories(null)
      setErrorCategories('Select a category for your video')
    } else {
      setSelectedCategories(id)
      setErrorCategories('')
    }
  }

  const renderListCategories = () => {
    return categories.map(item => {
      return (
        <div key={item.id} className='modal-category-wrapper'>
          <div
            className={
              `category upload-category modal-category uploadCtgBtn
              ${selectedCategories === item.attributes.id && 'category-active'}`
            }
            onClick={() => setCategory(item.attributes.id)}
            role='button'
            tabIndex='0'
          >
            {item.attributes.title}
          </div>
        </div>
      )
    })
  }

  const goToLogin = () => {
    history.push('/sign_in')
    localStorage.setItem('fromUploadPage', JSON.stringify({
      key: true,
      storageDescription: document.getElementById('description').value,
      storageSelectedCategories: selectedCategories
    }))
  }

  window.onbeforeunload = (e) => {
    e.preventDefault()
    localStorage.setItem('fromUploadPage', JSON.stringify({
      key: true,
      storageDescription: '',
      storageSelectedCategories: null
    }))
  }

  const goBack = () => {
    const createdVideo = parseInt(localStorage.getItem('createdVideo'))
    createdVideo && removeCreatedVideo(createdVideo)
    localStorage.removeItem('createdVideo')
    localStorage.removeItem('createdVideoImg')
    localStorage.removeItem('fromUploadPage')
    localStorage.removeItem('videoDuration')
    localStorage.removeItem('createdVideoError')
    localStorage.removeItem('coefficient')
    localStorage.removeItem('userVideoCreated')
    localStorage.removeItem('userFirstVideoCreated')
    if (videoId) {
      history.push(`/v/${videoId}`)
      localStorage.removeItem('currentVideoId')
    } else {
      history.push('/v')
    }
  }

  const hidePassword = () => {
    setShowPassword(!showPassword)
  }

  const userVideoCreated = localStorage.getItem('userVideoCreated')

  return (
    <div className='upload-container sign-in-container'>
      {
        isEmpty(currentUser) && (
          <button className='sign-up-button' onClick={() => goToLogin()} type='button'>
            <SignUpIcon />
            <p className='sign-in-text'>
              Already have an account? Login <span className='text-underline'>here</span> .
            </p>
          </button>
        )
      }
      <div className='upload-block' style={{ marginTop: `${isEmpty(currentUser) ? '56px' : '0px'}` }}>
        {
          isEmpty(createdVideoError) && !isLongUpload && !localStorage.getItem('createdVideoError')
            ? (localStorage.getItem('coefficient') || !localStorage.getItem('createdVideo')) && (
              <ProgressBar
                onAnimationEnd={() => setIsProgresBarAnimate(false)}
                customClassName={`upload-top-progressbar ${isProgresBarAnimate ? 'top-bar-animate' : ''}`}
                setIsLongUpload={setIsLongUpload}
              />
            )
            : (
              <div className='progrres-bar-error' />
            )
        }
        <div className='description-container'>
          <div className='description-title-block'>
            <p className='description-title'>Description</p>
            {
              errors.description && <ErrorIcon className='error-icon' />
            }
          </div>
          {
            errors.description && (
              <p className='upload-page-error-text'>{errors.description}</p>
            )
          }
          <div className='little-hr' />
          <div className='description-block'>
            <div className='description-textarea'>
              <textarea
                className='description'
                defaultValue={description}
                id='description'
                maxLength={300 + forbiddenСharacters}
                onBlur={() => descriptionLengthValid(
                  description, forbiddenСharacters, setValidDescription, setErrorDescription
                )}
                onChange={(e) => { setDescription(e.target.value); setChanged('description') }}
                placeholder='Tell us about your video.'
                type='text'
              />
              <p className='description-counter' id='count'>
                {textCount}/300
              </p>
            </div>
            {
              !localStorage.getItem('createdVideoError') && isEmpty(createdVideoError) && !isLongUpload
                ? (
                  <div
                    className='upload-gif'
                    style={{
                      backgroundImage: `url(
                        ${(createdVideoImg || localStorage.getItem('createdVideoImg')) || DicktGif}
                      )`
                    }}
                  >
                    {(videoCreated || localStorage.getItem('createdVideo')) && <CheckMarkerIcon />}
                    {
                      createdVideoImg || localStorage.getItem('createdVideoImg')
                        ? (
                          <div className='upload-gif-video-duration'>
                            {parseInt(localStorage.getItem('videoDuration'))} Seconds
                          </div>
                        )
                        : <div className='upload-gif-text'>UPLOADING</div>
                    }
                  </div>
                ) : (
                  <div
                    className='upload-error'
                    onClick={() => goBack()}
                    role='button'
                    tabIndex='0'
                  >
                    <RefreshIcon />
                    <div className='upload-tap'>Tap here to try again</div>
                  </div>
                )
            }
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div className='description-title-block'>
            <p className='description-title'>Category</p>
            {
              errors.categories && <ErrorIcon className='error-icon' />
            }
          </div>
          <div className='little-hr' />
          {
            errors.categories && (
              <p className='upload-page-error-text'>{errors.categories}</p>
            )
          }
          <div className='modal-show-me'>
            <div className='modal-buttons-block'>
              {renderListCategories()}
            </div>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div className='description-title-block'>
            <p className='description-title'>Lock this qwki?</p>
          </div>
          <div className='little-hr' />
          <div className='upload-page-lock'>
            <label className='switch' htmlFor='checkbox'>
              <input id='checkbox' onClick={() => setLocked(!locked)} type='checkbox' />
              <div className={`slider round ${locked && 'active'}`}>
                <span className={!locked && 'checked'}>No</span>
                <span className={locked && 'checked'}>Yes</span>
              </div>
            </label>
          </div>
          {locked && (
            <div className='upload-page-lock-form form-wrap'>
              <div className='form'>
                <div className='inputs'>
                  <div className='input-wrap'>
                    {
                      price.length
                        ? <TipIcon className='icon focus-upload-svg' />
                        : <LockIcon className='icon' />
                    }
                    {
                      validPrice &&
                      <SuccessIcon className='valid-icon' />
                    }
                    {
                      errors.price && (
                        <>
                          <ErrorIcon className='error-icon' />
                          <p className='error-text'>{errors.price}</p>
                        </>
                      )
                    }
                    <input
                      autoComplete='off'
                      className='input'
                      maxLength='25'
                      onBlur={() => setFocusedInput('')}
                      onChange={(e) => { setPrice(e.target.value); setChanged('price') }}
                      onFocus={() => setFocusedInput('price')}
                      placeholder='Token amount'
                      type='tel'
                      value={price}
                    />
                    <div className='inputs-hr' />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {
          isEmpty(currentUser) &&
            (
              <div className='upload-form-wrap form-wrap'>
                <div className='form'>
                  <div className='header'>
                    <p className='title'>Create account</p>
                  </div>
                  <div className='inputs'>
                    <div className='little-hr' />
                    <div className='input-wrap'>
                      {
                        focusedInput === 'username' || username.length
                          ? <FocusUsernameIcon className='icon focus-upload-svg' />
                          : <UnFocusUsernameIcon className='icon' />
                      }
                      {
                        validUsername &&
                        <SuccessIcon className='valid-icon' />
                      }
                      {
                        errors.username && (
                          <>
                            <ErrorIcon className='error-icon' />
                            <p className='error-text'>{errors.username}</p>
                          </>
                        )
                      }
                      <input
                        autoComplete='off'
                        className='input'
                        maxLength='25'
                        onBlur={() => { setFocusedInput(''); getValidation('username', username) }}
                        onChange={(e) => { setUsername(e.target.value); setChanged('username') }}
                        onFocus={() => { setFocusedInput('username'); resetValidUsername() }}
                        placeholder='Enter username'
                        type='text'
                        value={username}
                      />
                      <div className='inputs-hr' />
                    </div>
                    <div className='input-wrap'>
                      {
                        focusedInput === 'email' || email.length
                          ? <FocusEmailIcon className='icon focus-upload-svg' />
                          : <UnFocusEmailIcon className='icon' />
                      }
                      {
                        validEmail &&
                        <SuccessIcon className='valid-icon' />
                      }
                      {
                        errors.email && (
                          <>
                            <ErrorIcon className='error-icon' />
                            <p className='error-text'>{errors.email}</p>
                          </>
                        )
                      }
                      <input
                        autoComplete='off'
                        className='input'
                        onBlur={() => { setFocusedInput(''); getValidation('email', email) }}
                        onChange={(e) => { setEmail(e.target.value); setChanged('email') }}
                        onFocus={() => { setFocusedInput('email'); resetValidEmail() }}
                        placeholder='Enter email'
                        type='text'
                        value={email}
                      />
                      <div className='inputs-hr' />
                    </div>
                    <div className='input-wrap'>
                      {
                        focusedInput === 'password' || password.length
                          ? <FocusPasswordIcon className='icon focus-upload-svg' />
                          : <UnFocusPasswordIcon className='icon' />
                      }
                      {
                        validPassword &&
                        <SuccessIcon className='valid-icon' />
                      }
                      {
                        (errors.password ||
                        (focusedInput === 'password' && password.length < 8 && password.length >= 1)) && (
                          <>
                            <ErrorIcon className='error-icon' />
                            <p className='error-text'>{errors.password || 'Password too short'}</p>
                          </>
                        )
                      }
                      {
                        password && password.length && (
                          showPassword
                            ? <EyeOnIcon className='eye-icon' onClick={() => hidePassword()} />
                            : <EyeOffIcon className='eye-icon' onClick={() => hidePassword()} />
                        )
                      }
                      <input
                        autoComplete='new-password'
                        className='input'
                        onBlur={() => { setFocusedInput(''); setChanged('') }}
                        onChange={(e) => { setPassword(e.target.value); setChanged('password') }}
                        onFocus={() => setFocusedInput('password')}
                        placeholder='Enter password'
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                      />
                      <div className='inputs-hr bottom' />
                    </div>
                  </div>
                </div>
              </div>
            )
        }
        <div>
          <div className='check-container' onClick={() => setChecked(!checked)} role='button' tabIndex={0}>
            {
              checked
                ? <CheckIcon />
                : <NoCheckIcon />
            }
            <p className='check'>
              I agree to receive updates and offers from Qwki. Opt-out anytime.
            </p>
          </div>
          <div className='buttons'>
            <a
              className={`${valid() ? 'done' : 'disable'} button uploadPostBtn`}
              onClick={userVideoCreated ? createPost : () => setIsProgresBarAnimate(true)}
              role='button'
            >
              <p className='p-button done-color'>{userVideoCreated ? 'Post' : 'Uploading...'}</p>
            </a>
            <a className='cancel button uploadCancelBtn' onClick={() => goBack()} role='button'>
              <p className='p-button cancel-color'>Cancel</p>
            </a>
          </div>
          <div className='under-buttons-container'>
            <p className='by-creating-text'>
              By posting, you verify that all parties depicted in the content you&apos;re uploading consent to
              it being posted on Qwki and are aware that you are doing so. You are representing that your files
              do not violate Qwki’s
              <button
                className='link'
                onClick={() => goToQuill('Terms Of Use', 'terms_of_use')}
                type='button'
              >&nbsp;<b>Terms of Use</b>&nbsp;
              </button>
              and that you are willing to provide any 2257 information upon request.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

UploadVideo.propTypes = {
  categories: PropTypes.array.isRequired,
  emailPresent: PropTypes.bool,
  usernamePresent: PropTypes.bool,
  getValidation: PropTypes.func.isRequired,
  goToQuill: PropTypes.func.isRequired,
  createdVideoImg: PropTypes.string,
  createdVideoId: PropTypes.number,
  currentUser: PropTypes.object,
  updateVideo: PropTypes.func.isRequired,
  isUS: PropTypes.bool.isRequired,
  resetValidEmail: PropTypes.func.isRequired,
  resetValidUsername: PropTypes.func.isRequired,
  createdVideoError: PropTypes.object,
  removeCreatedVideo: PropTypes.func.isRequired,
  videoId: PropTypes.string
}

export default UploadVideo
