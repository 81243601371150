import * as React from "react"

const CopyrightBlackIcon = (props) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11.5 22c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.885 15.8c-.76 0-1.43-.165-2.01-.495-.58-.33-1.04-.79-1.38-1.38C8.165 13.335 8 12.66 8 11.9s.165-1.435.495-2.025c.34-.59.8-1.05 1.38-1.38.58-.33 1.25-.495 2.01-.495.95 0 1.75.25 2.4.75.65.49 1.065 1.17 1.245 2.04h-2.025c-.1-.36-.3-.64-.6-.84a1.717 1.717 0 00-1.035-.315c-.53 0-.98.2-1.35.6-.37.4-.555.955-.555 1.665 0 .71.185 1.265.555 1.665.37.4.82.6 1.35.6.4 0 .745-.1 1.035-.3.3-.2.5-.485.6-.855h2.025c-.18.84-.595 1.515-1.245 2.025-.65.51-1.45.765-2.4.765z"
        fill="#000"
      />
    </svg>
  )
}

export default CopyrightBlackIcon
