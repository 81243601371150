import axios from 'axios'

export const request = (endpoint, method, data) => {
  return axios(process.env.BACKEND_BASE_URL + endpoint, {
    method,
    data,
    withCredentials: true
  })
    .then(res => {
      return res.data
    })
}
