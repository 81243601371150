import * as React from 'react'

const TipIcon = (props) => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip2)'>
        <path
          d='M21.4747 4.52524L21.4748 4.52525C23.7508 6.80125 25 9.80834 25 13C25 16.1917 23.7508 19.1987 21.4748 21.4747L21.4747 21.4748C19.1987 23.7508 16.1917 25 13 25C9.80834 25 6.80125 23.7508 4.52525 21.4748L4.52524 21.4747C2.24919 19.1987 1 16.1917 1 13C1 9.80834 2.24919 6.80125 4.52524 4.52525L4.52525 4.52524C6.80125 2.24919 9.80834 1 13 1C16.1917 1 19.1987 2.24919 21.4747 4.52524Z'
          stroke='white'
          strokeWidth='2'
        />
        <path
          d='M16.3224 16.0727C16.3224 16.0727 16.3223 16.0727 16.3223 16.0727H10.2102C10.2102 16.0727 10.2102 16.0727 10.2101 16.0727L8.98188 12.0322C8.98185 12.0322 8.98194 12.0321 8.98202 12.0321L10.7085 12.8726C10.8799 12.9561 11.0859 12.9139 11.2108 12.7699L13.2662 10.4001C13.2663 10.4 13.2663 10.4 13.2664 10.4001L15.3218 12.7699C15.4467 12.9139 15.6527 12.956 15.8241 12.8726L17.5506 12.0321C17.5506 12.0321 17.5507 12.0322 17.5507 12.0322L16.3224 16.0727Z'
          stroke='white'
          strokeWidth='2'
        />
      </g>
      <defs>
        <clipPath id='clip2'>
          <rect width='26' height='26' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default TipIcon
