import React, { useEffect } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import BeatLoader from 'react-spinners/BeatLoader'

import { TipIcon } from '../../assets/svg'
import './styles.scss'

const PurchaseModal = (props) => {
  const {
    show,
    price,
    handleSubmit,
    handleClose,
    purchaseLoading,
    purchaseAnimation,
    purchaseError,
    setRefillModal
  } = props

  const handleBuyTokens = () => {
    handleClose()
    setRefillModal(true)
  }

  useEffect(() => {
    if (purchaseAnimation) {
      handleClose()
    }
  }, [purchaseAnimation])

  return (
    <Modal
      ariaHideApp={false}
      className='purchase-modal'
      isOpen={show}
      onRequestClose={() => handleClose()}
      overlayClassName='Overlay'
    >
      <div className='purchase-modal__wrapper'>
        {purchaseLoading && (
          <div className='purchase-modal__loader'>
            <BeatLoader color='#9a9a9a' size={15} />
          </div>
        )}
        {!purchaseLoading && purchaseError && (
          <>
            <div className='purchase-modal__title'>
              <p>{purchaseError}</p>
            </div>
            <div className='purchase-modal__hr' />
            <div className='purchase-modal__actions'>
              <button
                className='purchase-modal__btn cancel-btn'
                onClick={() => handleClose()}
                type='button'
              >
                Close
              </button>
              <button
                className='purchase-modal__btn'
                onClick={() => handleBuyTokens()}
                type='button'
              >
                Buy tokens
              </button>
            </div>
          </>
        )}
        {!purchaseLoading && !purchaseError && (
          <>
            <div className='purchase-modal__title'>
              <p>
                Unlock this video for<br />
                <div className='purchase-modal__price'><TipIcon height={20} width={20} />{price}</div>
              </p>
            </div>
            <div className='purchase-modal__hr' />
            <div className='purchase-modal__actions'>
              <button
                className='purchase-modal__btn'
                onClick={() => handleSubmit()}
                type='button'
              >
                Yes
              </button>
              <button
                className='purchase-modal__btn cancel-btn'
                onClick={() => handleClose()}
                type='button'
              >
                No
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

PurchaseModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  purchaseAnimation: PropTypes.bool.isRequired,
  purchaseLoading: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  purchaseError: PropTypes.string,
  setRefillModal: PropTypes.func.isRequired
}

export default PurchaseModal
