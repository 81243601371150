import * as React from 'react'

const EighteenPlusIcon = (props) => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.89248 27.0483V13.1141L0 13.7933V11.3284L4.25068 9.44196H6.23769V27.0483H2.89248Z" fill="white"/>
      <path d="M15.1933 27.3502C13.986 27.3502 12.8961 27.1406 11.9235 26.7214C10.951 26.2854 10.1713 25.6734 9.58441 24.8853C9.0143 24.0972 8.72924 23.1833 8.72924 22.1437C8.72924 21.0706 9.00591 20.1315 9.55926 19.3267C10.1294 18.5218 10.8755 17.9433 11.7978 17.5912C10.9929 17.2391 10.3557 16.736 9.88623 16.0821C9.41673 15.4114 9.18198 14.6652 9.18198 13.8436C9.18198 13.0052 9.40834 12.2338 9.86108 11.5296C10.3138 10.8086 10.9845 10.2301 11.8732 9.79409C12.7787 9.35812 13.8854 9.14014 15.1933 9.14014C16.5012 9.14014 17.5995 9.35812 18.4882 9.79409C19.3769 10.2301 20.0476 10.8086 20.5004 11.5296C20.9531 12.2338 21.1795 13.0052 21.1795 13.8436C21.1795 14.6484 20.9363 15.3946 20.4501 16.0821C19.9806 16.7528 19.3518 17.2558 18.5637 17.5912C19.5027 17.9433 20.2488 18.5218 20.8022 19.3267C21.3555 20.1315 21.6322 21.0706 21.6322 22.1437C21.6322 23.1833 21.3471 24.0972 20.777 24.8853C20.2069 25.6734 19.4272 26.2854 18.4379 26.7214C17.4654 27.1406 16.3838 27.3502 15.1933 27.3502ZM15.1933 16.6354C16.0652 16.6354 16.7527 16.4091 17.2558 15.9563C17.7588 15.5036 18.0103 14.9251 18.0103 14.2208C18.0103 13.4495 17.7588 12.8459 17.2558 12.4099C16.7695 11.9739 16.082 11.7559 15.1933 11.7559C14.2878 11.7559 13.592 11.9739 13.1057 12.4099C12.6194 12.8459 12.3763 13.4495 12.3763 14.2208C12.3763 14.9419 12.6194 15.5287 13.1057 15.9815C13.6087 16.4174 14.3046 16.6354 15.1933 16.6354ZM15.1933 24.6589C16.2664 24.6589 17.0797 24.399 17.633 23.8792C18.1864 23.3426 18.4631 22.6719 18.4631 21.867C18.4631 20.9783 18.1696 20.2825 17.5827 19.7794C17.0126 19.2764 16.2161 19.0249 15.1933 19.0249C14.1704 19.0249 13.3572 19.2764 12.7536 19.7794C12.1667 20.2825 11.8732 20.9783 11.8732 21.867C11.8732 22.6719 12.1499 23.3426 12.7033 23.8792C13.2734 24.399 14.1034 24.6589 15.1933 24.6589Z" fill="white"/>
      <path d="M23.8264 21.1125V18.4212H28.555V14.0699H31.2966V18.4212H36V21.1125H31.2966V25.4638H28.555V21.1125H23.8264Z" fill="white"/>
    </svg>
  )
}

export default EighteenPlusIcon
