const resource = 'banners'

export const ADS_INDEX_REQUEST = 'INDEX_ADS_REQUEST'
export const ADS_INDEX_SUCCESS = 'INDEX_ADS_SUCCEEDED'
export const ADS_INDEX_FAILURE = 'INDEX_ADS_FAILED'
export const ADS_SEND_REQUEST = 'SEND_ADS_REQUEST'
export const ADS_SEND_SUCCESS = 'SEND_ADS_SUCCESS'
export const ADS_SEND_FAILURE = 'SEND_ADS_FAILURE'

export const adsIndexRequest = () => ({
  type: ADS_INDEX_REQUEST,
  meta: {
    resource
  }
})

export const adsIndexSuccess = (detail) => ({
  type: ADS_INDEX_SUCCESS,
  payload: detail
})

export const adsIndexFailure = (error) => ({
  type: ADS_INDEX_FAILURE,
  error: true,
  payload: error
})

export const adsSendRequest = (detail) => ({
  type: ADS_SEND_REQUEST,
  meta: {
    resource: `banners/${detail.id}`
  },
  payload: detail.click
})

export const adsSendSuccess = (detail) => ({
  type: ADS_SEND_SUCCESS,
  payload: detail
})

export const adsSendFailure = (error) => ({
  type: ADS_SEND_FAILURE,
  error: true,
  payload: error
})

export const RESET_AD_STATE = 'RESET_AD_STATE'

export const resetAdState = () => ({
  type: RESET_AD_STATE
})
