const resource = 'categories'

export const CATEGORIES_INDEX_REQUEST = 'INDEX_CATEGORIES_REQUEST'
export const CATEGORIES_INDEX_SUCCESS = 'INDEX_CATEGORIES_SUCCEEDED'
export const CATEGORIES_INDEX_FAILURE = 'INDEX_CATEGORIES_FAILED'

export const CATEGORIES_SEND_REQUEST = 'SEND_CATEGORIES_REQUEST'
export const CATEGORIES_SEND_SUCCESS = 'SEND_CATEGORIES_SUCCEEDED'
export const CATEGORIES_SEND_FAILURE = 'SEND_CATEGORIES_FAILED'

export const CHOSEN_CATEGORIES_REQUEST = 'INDEX_CHOSEN_CATEGORIES_REQUEST'
export const CHOSEN_CATEGORIES_SUCCESS = 'INDEX_CHOSEN_CATEGORIES_SUCCEEDED'
export const CHOSEN_CATEGORIES_FAILURE = 'INDEX_CHOSEN_CATEGORIES_FAILED'

export const categoriesIndexRequest = () => ({
  type: CATEGORIES_INDEX_REQUEST,
  meta: {
    resource
  }
})

export const categoriesIndexSuccess = (detail) => ({
  type: CATEGORIES_INDEX_SUCCESS,
  payload: detail
})

export const categoriesIndexFailure = (error) => ({
  type: CATEGORIES_INDEX_FAILURE,
  error: true,
  payload: error
})

export const categoriesSendRequest = (payload) => ({
  type: CATEGORIES_SEND_REQUEST,
  meta: {
    resource: 'users_categories'
  },
  payload
})

export const categoriesSendSuccess = () => ({
  type: CATEGORIES_SEND_SUCCESS
})

export const categoriesSendFailure = (error) => ({
  type: CATEGORIES_SEND_FAILURE,
  error: true,
  payload: error
})

export const chosenCategoriesIndexRequest = () => ({
  type: CHOSEN_CATEGORIES_REQUEST,
  meta: {
    resource: 'users_categories'
  }
})

export const chosenCategoriesIndexSuccess = (detail) => ({
  type: CHOSEN_CATEGORIES_SUCCESS,
  payload: detail
})

export const chosenCategoriesIndexFailure = (error) => ({
  type: CHOSEN_CATEGORIES_FAILURE,
  error: true,
  payload: error
})
