import * as React from 'react'

const IllegalIcon = (props) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3303 28.5852L23.2334 11.7915C24.4665 9.46557 23.5043 6.61929 21.0842 5.43416C18.6641 4.24904 15.7026 5.17385 14.4695 7.49979L5.56642 24.2935C4.33333 26.6194 5.29557 29.4657 7.71565 30.6509C10.1357 31.836 13.0972 30.9112 14.3303 28.5852ZM22.3866 2.97745C18.5548 1.10099 13.8658 2.56528 11.9134 6.24803L3.01028 23.0417C1.05788 26.7245 2.58143 31.2311 6.41323 33.1076C10.245 34.984 14.934 33.5197 16.8864 29.837L25.7895 13.0433C27.7419 9.36052 26.2184 4.8539 22.3866 2.97745Z" fill="white"/>
      <path d="M10.927 13.6037L20.1778 18.1339L18.8999 20.5443L9.64915 16.0141L10.927 13.6037Z" fill="white"/>
      <rect width="10.5891" height="2.12634" transform="matrix(0.895908 -0.444241 0.458474 0.888708 22.228 27.9697)" fill="white"/>
      <path d="M33.2125 26.7815C33.2125 29.9599 30.5773 32.5789 27.2739 32.5789C23.9704 32.5789 21.3352 29.9599 21.3352 26.7815C21.3352 23.6031 23.9704 20.984 27.2739 20.984C30.5773 20.984 33.2125 23.6031 33.2125 26.7815Z" stroke="white" strokeWidth="2.52273"/>
    </svg>
  )
}

export default IllegalIcon
