import * as React from 'react'

const ProfileIcon = (props) => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M16.8111 13.9078L13.7703 12.8942C13.6976 12.8697 13.6214 12.8572 13.5446 12.857H7.83037C7.75361 12.8572 7.6774 12.8697 7.60466 12.8942L4.56394 13.9078C2.2476 14.6774 0.685284 16.8448 0.687502 19.2856C0.687502 19.6801 1.0073 19.9999 1.4018 19.9999H19.9732C20.3677 19.9999 20.6875 19.6801 20.6875 19.2856C20.6897 16.8448 19.1274 14.6774 16.8111 13.9078ZM2.17608 18.5713C2.43858 17.0282 3.53068 15.7557 5.01607 15.262L7.94605 14.2856H13.4289L16.3575 15.262C17.8434 15.7552 18.9362 17.0278 19.1989 18.5713H2.17608Z'
          fill='white'
        />
        <path
          d='M10.6878 0C8.3209 0 6.40213 1.91878 6.40213 4.28571V7.14287C6.40447 9.50884 8.32187 11.4262 10.6878 11.4286C13.0538 11.4262 14.9712 9.50884 14.9735 7.14287V4.28571C14.9735 1.91878 13.0548 0 10.6878 0ZM13.545 7.14287C13.545 8.72084 12.2658 10 10.6878 10C9.10987 10 7.83068 8.72084 7.83068 7.14287V4.28571C7.83068 2.70774 9.10987 1.42856 10.6878 1.42856C12.2658 1.42856 13.545 2.70774 13.545 4.28571V7.14287Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.6875)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ProfileIcon
