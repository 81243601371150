import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { history } from '../services'
import {
  getProfileRequest,
  getProfileVideosRequest,
  resetProfileVideosState,
  removeProfileVideoRequest,
  sessionDeleteRequest,
  validationsCreateRequest,
  resetValidationUsername,
  packsIndexRequest,
  sessionShowRequest,
  tipCreateRequest,
  tipResetState
} from '../store/actions'

import Profile from '../components/pages/Profile'

class ProfileContainer extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    if (this.props.match.params.username) {
      this.props.getUser()
      this.props.getProfile(this.props.match.params.username)
    } else {
      history.push('/v')
    }
  }

  onGetValidation = (field, value) => {
    if (value) {
      this.props.getValidation({ [field]: value.toLowerCase() })
    }
  }

  render() {
    return (
      <Profile
        createTip={this.props.createTip}
        currentGuest={this.props.currentGuest}
        currentUser={this.props.currentUser}
        deleteVideo={this.props.deleteVideo}
        getPacks={this.props.getPacks}
        getUser={this.props.getUser}
        getVideos={this.props.getProfileVideos}
        hasMoreVideos={this.props.hasMoreVideos}
        isOwn={this.props.isOwn}
        onDeleteSession={this.props.deleteSession}
        packs={this.props.packs}
        packsLoading={this.props.packsLoading}
        profile={this.props.profile}
        profileLoading={this.props.profileLoading}
        resetProfileVideos={this.props.resetProfileVideos}
        resetTipState={this.props.resetTipState}
        tipCreated={this.props.tipCreated}
        tipLoading={this.props.tipLoading}
        videos={this.props.videos}
        videosLoading={this.props.videosLoading}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile.details,
  profileLoading: state.profile.profileLoading,
  isOwn: state.profile.details.is_own,
  videos: state.profile.videos,
  videosLoading: state.profile.videosLoading,
  hasMoreVideos: state.profile.hasMore,
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest,
  packs: state.packs.details,
  pack: state.packs.detail,
  packsLoading: state.packs.packsLoading,
  tipLoading: state.tips.loading,
  tipCreated: state.tips.tipCreated
})

const mapDispatchToProps = (dispatch) => ({
  getProfile: (username) => dispatch(getProfileRequest(username)),
  getProfileVideos: (id, liked, page) => dispatch(getProfileVideosRequest(id, liked, page)),
  deleteVideo: (id) => dispatch(removeProfileVideoRequest(id)),
  deleteSession: () => dispatch(sessionDeleteRequest()),
  getValidation: (data) => dispatch(validationsCreateRequest(data)),
  resetProfileVideos: () => dispatch(resetProfileVideosState()),
  resetValidUsername: () => dispatch(resetValidationUsername()),
  getPacks: () => dispatch(packsIndexRequest()),
  getUser: () => dispatch(sessionShowRequest()),
  createTip: (data) => dispatch(tipCreateRequest(data)),
  resetTipState: () => dispatch(tipResetState())
})

ProfileContainer.propTypes = {
  getProfile: PropTypes.func.isRequired,
  getProfileVideos: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func.isRequired,
  deleteSession: PropTypes.func.isRequired,
  resetProfileVideos: PropTypes.func.isRequired,
  profile: PropTypes.object,
  profileLoading: PropTypes.bool.isRequired,
  videos: PropTypes.array,
  videosLoading: PropTypes.bool.isRequired,
  hasMoreVideos: PropTypes.bool.isRequired,
  getValidation: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGuest: PropTypes.object.isRequired,
  createTip: PropTypes.func.isRequired,
  tipLoading: PropTypes.bool.isRequired,
  tipCreated: PropTypes.bool.isRequired,
  resetTipState: PropTypes.func.isRequired,
  getPacks: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  packs: PropTypes.array,
  packsLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  isOwn: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
