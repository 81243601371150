const resource = 'purchases'

export const PURCHASE_CREATE_REQUEST = 'PURCHASE_CREATE_REQUEST'
export const PURCHASE_CREATE_SUCCESS = 'PURCHASE_CREATE_SUCCESS'
export const PURCHASE_CREATE_FAILURE = 'PURCHASE_CREATE_FAILURE'
export const RESET_PURCHASE_STATE = 'RESET_PURCHASE_STATE'

export const purchaseCreateRequest = (payload) => ({
  type: PURCHASE_CREATE_REQUEST,
  meta: {
    resource
  },
  payload
})

export const purchaseCreateSuccess = (detail) => ({
  type: PURCHASE_CREATE_SUCCESS,
  payload: detail
})

export const purchaseCreateFailure = (error) => ({
  type: PURCHASE_CREATE_FAILURE,
  error: true,
  payload: error
})

export const resetPurchaseState = () => ({
  type: RESET_PURCHASE_STATE
})
