import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Player extends Component {
  componentDidMount() {
    this.props.playerRef.current.addEventListener('loadstart', () => {
      this.props.setShowSpinner(true)
    })

    this.props.playerRef.current.addEventListener('canplay', () => {
      this.props.setShowSpinner(false)
      this.props.setPreviewShown(false)
    })
  }

  componentDidUpdate(prevProps) {
    const isVideoLocked = this.props.video.attributes.is_locked
    this.props.isPlayerPaused || isVideoLocked
      ? this.props.playerRef.current.pause()
      : this.props.playerRef.current.play()
    if (
      this.props.video !== prevProps.video &&
      !this.props.isShowAds &&
      !this.props.isPlayerPaused
    ) {
      this.props.setPlayerPaused(false)
    }
  }

  render() {
    return (
      <>
        <video
          ref={this.props.playerRef}
          loop
          playsInline
          preload='auto'
          src={this.props.video.attributes.url}
          style={{ height: '100vh', objectFit: 'cover' }}
          webkit-playsinline='true'
          width='100%'
        />
        <video
          preload='auto'
          src={this.props.nextVideo.attributes.url}
          style={{ display: 'none' }}
        />
      </>
    )
  }
}

Player.propTypes = {
  setPlayerPaused: PropTypes.func.isRequired,
  setPreviewShown: PropTypes.func.isRequired,
  setShowSpinner: PropTypes.func.isRequired,
  isPlayerPaused: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
  isShowAds: PropTypes.bool.isRequired,
  playerRef: PropTypes.object.isRequired,
  nextVideo: PropTypes.object.isRequired
}

export default Player
