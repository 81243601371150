const resource = 'guests'

export const GUEST_CREATE_REQUEST = 'CREATE_GUEST_REQUEST'
export const GUEST_CREATE_SUCCESS = 'CREATE_GUEST_SUCCEEDED'
export const GUEST_CREATE_FAILURE = 'CREATE_GUEST_FAILED'

export const guestCreateRequest = () => ({
  type: GUEST_CREATE_REQUEST,
  meta: {
    resource
  }
})

export const guestCreateSuccess = (detail) => ({
  type: GUEST_CREATE_SUCCESS,
  payload: detail
})

export const guestCreateFailure = (error) => ({
  type: GUEST_CREATE_FAILURE,
  error: true,
  payload: error
})

export const GUEST_RESET_STATE = 'RESET_GUEST_STATE'

export const guestResetState = () => ({
  type: GUEST_RESET_STATE
})
