import * as React from "react"

const SuccessIcon = (props) => {
  return (
    <svg width={18} height={13} viewBox="0 0 18 13" fill="none" {...props}>
      <path
        d="M17 1L6 12 1 7"
        stroke="#48D2A0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SuccessIcon
