const resource = 'ads_frequency'

export const FREQUENCY_INDEX_REQUEST = 'INDEX_FREQUENCY_REQUEST'
export const FREQUENCY_INDEX_SUCCESS = 'INDEX_FREQUENCY_SUCCEEDED'
export const FREQUENCY_INDEX_FAILURE = 'INDEX_FREQUENCY_FAILED'

export const frequencyIndexRequest = () => ({
  type: FREQUENCY_INDEX_REQUEST,
  meta: {
    resource
  }
})

export const frequencyIndexSuccess = (detail) => ({
  type: FREQUENCY_INDEX_SUCCESS,
  payload: detail
})

export const frequencyIndexFailure = (error) => ({
  type: FREQUENCY_INDEX_FAILURE,
  error: true,
  payload: error
})
