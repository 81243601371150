const resource = 'validations'

export const VALIDATIONS_CREATE_REQUEST = 'CREATE_VALIDATIONS_REQUEST'
export const VALIDATIONS_CREATE_SUCCESS = 'CREATE_VALIDATIONS_SUCCEEDED'
export const VALIDATIONS_CREATE_FAILURE = 'CREATE_VALIDATIONS_FAILED'
export const RESET_VALIDATION_EMAIL = 'RESET_VALIDATION_EMAIL'
export const RESET_VALIDATION_USERNAME = 'RESET_VALIDATION_USERNAME'

export const validationsCreateRequest = (data) => ({
  type: VALIDATIONS_CREATE_REQUEST,
  payload: { data },
  meta: {
    resource
  }
})

export const validationsCreateSuccess = (detail) => ({
  type: VALIDATIONS_CREATE_SUCCESS,
  payload: detail
})

export const validationsCreateFailure = (error) => ({
  type: VALIDATIONS_CREATE_FAILURE,
  error: true,
  payload: error
})

export const resetValidationEmail = () => ({
  type: RESET_VALIDATION_EMAIL
})

export const resetValidationUsername = () => ({
  type: RESET_VALIDATION_USERNAME
})
