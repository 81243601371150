import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'

import { history } from '../services'
import {
  infoIndexRequest,
  getProfileVideoRequest,
  updateProfileVideoRequest,
  categoriesIndexRequest
} from '../store/actions'

import ProfileVideoEdit from '../components/pages/ProfileVideoEdit'

class ProfileVideoEditContainer extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {
    const videoId = this.props.match.params.id
    if (
      !isEmpty(this.props.currentUser) &&
      isEmpty(this.props.currentGuest) &&
      videoId
    ) {
      if (!this.props.categoriesReceived) {
        this.props.getCategories()
      }
      this.props.getVideo(videoId)
    } else {
      history.push('/v')
    }
  }

  goToQuill = (name, value) => {
    history.push('/quill', { title: name, data: this.props.info[value] })
  }

  render() {
    return (
      <ProfileVideoEdit
        categories={this.props.categories}
        categoriesReceived={this.props.categoriesReceived}
        goToQuill={this.goToQuill}
        updateVideo={this.props.updateVideo}
        video={this.props.video}
        videoLoading={this.props.videoLoading}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  info: state.info.details,
  video: state.profile.video,
  videoLoading: state.profile.videoLoading,
  categories: state.categories.details,
  categoriesReceived: state.categories.categoriesReceived,
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest
})

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(infoIndexRequest()),
  getVideo: (id) => dispatch(getProfileVideoRequest(id)),
  updateVideo: (id, data) => dispatch(updateProfileVideoRequest(id, data)),
  getCategories: () => dispatch(categoriesIndexRequest())
})

ProfileVideoEditContainer.propTypes = {
  getVideo: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  categoriesReceived: PropTypes.bool.isRequired,
  updateVideo: PropTypes.func.isRequired,
  info: PropTypes.object,
  video: PropTypes.object.isRequired,
  videoLoading: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentGuest: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileVideoEditContainer)
