import * as React from "react"

const EyeOffIcon = (props) => {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <g
        clipPath={`url(${window.location.pathname}#prefix__clip0)`}
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9.414 9.413a2 2 0 11-2.827-2.826m5.373 5.373A6.714 6.714 0 018 13.333C3.334 13.333.667 8 .667 8A12.3 12.3 0 014.04 4.04l7.92 7.92zM6.6 2.827a6.08 6.08 0 011.4-.16C12.667 2.667 15.334 8 15.334 8c-.405.757-.888 1.47-1.44 2.127L6.6 2.827zM.667.667l14.667 14.666" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EyeOffIcon
