import * as React from 'react'

const ArrowDownIcon = (props) => {
  return (
    <svg
      width='12'
      height='7'
      viewBox='0 0 12 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M1 0.5L6 5.5L11 0.5' stroke='#9A9A9A' strokeWidth='1.3' />
    </svg>
  )
}

export default ArrowDownIcon
