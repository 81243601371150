/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import { historyRefresh } from '../../../services'
import './styles.scss'

const WelcomeModal = (props) => {
  const close = () => {
    ReactGA.event({
      category: 'Welcome modal button',
      action: 'Clicked button'
    })
    props.closeModal()
  }

  return (
    <div>
      <div className='welcome-modal-main-wrap' />
      <div className='welcome-modal-container'>
        <div className='title-wrap'>
          <p className='title-text'>You must be over 18</p>
          <p className='title-text'>And agree to the terms below</p>
          <p className='title-text'>Before continuing:</p>
        </div>
        <div style={{ width: 'auto', backgroundColor: 'black' }}>
          <div className='content-wrap'>
            <p className='content-text'>
              This website contains information,&nbsp;
              links, images and videos of sexually explicit material&nbsp;
              (collectively, the &quot;Sexually Explicit Material&quot;).
            </p>
            <p className='content-text'>
              Do NOT continue if:<br />
              (i) you are not at least 18 years of age or the age of majority in each and every jurisdiction in which you will or may view the Sexually Explicit Material, whichever is higher (the "Age of Majority"), (ii) such material offends you, or (iii) viewing the Sexually Explicit Material is not legal in each and every community where you choose to view it.
            </p>
            <p className='content-text'>
              By choosing to enter this website you are affirming under oath and penalties of perjury pursuant to Title 28 U.S.C. § 1746 and other applicable statutes and laws that all of the following statements are true and correct:
              <br />
              • I have attained the Age of Majority in my jurisdiction;
              <br />
              <br />
              • The sexually explicit material I am viewing is for my own personal use and I will not expose any minors to the material;
              <br />
              <br />
              • I desire to receive/view sexually explicit material;
              <br />
              <br />
              • I believe that as an adult it is my inalienable constitutional right to receive/view sexually explicit material;
              <br />
              <br />
              • I believe that sexual acts between consenting adults are neither offensive nor obscene;
              <br />
              <br />
              • The viewing, reading and downloading of sexually explicit materials does not violate the standards of any community, town, city, state or country where I will be viewing, reading and/or downloading the Sexually Explicit Materials;
              <br />
              <br />
              • I am solely responsible for any false disclosures or legal ramifications of viewing, reading or downloading any material appearing on this site. I further agree that neither this website nor its affiliates will be held responsible for any legal ramifications arising from any fraudulent entry into or use of this website;
              <br />
              <br />
              • I understand that my use of this website is governed by the website's Terms <button onClick={() => historyRefresh.push('/quill', { title: 'Terms of Use', data: props.info.terms_of_use, modal: true })} type='button'>https://qwki.xxx/terms/</button> which I have reviewed and accepted, and I agree to be bound by such Terms.
              <br />
              <br />
              • I agree that by entering this website, I am subjecting myself, and any business entity in which I have any legal or equitable interest, to the personal jurisdiction of the State of California, County of San Francisco, should any dispute arise at any time between this website, myself and/or such business entity;
              <br />
              <br />
              • This warning page constitutes a legally binding agreement between me, this website and/or any business in which I have any legal or equitable interest. If any provision of this Agreement is found to be unenforceable, the remainder shall be enforced as fully as possible and the unenforceable provision shall be deemed modified to the limited extent required to permit its enforcement in a manner most closely representing the intentions as expressed herein;
              <br />
              <br />
              • All performers on this site are over the age of 18, have consented being photographed and/or filmed, believe it is their right to engage in consensual sexual acts for the entertainment and education of other adults and I believe it is my right as an adult to watch them doing what adults do;
              <br />
              <br />
              • The videos and images in this site are intended to be used by responsible adults as sexual aids, to provide sexual education and to provide sexual entertainment;
              <br />
              <br />
              • I understand that providing a false declaration under the penalties of perjury is a criminal offense; and
              <br />
              <br />
              • I agree that this agreement is governed by the Electronic Signatures in Global and National Commerce Act (commonly known as the "E-Sign Act"), 15 U.S.C. § 7000, et seq., and by choosing to click on "I Agree" and indicating my agreement to be bound by the terms of this agreement, I affirmatively adopt the signature line below as my signature and the manifestation of my consent to be bound by the terms of this agreement.
              <br />
            </p>
          </div>
        </div>
        <div className='bottom-wrap'>
          <div className='text-wrap'>
            <p className='bottom-text'>This site actively cooperates with law</p>
            <p className='bottom-text'>Enforcement in all instances of suspected</p>
            <p className='bottom-text'>Illegal use of the service, especially in the</p>
            <p className='bottom-text'>Case of underage usage of the service</p>
          </div>
          <div className='buttons-wrap'>
            <button
              className='button agree'
              onClick={() => close()}
              type='button'
            >
              <p className='agree-text'>I Agree</p>
            </button>
            <button
              className='button exit'
              onClick={() => window.location.replace('https://google.com')}
              type='button'
            >
              <p className='exit-text'>Exit</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

WelcomeModal.propTypes = ({
  closeModal: PropTypes.func.isRequired,
  info: PropTypes.object
})

export default WelcomeModal
