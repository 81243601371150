import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import categories from './categories/reducer'
import videos from './videos/reducer'
import comments from './comments/reducer'
import ads from './ads/reducer'
import cards from './cards/reducer'
import scripts from './scripts/reducer'
import frequency from './frequency/reducer'
import validation from './validation/reducer'
import users from './users/reducer'
import session from './session/reducer'
import platform from './platform/reducer'
import profile from './profiles/reducer'
import packs from './packs/reducer'
import payment from './payments/reducer'
import purchase from './purchase/reducer'
import tips from './tips/reducer'
import info from './about/reducer'
import report from './reports/reducer'
import guest from './guests/reducer'

const reducers = {
  validation,
  session,
  users,
  platform,
  info,
  profile,
  payment,
  purchase,
  packs,
  tips,
  categories,
  videos,
  comments,
  ads,
  cards,
  scripts,
  frequency,
  report,
  guest,
  form,
  toastr: toastrReducer
}

export default combineReducers(reducers)
