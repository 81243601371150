/* eslint-disable */

import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'
import App from './App'
import ErrorBoundary from './error'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById('root')
)

serviceWorker.register();
