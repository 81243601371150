import 'regenerator-runtime/runtime'
import { all, fork } from 'redux-saga/effects'

import categoriesSaga from './categories/sagas'
import videosSaga from './videos/sagas'
import commentsSaga from './comments/sagas'
import adsSaga from './ads/sagas'
import cardsSaga from './cards/sagas'
import scriptsSaga from './scripts/sagas'
import frequencySaga from './frequency/sagas'
import validationSaga from './validation/sagas'
import usersSaga from './users/sagas'
import profilesSaga from './profiles/sagas'
import paymentsSaga from './payments/sagas'
import purchaseSaga from './purchase/sagas'
import packsSaga from './packs/sagas'
import tipsSaga from './tips/sagas'
import sessionSaga from './session/sagas'
import platformSaga from './platform/sagas'
import infoSaga from './about/sagas'
import reportSaga from './reports/sagas'
import guestSaga from './guests/sagas'

const sagas = [
  sessionSaga,
  usersSaga,
  categoriesSaga,
  platformSaga,
  videosSaga,
  commentsSaga,
  adsSaga,
  cardsSaga,
  scriptsSaga,
  profilesSaga,
  paymentsSaga,
  purchaseSaga,
  packsSaga,
  tipsSaga,
  frequencySaga,
  infoSaga,
  validationSaga,
  reportSaga,
  guestSaga
]

export default function* (services = {}) {
  yield all(sagas.map(saga => fork(saga, services)))
}
