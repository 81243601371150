import * as actions from './actions'

const initialResourceState = {
  details: [],
  loading: false,
  errors: {}
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.SCRIPTS_INDEX_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.SCRIPTS_INDEX_SUCCESS:
    return {
      ...state,
      loading: false,
      details: payload
    }

  case actions.SCRIPTS_INDEX_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.RESET_SCRIPTS_STATE:
    return {
      ...state,
      loading: false,
      details: [],
      errors: {}
    }

  default:
    return state
  }
}
