/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'
import ReactGA from 'react-ga'

import CommentsModalAnswer from './reply'
import NoAvatar from '../../../../no-avatar.png'
import { history } from '../../../services'
import {
  CommentLikeIcon,
  CommentLikedIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ReplyIcon
} from '../../assets/svg'

import './styles.scss'

const CommentsModalComment = (props) => {
  const [repliesOpen, setRepliesOpen] = useState(false)
  const {
    id,
    avatar,
    username,
    message,
    is_liked,
    tip,
    tipMessage,
    replies,
    likes_count,
    commentSetLike,
    commentReplySetLike,
    reply,
    setReply,
    setReplyUsername,
    setMessage,
    currentUser
  } = props

  const showReplies = () => {
    setMessage('')
    if (repliesOpen) {
      setRepliesOpen(false)
      setReply()
      setReplyUsername('')
    } else {
      setRepliesOpen(true)
      setReply(id)
      setReplyUsername(username)
    }
  }

  const repliesButton = () => {
    if (repliesOpen && replies.length > 0) {
      return (
        <div>
          Hide ({replies.length}) <ArrowUpIcon />
        </div>
      )
    } else if (!repliesOpen && replies.length > 0) {
      return (
        <div>
          View replies ({replies.length}) <ArrowDownIcon />
        </div>
      )
    } else if (!isEmpty(currentUser) && reply === id) {
      return <div>Cancel</div>
    } else if (!isEmpty(currentUser) && reply !== id) {
      return (
        <div className='comment__reply-button'>
          Reply <ReplyIcon />
        </div>
      )
    } else {
      return null
    }
  }

  const handleLike = id => {
    commentSetLike(id)
    ReactGA.event({
      category: 'Comment like button',
      action: 'Comment liked'
    })
  }

  const avatarUrl = avatar && avatar.thumbnail.url

  return (
    <div className='comments-modal__body__comment__wrapper'>
      <div className='comments-modal__body__comment'>
        <a
          className='comment__avatar commentProfileBtn'
          onClick={() => history.push(`/${username}`)}
          role='button'
          tabIndex={0}
        >
          <img alt='' src={avatarUrl || NoAvatar} />
        </a>
        <div className='comment__wrapper'>
          <div className='comment__name'>{username}</div>
          <div className={`comment__message ${tip && 'comment__message--tip'}`}>{message}{tipMessage && ':'}</div>
          {tip && tipMessage && <div className='comment__tip-message'>{tipMessage}</div>}
          <div className='comment__actions'>
            <span
              className='comment__replies-button'
              onClick={() => showReplies()}
              role='button'
              tabIndex={0}
            >
              {repliesButton()}
            </span>
          </div>
        </div>
        <a className='comment__like commentlikeBtn' role='button'>
          {is_liked ? (
            <CommentLikedIcon onClick={() => handleLike(id)} />
          ) : (
            <CommentLikeIcon onClick={() => handleLike(id)} />
          )}
          <span>{likes_count > 0 && likes_count}</span>
        </a>
      </div>
      {repliesOpen &&
        replies.map(({ id, attributes }) => (
          <CommentsModalAnswer
            key={id}
            avatar={attributes.user.avatar}
            commentReplySetLike={commentReplySetLike}
            id={id}
            isLiked={attributes.is_liked}
            likesCount={attributes.likes_count}
            message={attributes.message}
            username={attributes.user.username}
          />
        ))}
    </div>
  )
}

CommentsModalComment.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  replies: PropTypes.array,
  tip: PropTypes.bool.isRequired,
  tipMessage: PropTypes.string,
  is_liked: PropTypes.bool.isRequired,
  likes_count: PropTypes.number.isRequired,
  commentSetLike: PropTypes.func.isRequired,
  commentReplySetLike: PropTypes.func.isRequired,
  reply: PropTypes.string,
  setReply: PropTypes.func.isRequired,
  setReplyUsername: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
}

export default CommentsModalComment
