import * as React from 'react'

const SendIcon = (props) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.01 21.5L23 12.5L2.01 3.5L2 10.5L17 12.5L2 14.5L2.01 21.5Z'
        fill='#9195A9'
      />
    </svg>
  )
}

export default SendIcon
