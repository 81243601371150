import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'
import { history } from '../../../services'
import {
  BackArrowIcon, BookWhiteIcon, CommunityWhiteIcon, PrivacyWhiteIcon,
  CopyrightWhiteIcon, ShareWhiteIcon, TokenWhiteIcon, TwitterWhiteIcon,
  BookBlackIcon, CommunityBlackIcon, TokenBlackIcon, PrivacyBlackIcon, CopyrightBlackIcon,
  TwitterBlackIcon, ShareBlackIcon, ArrowRightIcon
} from '../../assets/svg'

const About = ({ info, onShare }) => {
  const [touched, setTouched] = useState('')

  return (
    <div className='about-container'>
      <div className='header'>
        <div className='arrow-wrap'>
          <BackArrowIcon className='back-arrow' onClick={() => history.goBack()} />
        </div>
        <div className='title-wrap'>
          <p className='title'>
            We’re
            <b> Qwki </b>
            - a new way to discover, watch, and share sexy moments. Come in, get off, and tell your friends!
          </p>
        </div>
      </div>
      <div className='main-wrap'>
        <div className='row'>
          <div className='side-color color-1' style={touched === 'Terms' ? { visibility: 'visible' } : {}} />
          <div className='left-color-1' />
          <div className='middle-color-1' />
          <button
            className='row-title border'
            onClick={() => history.push('/quill', { title: 'Terms of Use', data: info.terms_of_use })}
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Terms')}
            style={touched === 'Terms' ? { background: '#FFFFFF', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Terms'
                ? <BookBlackIcon className='icon-item' />
                : <BookWhiteIcon className='icon-item' />
            }
            Terms Of Use
            <ArrowRightIcon className='arrow-right' />
          </button>
        </div>
        <div className='row'>
          <div className='side-color color-2' style={touched === 'Community' ? { visibility: 'visible' } : {}} />
          <div className='left-color-2' />
          <div className='middle-color-2' />
          <button
            className='row-title border'
            onClick={() => history.push('/quill', { title: 'Community Guidelines', data: info.community_guidlines })}
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Community')}
            style={touched === 'Community' ? { background: '#FCFD00', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Community'
                ? <CommunityBlackIcon className='icon-item' />
                : <CommunityWhiteIcon className='icon-item' />
            }
            Community Guidelines
            <ArrowRightIcon className='arrow-right' />
          </button>
        </div>
        <div className='row'>
          <div className='side-color color-3' style={touched === 'Privacy' ? { visibility: 'visible' } : {}} />
          <div className='left-color-3' />
          <div className='middle-color-3' />
          <button
            className='row-title border'
            onClick={() => history.push('/quill', { title: 'Privacy Policy', data: info.privacy_policy })}
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Privacy')}
            style={touched === 'Privacy' ? { background: '#01FFFC', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Privacy'
                ? <PrivacyBlackIcon className='icon-item' />
                : <PrivacyWhiteIcon className='icon-item' />
            }
            Privacy Policy
            <ArrowRightIcon className='arrow-right' />
          </button>
        </div>
        <div className='row'>
          <div className='side-color color-4' style={touched === 'Copyright' ? { visibility: 'visible' } : {}} />
          <div className='left-color-4' />
          <div className='middle-color-4' />
          <button
            className='row-title border'
            onClick={() => history.push('/quill', { title: 'Copyright Policy', data: info.copyright_policy })}
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Copyright')}
            style={touched === 'Copyright' ? { background: '#00FE00', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Copyright'
                ? <CopyrightBlackIcon className='icon-item' />
                : <CopyrightWhiteIcon className='icon-item' />
            }
            Copyright Policy
            <ArrowRightIcon className='arrow-right' />
          </button>
        </div>
        <div className='row'>
          <div className='side-color color-5' style={touched === 'Token' ? { visibility: 'visible' } : {}} />
          <div className='left-color-5' />
          <div className='middle-color-5' />
          <button
            className='row-title border'
            onClick={() => history.push('/quill', { title: 'Token Policy', data: info.token_policy })}
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Token')}
            style={touched === 'Token' ? { background: '#FF02FB', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Token'
                ? <TokenBlackIcon className='icon-item' />
                : <TokenWhiteIcon className='icon-item' />
            }
            Token Policy
            <ArrowRightIcon className='arrow-right' />
          </button>
        </div>
        <div className='row'>
          <div className='side-color color-6' style={touched === 'Twitter' ? { visibility: 'visible' } : {}} />
          <div className='left-color-6' />
          <div className='middle-color-6' />
          <button
            className='row-title border'
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Twitter')}
            style={touched === 'Twitter' ? { background: '#FA0001', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Twitter'
                ? <TwitterBlackIcon className='icon-item' />
                : <TwitterWhiteIcon className='icon-item' />
            }
            <a
              className='link'
              href={info.twitter_link}
              style={touched === 'Twitter' ? { color: '#000000' } : { color: '#FFFFFF' }}
            >
              Find us on Twitter
              <ArrowRightIcon className='arrow-right' />
            </a>
          </button>
        </div>
        <div className='row'>
          <div className='side-color color-7' style={touched === 'Share' ? { visibility: 'visible' } : {}} />
          <div className='left-color-7' />
          <div className='middle-color-7' />
          <button
            className='row-title border'
            onClick={() => onShare()}
            onTouchEnd={() => setTouched('')}
            onTouchStart={() => setTouched('Share')}
            style={touched === 'Share' ? { background: '#0100E4', color: '#000000' } : {}}
            type='button'
          >
            {
              touched === 'Share'
                ? <ShareBlackIcon className='icon-item' />
                : <ShareWhiteIcon className='icon-item' />
            }
            Share QWKI
            <ArrowRightIcon className='arrow-right' />
          </button>
        </div>
      </div>
    </div>
  )
}

About.propTypes = ({
  info: PropTypes.object.isRequired,
  onShare: PropTypes.func.isRequired
})

export default About
