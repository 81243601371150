import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'

export const useScrollBlock = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    body.style.cssText = isMobile && 'position: fixed; width: 100%; height: 100%'
    return () => body.style = null
  }, [])
}
