import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'
import { scriptsIndexRequest } from '../scripts/actions'

function* updatePlatform({ data }, { resource }) {
  try {
    const detail = yield request(resource, 'put', data)
    yield put(actions.platformUpdateSuccess(detail))
    yield put(scriptsIndexRequest())
  } catch (e) {
    yield put(actions.platformUpdateFailure(e))
  }
}

function* watchPlatformUpdateRequest({ payload, meta }) {
  yield call(updatePlatform, payload, meta)
}

export default function* () {
  yield takeLatest(actions.PLATFORM_UPDATE_REQUEST, watchPlatformUpdateRequest)
}
