import * as React from 'react'

const CardChip = (props) => {
  return (
    <svg
      width='30'
      height='22'
      viewBox='0 0 30 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='30' height='22' rx='5' fill='white' fillOpacity='0.4' />
      <path
        d='M12.5 4V18'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 8.5H12'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 13.5H12'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 4V18'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.5 8.5H18.5'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.5 13.5H18.5'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CardChip
