const resource = 'packs'

export const GET_PACKS_REQUEST = 'GET_PACKS_REQUEST'
export const GET_PACKS_SUCCESS = 'GET_PACKS_SUCCESS'
export const GET_PACKS_FAILURE = 'GET_PACKS_FAILURE'

export const packsIndexRequest = () => ({
  type: GET_PACKS_REQUEST,
  meta: {
    resource
  }
})

export const packsIndexSuccess = (detail) => ({
  type: GET_PACKS_SUCCESS,
  payload: detail
})

export const packsIndexFailure = (error) => ({
  type: GET_PACKS_FAILURE,
  error: true,
  payload: error
})

export const GET_PACK_REQUEST = 'GET_PACK_REQUEST'
export const GET_PACK_SUCCESS = 'GET_PACK_SUCCESS'
export const GET_PACK_FAILURE = 'GET_PACK_FAILURE'

export const packGetRequest = (amount) => ({
  type: GET_PACK_REQUEST,
  meta: {
    resource: `${resource}/${amount}`
  }
})

export const packGetSuccess = (detail) => ({
  type: GET_PACK_SUCCESS,
  payload: detail
})

export const packGetFailure = (error) => ({
  type: GET_PACK_FAILURE,
  error: true,
  payload: error
})
