import { put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services'
import * as actions from './actions'

function* createReport({ data }, { resource }) {
  try {
    const detail = yield request(resource, 'post', data)
    yield put(actions.reportCreateSuccess(detail))
  } catch (e) {
    yield put(actions.reportCreateFailure(e))
  }
}

function* watchReportCreateRequest({ payload, meta }) {
  yield call(createReport, payload, meta)
}

export default function* () {
  yield takeLatest(actions.REPORT_CREATE_REQUEST, watchReportCreateRequest)
}
