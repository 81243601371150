import React from 'react'
import PropTypes from 'prop-types'

import {
  CardChip,
  MasterType,
  VisaType,
  DiscType,
  AmexType
} from '../../../assets/svg'
import '../styles.scss'

const PurchaseCardsItem = (props) => {
  const { card, isActive } = props

  const handleType = (type) => {
    switch (type) {
    case 'Visa':
      return <VisaType />
    case 'MasterCard':
      return <MasterType />
    case 'AmericanExpress':
      return <AmexType />
    case 'Discover':
      return <DiscType />
    default:
      return type
    }
  }

  return (
    <div className={`purchase__card ${isActive && 'purchase__card-active'}`}>
      <div className='purchase__card__section'>
        <div className='purchase__card__type'>
          <div className='purchase__card__icon'>{handleType(card.brand)}</div>
          <CardChip />
        </div>
      </div>
      <div className='purchase__card__section'>
        <div className='purchase__card__holder'>
          {card.first_name} <br />
          {card.last_name}
        </div>
      </div>
      <div className='purchase__card__section'>
        <div className='purchase__card__number'>
          <span>CARD NUMBER</span>
          ****{card.number.replace(/X/g, '')}
        </div>
      </div>
    </div>
  )
}

PurchaseCardsItem.propTypes = {
  card: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired
}

export default PurchaseCardsItem
