import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'

import {
  packGetRequest,
  cardsIndexRequest,
  paymentCreateRequest,
  addCardRequest
} from '../store/actions'
import { history } from '../services'
import { Purchase } from '../components/pages'
import { resetPaymentState } from '../store/payments/actions'

class PurchaseContainer extends Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      videoId: null
    }
  }

  componentDidMount() {
    const videoId = localStorage.getItem('currentVideoId')
    if (
      isEmpty(this.props.currentGuest) &&
      !isEmpty(this.props.currentUser) &&
      this.props.match.params.amount
    ) {
      if (videoId) {
        this.setState({ videoId })
      }
      this.props.getPack(this.props.match.params.amount)
      this.props.getCards()
    } else {
      history.push('/')
    }
  }

  render() {
    return (
      <Purchase
        addCard={this.props.addCard}
        cards={this.props.cards}
        cardsError={this.props.cardsError}
        cardsLoading={this.props.cardsLoading}
        createPayment={this.props.createPayment}
        currentGuest={this.props.currentGuest}
        currentUser={this.props.currentUser}
        handleSubmit={this.handleSubmit}
        pack={this.props.pack}
        packLoading={this.props.packLoading}
        paymentError={this.props.paymentError}
        paymentLoading={this.props.paymentLoading}
        paymentMessage={this.props.paymentMessage}
        paymentOpen={this.props.paymentOpen}
        resetPaymentState={this.props.resetPaymentState}
        videoId={this.state.videoId}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest,
  cards: state.cards.details,
  cardsLoading: state.cards.loading,
  cardsError: state.cards.error,
  pack: state.packs.detail,
  packLoading: state.packs.packLoading,
  paymentLoading: state.payment.loading,
  paymentMessage: state.payment.detail.message,
  paymentError: state.payment.detail.error,
  paymentOpen: state.payment.paymentOpen
})

const mapDispatchToProps = (dispatch) => ({
  getPack: (amount) => dispatch(packGetRequest(amount)),
  getCards: () => dispatch(cardsIndexRequest()),
  addCard: (data) => dispatch(addCardRequest(data)),
  createPayment: (data) => dispatch(paymentCreateRequest(data)),
  resetPaymentState: () => dispatch(resetPaymentState())
})

PurchaseContainer.propTypes = {
  currentUser: PropTypes.object,
  currentGuest: PropTypes.object,
  getPack: PropTypes.func.isRequired,
  getCards: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  createPayment: PropTypes.func.isRequired,
  resetPaymentState: PropTypes.func.isRequired,
  pack: PropTypes.object.isRequired,
  packLoading: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  cardsLoading: PropTypes.bool.isRequired,
  cardsError: PropTypes.object.isRequired,
  paymentLoading: PropTypes.bool.isRequired,
  paymentError: PropTypes.string,
  paymentMessage: PropTypes.string,
  paymentOpen: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseContainer)
