import * as EmailValidator from 'email-validator'

const checkPrice = (
  locked, price, changed, setValidPrice, setErrorPrice
) => {
  if (!locked) {
    setValidPrice(true)
    setErrorPrice('')
  } else if (Number(price) > 0 && Number(price) <= 1000) {
    setValidPrice(true)
    setErrorPrice('')
  } else if (Number(price) > 0 && Number(price) > 1000) {
    setValidPrice(false)
    setErrorPrice('Max tokens amount: 1000')
  } else if (!price && changed === 'price') {
    setValidPrice(false)
    setErrorPrice('Is required.')
  } else if (!price) {
    setValidPrice(false)
    setErrorPrice('')
  } else {
    setValidPrice(false)
    setErrorPrice('Invalid value.')
  }
}

const checkEmail = (
  email, focusedInput, changed, emailPresent, setValidEmail, setErrorEmail
) => {
  if (email.length && EmailValidator.validate(email) && focusedInput !== 'email') {
    if (focusedInput !== 'email' && changed === 'email') {
      if (!emailPresent) {
        setValidEmail(true)
        setErrorEmail('')
      } else {
        setValidEmail(false)
        setErrorEmail(`${email} already in use. Please login.`)
      }
    }
  } else if (email.length && !EmailValidator.validate(email) && focusedInput !== 'email') {
    setValidEmail(false)
    setErrorEmail('Not a valid email')
  } else if (!email && changed === 'email') {
    setValidEmail(false)
    setErrorEmail('Is required.')
  } else if (changed === 'email' && focusedInput === 'email') {
    setValidEmail(false)
    setErrorEmail('')
  } else if (!email) {
    setValidEmail(false)
    setErrorEmail('Is required.')
  }
}

const checkUsername = (
  username, focusedInput, changed, usernamePresent, setValidUsername, setErrorUsername
) => {
  const regex = /^[0-9a-zA-Z_.-]*$/
  if (username.length >= 4 && username.length <= 25 && regex.test(username) && focusedInput !== 'username') {
    if (focusedInput !== 'username' && changed === 'username') {
      if (!regex.test(username)) {
        setValidUsername(false)
        setErrorUsername('Not a valid username.')
      } else if (!usernamePresent) {
        setValidUsername(true)
        setErrorUsername('')
      } else {
        setValidUsername(false)
        setErrorUsername('Username already taken.')
      }
    }
  } else if (!username && changed === 'username') {
    setValidUsername(false)
    setErrorUsername('Is required.')
  } else if ((username.length > 0 && username.length < 4)) {
    setValidUsername(false)
    setErrorUsername('Username must be 4-25 characters.')
  } else if (!regex.test(username) && changed === 'username') {
    setValidUsername(false)
    setErrorUsername('No spaces or symbols.')
  } else if (changed === 'username' && focusedInput === 'username') {
    setValidUsername(false)
    setErrorUsername('')
  } else if (!username) {
    setValidUsername(false)
    setErrorUsername('Is required.')
  }
}

const checkPassword = (
  password, changed, setValidPassword, setErrorPassword
) => {
  const passwrodRegex = /^(?=.*[\d])(?=.*[a-z])[\w!@#$%^&*-/= ]{8,}$/
  if (password.length >= 8 && passwrodRegex.test(password)) {
    setValidPassword(true)
    setErrorPassword('')
  } else if (password.length > 0 && password.length < 8) {
    setValidPassword(false)
    setErrorPassword('Min. 8 characters, at least 1 letter and number.')
  } else if (!password && changed === 'password') {
    setValidPassword(false)
    setErrorPassword('Is required')
  } else if (!passwrodRegex.test(password) && changed === 'password') {
    setValidPassword(false)
    setErrorPassword('Min. 8 characters, at least 1 letter and number.')
  } else if (!password) {
    setValidPassword(false)
    setErrorPassword('Is required')
  }
}

const checkDescription = (
  description, changed, forbiddenСharacters, setValidDescription, setErrorDescription
) => {
  if (description.length - forbiddenСharacters >= 10) {
    setValidDescription(true)
    setErrorDescription('')
  } else if (!description && changed === 'description') {
    setValidDescription(false)
    setErrorDescription('Description is required.')
  } else if (!description) {
    setValidDescription(false)
    setErrorDescription('Description is required.')
  } else {
    setValidDescription(false)
    setErrorDescription('Min 10 characters')
  }
}

const descriptionLengthValid = (
  description, forbiddenСharacters, setValidDescription, setErrorDescription
) => {
  if (!description) {
    setValidDescription(false)
    setErrorDescription('Description is required.')
  } else if (description.length - forbiddenСharacters < 10) {
    setValidDescription(false)
    setErrorDescription('Minimum 10 character description required. #hastags ok.')
  }
}

const checkCategories = (selectedCategories, setSelectedCategories, setErrorCategories) => {
  if (selectedCategories === null) {
    setSelectedCategories(null)
    setErrorCategories('Select a category for your video')
  }
}

export {
  checkPrice,
  checkEmail,
  checkUsername,
  checkPassword,
  checkDescription,
  descriptionLengthValid,
  checkCategories
}
