import * as React from 'react'

const GridVideosIcon = (props) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='4' height='4' fill='#9A9A9A' />
      <rect x='7' width='4' height='4' fill='#9A9A9A' />
      <rect x='14' width='4' height='4' fill='#9A9A9A' />
      <rect y='7' width='4' height='4' fill='#9A9A9A' />
      <rect x='7' y='7' width='4' height='4' fill='#9A9A9A' />
      <rect x='14' y='7' width='4' height='4' fill='#9A9A9A' />
      <rect y='14' width='4' height='4' fill='#9A9A9A' />
      <rect x='7' y='14' width='4' height='4' fill='#9A9A9A' />
      <rect x='14' y='14' width='4' height='4' fill='#9A9A9A' />
    </svg>
  )
}

export default GridVideosIcon
