import * as actions from './actions'

const initialResourceState = {
  details: [],
  hasMore: true,
  commentsLoading: false,
  commentLoading: false,
  likeLoading: false,
  errors: {}
}

const createCommentDetails = (payload, state) => {
  if (payload.response.data.attributes.comment_id) {
    state.details.map((comment) => {
      if (comment.id.toString() === payload.response.data.attributes.comment_id.toString()) {
        comment.attributes.replies.data = [
          ...comment.attributes.replies.data,
          { ...payload.response.data }
        ]
      }
      return comment
    })
    return [...state.details]
  } else {
    return [{ ...payload.response.data }, ...state.details]
  }
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.COMMENTS_INDEX_REQUEST:
    return {
      ...state,
      commentsLoading: true
    }

  case actions.COMMENTS_INDEX_SUCCESS:
    return {
      ...state,
      commentsLoading: false,
      hasMore: payload.has_more,
      details: [...state.details, ...payload.response.data]
    }

  case actions.COMMENTS_CLEAR_REQUEST:
    return {
      ...state,
      hasMore: true,
      details: []
    }

  case actions.COMMENTS_INDEX_FAILURE:
    return {
      ...state,
      commentsLoading: false,
      hasMore: false,
      errors: payload
    }

  case actions.COMMENT_LIKE_REQUEST:
    return {
      ...state,
      likeLoading: true
    }

  case actions.COMMENT_LIKE_SUCCESS:
    return {
      ...state,
      likeLoading: false,
      details: state.details.map((item) => {
        if (item.id === payload.data.id) {
          item.attributes.likes_count = payload.data.attributes.likes_count
          item.attributes.is_liked = payload.data.attributes.is_liked
          return item
        } else {
          return item
        }
      })
    }

  case actions.COMMENT_LIKE_FAILURE:
    return {
      ...state,
      likeLoading: false,
      errors: payload
    }

  case actions.COMMENT_REPLY_LIKE_REQUEST:
    return {
      ...state,
      likeLoading: true
    }

  case actions.COMMENT_REPLY_LIKE_SUCCESS:
    return {
      ...state,
      likeLoading: false,
      details: state.details.map((item) => {
        if (item.id === payload.data.attributes.comment_id.toString()) {
          item.attributes.replies.data.map((reply) => {
            if (reply.id === payload.data.id.toString()) {
              reply.attributes.likes_count =
                  payload.data.attributes.likes_count
              reply.attributes.is_liked = payload.data.attributes.is_liked
            }
            return reply
          })
          return item
        } else {
          return item
        }
      })
    }

  case actions.COMMENT_REPLY_LIKE_FAILURE:
    return {
      ...state,
      likeLoading: false,
      errors: payload
    }

  case actions.CREATE_COMMENT_REQUEST:
    return {
      ...state,
      commentLoading: true
    }

  case actions.CREATE_COMMENT_SUCCESS:
    return {
      ...state,
      commentLoading: false,
      details: createCommentDetails(payload, state)
    }

  case actions.CREATE_COMMENT_FAILURE:
    return {
      ...state,
      commentCreated: false,
      commentLoading: false,
      errors: payload
    }

  default:
    return state
  }
}
