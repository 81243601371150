/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import PulseLoader from 'react-spinners/PulseLoader'

import {
  ModalCloseIcon,
  CheckIcon,
  NoCheckIcon,
  FocusMoneyIcon,
  UnFocusMoneyIcon,
  FocusEmailIcon,
  UnFocusEmailIcon
} from '../../assets/svg'
import SuccessGif from '../../assets/success-tip.gif'
import './styles.scss'

const TipModal = (props) => {
  const {
    showTipModal,
    handleCloseModal,
    handleOpenRefillModal,
    createTip,
    tipLoading,
    tipCreated,
    resetTipState,
    videoId,
    receiver,
    currentUser
  } = props
  const [timeoutId, setTimeoutId] = useState(null)
  const [changed, setChanged] = useState('')
  const [focusedInput, setFocusedInput] = useState('')
  const [amount, setAmount] = useState('')
  const [message, setMessage] = useState('')
  const [anon, setAnon] = useState(false)
  const [validAmount, setValidAmount] = useState(false)
  const [errorAmount, setErrorAmount] = useState('')
  const [validMessage, setValidMessage] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const regex = /^(0|[1-9]\d*)$/

  const closeTimeOut = () => {
    setTimeoutId(setTimeout(() => handleCloseModal(), 3500))
  }

  useEffect(() => {
    if (!showTipModal) {
      clearTimeout(timeoutId)
    } else if (!tipLoading && tipCreated) {
      closeTimeOut()
    }
  }, [showTipModal, tipLoading, tipCreated])

  const clearState = () => {
    setAmount('')
    setMessage('')
    setAnon(false)
  }

  useEffect(() => {
    resetTipState()
    clearState()
  }, [showTipModal])

  useEffect(() => {
    if (focusedInput === 'amount' && changed === 'amount') {
      if (!amount) {
        setValidAmount(false)
        setErrorAmount('Is required.')
      } else if (amount > currentUser.tokens_count) {
        setValidAmount(false)
        setErrorAmount('Not enough tokens')
      } else if (!regex.test(amount)) {
        setValidAmount(false)
        setErrorAmount('Invalid value')
      } else {
        setValidAmount(true)
        setErrorAmount('')
      }
    }
  }, [amount, changed, focusedInput])

  useEffect(() => {
    if (message) {
      if (message.length > 200 || message.length < 2) {
        setValidMessage(false)
        setErrorMessage('Message must be 2-200 characters.')
      } else {
        setValidMessage(true)
        setErrorMessage('')
      }
    } else {
      setValidMessage(true)
      setErrorMessage('')
    }
  }, [message, changed, focusedInput])

  const errors = {
    amount: errorAmount,
    message: errorMessage
  }

  const reloadGif = () => {
    const gif = document.getElementById('success-gif')
    if (gif) {
      const gifSrc = gif.src
      gif.src = gifSrc
    }
  }

  useEffect(() => {
    if (!tipLoading && tipCreated) {
      reloadGif()
    }
  }, [tipLoading, tipCreated])

  const handleSubmit = () => {
    if (validAmount && validMessage) {
      const sendData = {
        video: videoId,
        sender: currentUser.username,
        receiver,
        amount,
        message,
        anon
      }
      createTip(sendData)
    }
  }

  return (
    <Modal
      ariaHideApp={false}
      className='tip-modal'
      contentLabel='onRequestClose Example'
      isOpen={showTipModal}
      onRequestClose={handleCloseModal}
      overlayClassName='Overlay'
    >
      <div className='tip-modal__wrapper'>
        <div className='tip-modal__title'>
          <a className='tip-modal__title__close tipCloseBtn'>
            Your current balance:
            <ModalCloseIcon onClick={() => handleCloseModal()} />
          </a>
          <div className='tip-modal__title__tokens'>
            {currentUser.tokens_count} tokens
          </div>
        </div>
        {tipLoading && (
          <div className='tip-modal__loader'>
            <PulseLoader color='#9a9a9a' size={20} />
          </div>
        )}
        {!tipLoading && tipCreated && (
          <div className='tip-modal__success'>
            <div className='tip-modal__success__icon'>
              <img alt='tip-success' id='success-gif' src={SuccessGif} />
            </div>
          </div>
        )}
        {!tipLoading && !tipCreated && (
          <>
            <form className='tip-modal__form'>
              <div className='inputs'>
                <div className='input-wrap'>
                  {focusedInput === 'amount' || amount.length ? (
                    <FocusMoneyIcon className='icon' heigth='22' width='22' />
                  ) : (
                    <UnFocusMoneyIcon className='icon' heigth='22' width='22' />
                  )}
                  {errors.amount && (
                    <>
                      <p className='error-text'>{errors.amount}</p>
                    </>
                  )}
                  <input
                    autoComplete='off'
                    className='input'
                    maxLength='25'
                    onBlur={() => {
                      setFocusedInput('')
                    }}
                    onChange={(e) => {
                      setAmount(e.target.value)
                      setChanged('amount')
                    }}
                    onFocus={() => {
                      setFocusedInput('amount')
                    }}
                    placeholder='Enter token amount'
                    type='tel'
                    value={amount}
                  />
                  <div className='inputs-hr' />
                </div>
                <div className='input-wrap'>
                  {focusedInput === 'message' || message.length ? (
                    <FocusEmailIcon className='icon' heigth='22' width='22' />
                  ) : (
                    <UnFocusEmailIcon className='icon' heigth='22' width='22' />
                  )}
                  {errors.message && (
                    <>
                      <p className='error-text'>{errors.message}</p>
                    </>
                  )}
                  <input
                    autoComplete='off'
                    className='input'
                    maxLength='60'
                    onBlur={() => {
                      setFocusedInput('')
                    }}
                    onChange={(e) => {
                      setMessage(e.target.value)
                      setChanged('message')
                    }}
                    onFocus={() => {
                      setFocusedInput('message')
                    }}
                    placeholder='Include optional message'
                    type='text'
                    value={message}
                  />
                  <div className='inputs-hr' />
                </div>
                <div
                  className='tip-modal__form__check'
                  onClick={() => setAnon(!anon)}
                  role='button'
                  tabIndex={0}
                >
                  {anon ? <CheckIcon /> : <NoCheckIcon />}
                  <div className='tip-modal__form__check__content'>
                    <p className='tip-modal__form__check__text plus'>
                      Anonymous Tipping
                    </p>
                    <p className='tip-modal__form__check__text'>
                      Username only visible to creator
                    </p>
                  </div>
                </div>
              </div>
            </form>
            <div className='tip-modal__form__actions'>
              <a className='tipSubmitBtn' role='button'>
                <button
                  disabled={!validAmount || !validMessage}
                  onClick={() => handleSubmit()}
                  type='button'
                >
                  TIP
                </button>
              </a>
              <a className='tipPurchaseBtn' role='button'>
                <button
                  className='cancel'
                  onClick={() => handleOpenRefillModal()}
                  type='button'
                >
                  Purchase tokens
                </button>
              </a>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

TipModal.propTypes = {
  showTipModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  handleOpenRefillModal: PropTypes.func,
  createTip: PropTypes.func.isRequired,
  tipLoading: PropTypes.bool.isRequired,
  tipCreated: PropTypes.bool.isRequired,
  resetTipState: PropTypes.func.isRequired,
  videoId: PropTypes.string,
  receiver: PropTypes.string.isRequired,
  currentUser: PropTypes.object
}

export default TipModal
