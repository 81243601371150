import * as React from 'react'

const MoreDotsIcon = (props) => {
  return (
    <svg
      width='3'
      height='13'
      viewBox='0 0 3 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle cx='1.5' cy='1.5' r='1.5' fill='#E3E3E3' />
      <circle cx='1.5' cy='6.5' r='1.5' fill='#E3E3E3' />
      <circle cx='1.5' cy='11.5' r='1.5' fill='#E3E3E3' />
    </svg>
  )
}

export default MoreDotsIcon
