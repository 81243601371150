import * as actions from './actions'

const initialResourceState = {
  loading: false,
  currentUser: {},
  errors: {},
  isCreated: false
}

export default (state = initialResourceState, { type, payload }) => {
  switch (type) {
  case actions.SESSION_CREATE_REQUEST:
    return {
      ...state,
      loading: true,
      errors: {}
    }

  case actions.SESSION_CREATE_SUCCESS:
    return {
      ...state,
      loading: false,
      isCreated: true,
      currentUser: payload.data.attributes
    }

  case actions.SESSION_CREATE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload.response.data
    }

  case actions.SESSION_SHOW_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.SESSION_SHOW_SUCCESS:
    return {
      ...state,
      loading: false,
      currentUser: payload.data.attributes
    }

  case actions.SESSION_SHOW_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  case actions.SESSION_DELETE_REQUEST:
    return {
      ...state,
      loading: true
    }

  case actions.SESSION_DELETE_SUCCESS:
    return {
      ...state,
      loading: false,
      currentUser: {}
    }

  case actions.SESSION_DELETE_FAILURE:
    return {
      ...state,
      loading: false,
      errors: payload
    }

  default:
    return state
  }
}
