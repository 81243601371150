import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'

import Card from './item'
import { CheckIcon, NoCheckIcon } from '../../../assets/svg'
import '../styles.scss'

const PurchaseCards = (props) => {
  const { cards, pack } = props
  const [swiper, updateSwiper] = useState(null)
  const [updateBool, setUpdateBool] = useState(false)
  const [activeCard, setActiveCard] = useState(0)
  const [agree, setAgree] = useState(false)

  useEffect(() => {
    if (swiper) {
      setActiveCard(swiper.realIndex)
    }
  }, [updateBool])

  const forceUpdate = () => {
    setUpdateBool((updateBool) => !updateBool)
  }

  const handlePick = (index) => {
    if (swiper) {
      swiper.slideTo(index)
    }
  }

  const handleSubmit = () => {
    const formData = new FormData()
    formData.append('card_id', cards[activeCard].id)
    formData.append('pack_id', pack.id)
    props.createPayment(formData)
    props.setPaymentModal(true)
  }

  const params = {
    spaceBetween: 25,
    centeredSlides: true,
    slidesPerView: 'auto',
    shouldSwiperUpdate: true,
    getSwiper: updateSwiper,
    on: {
      slideChangeTransitionStart: forceUpdate
    }
  }

  return (
    <>
      <div className='purchase__cards'>
        <Swiper {...params}>
          {cards.map((card, index) => (
            <div
              key={card.id}
              className='swiper-slide'
              onClick={() => handlePick(index)}
              role='button'
              tabIndex={0}
            >
              <Card card={card} isActive={index === activeCard} />
            </div>
          ))}
        </Swiper>
      </div>
      <div className='purchase__content__wrapper'>
        <div
          className='purchase__check'
          onClick={() => setAgree(!agree)}
          role='button'
          tabIndex={0}
        >
          {agree ? <CheckIcon /> : <NoCheckIcon />}
          <div className='purchase__check__content'>
            <p className='purchase__check__text'>
              By submitting this form, you agree to our Terms of Use, certify
              that you are 18 years of age or older and understand the terms of
              billing.
            </p>
          </div>
        </div>
        <div className='purchase__actions'>
          <button
            disabled={props.paymentLoading || !agree}
            onClick={() => handleSubmit()}
            type='button'
          >
            Submit payment
          </button>
          <button className='cancel' onClick={() => props.handleBack()} type='button'>
            Cancel
          </button>
        </div>
        <div className='purchase__info'>
          <p>Qwki will appear on your billing statement.</p>
          <p>
            You agree that Qwki will store your payment information so that you
            may make future purchases without re-entering the full payment
            information.
          </p>
          <p>Support@qwki.me • Qwki • San Francisco, CA</p>
        </div>
      </div>
    </>
  )
}

PurchaseCards.propTypes = {
  cards: PropTypes.array,
  pack: PropTypes.object.isRequired,
  createPayment: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  paymentLoading: PropTypes.bool.isRequired,
  setPaymentModal: PropTypes.bool.isRequired
}

export default PurchaseCards
