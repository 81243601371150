const resource = 'cards'

export const GET_CARDS_REQUEST = 'GET_CARDS_REQUEST'
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS'
export const GET_CARDS_FAILURE = 'GET_CARDS_FAILURE'

export const cardsIndexRequest = () => ({
  type: GET_CARDS_REQUEST,
  meta: {
    resource
  }
})

export const cardsIndexSuccess = (details) => ({
  type: GET_CARDS_SUCCESS,
  payload: details
})

export const cardsIndexFailure = (error) => ({
  type: GET_CARDS_FAILURE,
  error: true,
  payload: error
})

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST'
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS'
export const ADD_CARD_FAILURE = 'ADD_CARD_FAILURE'

export const addCardRequest = (data) => ({
  type: ADD_CARD_REQUEST,
  payload: data,
  meta: {
    resource
  }
})

export const addCardSuccess = (detail) => ({
  type: ADD_CARD_SUCCESS,
  payload: detail
})

export const addCardFailure = (detail) => ({
  type: ADD_CARD_FAILURE,
  error: true,
  payload: detail
})
