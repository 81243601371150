/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Swipe from 'react-easy-swipe'
import Linkify from 'react-linkify'
import MoonLoader from 'react-spinners/MoonLoader'
import isEmpty from 'lodash.isempty'
import ReactGA from 'react-ga'

import ProfileVideos from './videos'
import { TipModal, RefillModal, ProfileShareModal } from '../../molecules'
import NoAvatar from '../../../../no-avatar.png'
import {
  CoinIcon,
  SecondBackArrowIcon,
  ShareIcon,
  EditIcon,
  MoneyIcon
} from '../../assets/svg'
import { history } from '../../../services'
import './styles.scss'

const Profile = (props) => {
  const [shareVideoId, setShareVideoId] = useState(0)
  const [shareProfile, setShareProfile] = useState(true)
  const [showShareModal, setShareModal] = useState(false)
  const [showTipModal, setTipModal] = useState(false)
  const [showRefillModal, setRefillModal] = useState(false)
  const {
    getVideos,
    deleteVideo,
    resetProfileVideos,
    onDeleteSession,
    videos,
    videosLoading,
    profile,
    profileLoading,
    isOwn,
    hasMoreVideos,
    currentUser,
    currentGuest,
    createTip,
    tipLoading,
    tipCreated,
    resetTipState,
    getUser,
    getPacks,
    packs,
    packsLoading
  } = props

  useEffect(() => {
    if (profile.username) {
      ReactGA.event({
        category: 'Profile',
        action: 'Profile opened'
      })
    }
  }, [profile])

  const handleCloseModal = () => {
    document.querySelector('.ReactModal__Overlay').style.cssText =
      'bottom:-100% !important'
    setTimeout(() => {
      setTipModal(false)
      setRefillModal(false)
      setShareModal(false)
      setShareVideoId(0)
      setShareProfile(true)
    }, 600)
  }

  const handleShareProfile = () => {
    setShareProfile(true)
    setShareModal(true)
  }

  const handleShareVideo = (id) => {
    setShareProfile(false)
    setShareVideoId(id)
    setShareModal(true)
  }

  const handleBack = () => {
    const videoId = localStorage.getItem('currentVideoId')
    if (videoId) {
      history.push(`/v/${videoId}`)
    } else {
      history.push('/v')
    }
  }

  const handleOpenTipModal = () => {
    if (isEmpty(currentUser) && !isEmpty(currentGuest)) {
      history.push('/sign_in')
    } else if (currentUser.tokens_count > 0) {
      setTipModal(true)
    } else {
      setRefillModal(true)
    }
  }

  const handleOpenRefillModal = () => {
    document.querySelector('.ReactModal__Overlay').style.cssText =
      'bottom:-100% !important'
    setTimeout(() => {
      setTipModal(false)
      setRefillModal(true)
    }, 500)
  }

  useEffect(() => {
    resetProfileVideos()
  }, [resetProfileVideos])

  const avatarUrl = profile.avatar && profile.avatar.normal.url

  return profileLoading ? (
    <div className='profile-container profile-container--loader'>
      <MoonLoader color='#9a9a9a' size={90} />
    </div>
  ) : (
    <Swipe onSwipeRight={() => handleBack()} tolerance={100}>
      <div className='profile-container'>
        {!isOwn && (
          <>
            <TipModal
              createTip={createTip}
              currentUser={currentUser}
              getUser={getUser}
              handleCloseModal={handleCloseModal}
              handleOpenRefillModal={handleOpenRefillModal}
              receiver={profile.username}
              resetTipState={resetTipState}
              showTipModal={showTipModal}
              tipCreated={tipCreated}
              tipLoading={tipLoading}
            />
            <RefillModal
              currentUser={currentUser}
              getPacks={getPacks}
              handleCloseModal={handleCloseModal}
              packs={packs}
              packsLoading={packsLoading}
              showRefillModal={showRefillModal}
            />
          </>
        )}
        <ProfileShareModal
          currentGuest={currentGuest}
          currentUser={currentUser}
          index={shareVideoId}
          isOpen={showShareModal}
          isProfile={shareProfile}
          onDeleteSession={onDeleteSession}
          onRequestClose={handleCloseModal}
          profile={profile}
          videos={videos}
        />
        <div className='profile-container__wrapper'>
          <div className='profile__title'>
            <a
              className='profile__back__arrow profileBackBtn'
              onClick={() => handleBack()}
              role='button'
              tabIndex={0}
            >
              <SecondBackArrowIcon />
            </a>
            @{profile.username}
            <div
              className='profile__share'
              onClick={handleShareProfile}
              role='button'
              tabIndex={0}
            >
              <ShareIcon />
            </div>
          </div>
          <div className='profile__info'>
            <div className='profile__info__avatar'>
              <img alt='preview' src={avatarUrl || NoAvatar} />
              {isOwn && (
                <div
                  className='profile__info__edit'
                  onClick={() => history.push(`/${profile.username}/edit`)}
                  role='button'
                  tabIndex={0}
                >
                  <EditIcon />
                </div>
              )}
            </div>
            {profile.name && (
              <div className='profile__info__username'>{profile.name}</div>
            )}
            {profile.bio && (
              <Linkify>
                <div className='profile__info__bio'>{profile.bio}</div>
              </Linkify>
            )}
            {profile.location && (
              <div className='profile__info__location '>{profile.location}</div>
            )}
          </div>
          <div
            className={`profile__actions ${isOwn && 'profile__actions--own'}`}
          >
            {isOwn && (
              <div className='profile__actions__tokens'>
                <span>
                  <MoneyIcon />
                  {currentUser.tokens_count}
                </span>
                <p>tokens</p>
              </div>
            )}
            {!isOwn && (
              <a
                className='profile__actions__tip porfileTipBtn'
                onClick={() => handleOpenTipModal()}
                role='button'
                tabIndex={0}
              >
                <CoinIcon /> Send tip
              </a>
            )}
          </div>
          <ProfileVideos
            deleteVideo={deleteVideo}
            getVideos={getVideos}
            handleShareVideo={handleShareVideo}
            hasMoreVideos={hasMoreVideos}
            haveVideos={profile.have_videos}
            isOwn={isOwn}
            profile={profile}
            profileLoading={profileLoading}
            resetProfileVideos={resetProfileVideos}
            videos={videos}
            videosLoading={videosLoading}
          />
        </div>
      </div>
    </Swipe>
  )
}

Profile.propTypes = {
  getVideos: PropTypes.func.isRequired,
  onDeleteSession: PropTypes.func.isRequired,
  deleteVideo: PropTypes.func,
  profile: PropTypes.object.isRequired,
  profileLoading: PropTypes.bool.isRequired,
  resetProfileVideos: PropTypes.func.isRequired,
  videos: PropTypes.array,
  videosLoading: PropTypes.bool.isRequired,
  isOwn: PropTypes.bool.isRequired,
  hasMoreVideos: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  currentGuest: PropTypes.object,
  createTip: PropTypes.func.isRequired,
  tipLoading: PropTypes.bool.isRequired,
  tipCreated: PropTypes.bool.isRequired,
  resetTipState: PropTypes.func.isRequired,
  getPacks: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  packs: PropTypes.array,
  packsLoading: PropTypes.bool.isRequired
}

export default Profile
