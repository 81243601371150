import * as React from 'react'

const ReplyIcon = (props) => {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.6875 4.76777H4.91407L5.39073 4.2933C5.68793 3.99747 5.68793 3.5181 5.39073 3.22187C5.09353 2.92604 4.61195 2.92604 4.31436 3.22187L2.53878 4.9893C2.53845 4.98963 2.53805 4.98963 2.53805 4.98963L2 5.5256L2.53799 6.06079C2.53799 6.06079 2.53832 6.06112 2.53871 6.06183L4.44095 7.95501C4.58972 8.10309 4.78452 8.17693 4.97894 8.17693C5.17408 8.17693 5.36849 8.10309 5.51732 7.95501C5.81452 7.65917 5.81452 7.17981 5.51732 6.88358L4.91407 6.2831H10.6875C12.2262 6.2831 13.4781 7.52957 13.4781 9.06084C13.4781 10.5921 12.2258 11.8386 10.6875 11.8386H3.07709C2.65645 11.8386 2.31576 12.1777 2.31576 12.5961C2.31576 13.0144 2.65645 13.3536 3.07709 13.3536H10.6875C13.0657 13.3536 15 11.4278 15 9.06091C15 6.69405 13.0656 4.76777 10.6875 4.76777Z'
        fill='#9195A9'
      />
    </svg>
  )
}

export default ReplyIcon
