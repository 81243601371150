import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { osName } from 'react-device-detect'
import axios from 'axios'
import isEmpty from 'lodash.isempty'

import { SignUp } from '../components/pages'
import {
  infoIndexRequest, validationsCreateRequest, userCreateRequest,
  resetValidationEmail, resetValidationUsername
} from '../store/actions'
import { history } from '../services'

class SignUpContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isUS: false
    }
  }

  componentDidMount() {
    this.props.getInfo()
    if (isEmpty(this.props.currentGuest) &&
      !isEmpty(this.props.currentUser)) {
      history.push('/')
    }
    if (this.props.guestCountryCode === 'US') {
      this.setState({ isUS: true })
    }
  }

  postAuth = async (username, password, email) => {
    let loc

    if (localStorage.getItem('loc')) {
      loc = localStorage.getItem('loc')
    } else {
      loc = await axios('https://api.ipbase.com/v2/info').then(res => { return res.data.data.location.country.alpha2 })
      localStorage.setItem('loc', loc)
    }

    if (email) {
      const data = {
        username: username.toLowerCase(),
        password,
        country: loc,
        password_confirmation: password,
        email: email.toLowerCase(),
        platform: osName
      }
      this.props.createUser(data)
    }
  }

  onGetValidation = (field, value) => {
    if (value) {
      this.props.getValidation({ [field]: value.toLowerCase() })
    }
  }

  goToQuill = (name, value) => {
    history.push('/quill', { title: name, data: this.props.info[value] })
  }

  render() {
    return (
      <SignUp
        emailPresent={this.props.emailPresent}
        errorsSignIn={this.props.errors}
        getValidation={this.onGetValidation}
        goToQuill={this.goToQuill}
        isUS={this.state.isUS}
        postAuth={this.postAuth}
        resetValidEmail={this.props.resetValidEmail}
        resetValidUsername={this.props.resetValidUsername}
        usernamePresent={this.props.usernamePresent}
        validate={this.validate}
        userCreated={this.props.userCreated}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  getValidation: (data) => dispatch(validationsCreateRequest(data)),
  createUser: (data) => dispatch(userCreateRequest(data)),
  getInfo: () => dispatch(infoIndexRequest()),
  resetValidEmail: () => dispatch(resetValidationEmail()),
  resetValidUsername: () => dispatch(resetValidationUsername())
})

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentGuest: state.guest.currentGuest,
  usernamePresent: state.validation.usernamePresent,
  userCreated: state.users.userCreated,
  emailPresent: state.validation.emailPresent,
  errors: state.session.errors,
  info: state.info.details,
  guestCountryCode: state.guest.currentGuest.country
})

SignUpContainer.propTypes = ({
  emailPresent: PropTypes.bool,
  createUser: PropTypes.func.isRequired,
  getValidation: PropTypes.func.isRequired,
  usernamePresent: PropTypes.bool,
  errors: PropTypes.object,
  getInfo: PropTypes.func.isRequired,
  info: PropTypes.object,
  guestCountryCode: PropTypes.string.isRequired,
  resetValidEmail: PropTypes.func.isRequired,
  resetValidUsername: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  currentGuest: PropTypes.object,
  userCreated: PropTypes.bool
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer)
